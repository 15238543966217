import React, { useState } from 'react'
import {
  Card,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  Paper,
  Collapse,
  IconButton,
  useTheme,
} from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { useParams } from 'react-router-dom'
import { useOrganizationById } from '../../api/aws/useOrganization'
import { useDealsByIds } from '../../api/aws/useDealByIds'

const OrgBillingInfo = () => {
  const { orgId } = useParams()
  const { organization, isOrganizationLoading, isOrganizationError } = useOrganizationById(orgId)
  const dealIds = organization?.dealIds || []
  const { deals: deals, isLoading: dealsLoading, isError: dealsError, error: dealsErrorDetails } = useDealsByIds(
    dealIds
  )
  const [open, setOpen] = useState({})
  const theme = useTheme()

  const handleToggle = productName => {
    setOpen(prev => ({ ...prev, [productName]: !prev[productName] }))
  }

  const aggregateProducts = deals => {
    const productTotals = {}

    deals.forEach(deal => {
      if (deal.products) {
        deal.products.forEach(product => {
          if (!productTotals[product.productName]) {
            productTotals[product.productName] = { total: 0, mediaSpend: 0 }
          }
          productTotals[product.productName].total += parseFloat(product.total || product.price) || 0
          productTotals[product.productName].mediaSpend += parseFloat(product.mediaSpend) || 0
        })
      }
    })

    return productTotals
  }

  const productTotals = deals ? aggregateProducts(deals) : {}
  Object.entries(productTotals).map(([productName, totals], index) => {
    console.log('productName: ', productName, 'totals: ', totals, index)
  })

  return (
    <Card sx={{ p: 2, height: '100%' }}>
      <Typography variant='h6' gutterBottom>
        Current Spends
      </Typography>
      <TableContainer component={Paper}>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Service</TableCell>
              <TableCell align='right'>Contract Totals</TableCell>
              <TableCell align='right'>Spend Totals</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(productTotals).map(([productName, totals], index) => (
              <React.Fragment key={index}>
                <TableRow hover onClick={() => handleToggle(productName)}>
                  <TableCell>
                    <IconButton size='small'>
                      {open[productName] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                  </TableCell>
                  <TableCell>{productName}</TableCell>
                  <TableCell align='right'>${totals.total.toFixed(2)}</TableCell>
                  <TableCell align='right'>${totals.mediaSpend.toFixed(2)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={4} sx={{ pb: 0, pt: 0 }}>
                    <Collapse in={open[productName]} timeout='auto' unmountOnExit>
                      <Table size='small'>
                        <TableHead>
                          <TableRow>
                            <TableCell>Service</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell align='right'>Contract Totals</TableCell>
                            <TableCell align='right'>Spend Totals</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {deals &&
                            deals.flatMap(deal =>
                              deal.products
                                ? deal.products
                                    .filter(p => p.productName === productName)
                                    .map(product => {
                                      return (
                                        <TableRow key={product.rowId}>
                                          <TableCell>{product.productName}</TableCell>
                                          <TableCell>{product.status}</TableCell>
                                          <TableCell align='right'>${product.total || product.price}</TableCell>
                                          <TableCell align='right'>${product.mediaSpend}</TableCell>
                                        </TableRow>
                                      )
                                    })
                                : []
                            )}
                        </TableBody>
                      </Table>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  )
}

export default OrgBillingInfo

// import React, { useState } from 'react'
// import {
//   Card,
//   Typography,
//   Table,
//   TableHead,
//   TableBody,
//   TableCell,
//   TableRow,
//   TableContainer,
//   Paper,
//   Collapse,
//   IconButton,
//   useTheme,
// } from '@mui/material'
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
// import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
// import { useParams } from 'react-router-dom'
// import { useOrganizationById } from '../../api/aws/useOrganization'
// import { useDealsByIds } from '../../api/aws/useDealByIds'

// const ContractsMediaSpends = () => {
//   const { orgId } = useParams()
//   const { data: organization, isLoading, isError, error } = useOrganizationById(orgId)
//   const dealIds = organization?.dealIds || null
//   const { data: deals, isLoading: dealsLoading, isError: dealsError, error: dealsErrorDetails } = useDealsByIds(dealIds)
//   console.log('deals: ', deals)
//   const [open, setOpen] = useState({})
//   const theme = useTheme()

//   const handleToggle = productName => {
//     setOpen(prev => ({ ...prev, [productName]: !prev[productName] }))
//   }

//   const aggregateProducts = deals => {
//     const productTotals = {}

//     deals.forEach(deal => {
//       if (deal.products) {
//         deal.products.forEach(product => {
//           if (!productTotals[product.productName]) {
//             productTotals[product.productName] = { total: 0, mediaSpend: 0 }
//           }
//           productTotals[product.productName].total += parseFloat(product.total) || 0
//           productTotals[product.productName].mediaSpend += parseFloat(product.mediaSpend) || 0
//         })
//       }
//     })

//     return productTotals
//   }

//   const productTotals = aggregateProducts(deals)
//   Object.entries(productTotals).map(([productName, totals], index) => {
//     console.log('productName: ', productName, 'totals: ', totals, index)
//   })
//   return (
//     <Card sx={{ p: 2, height: '100%' }}>
//       <Typography variant='h6' gutterBottom>
//         Current Spends
//       </Typography>
//       <TableContainer component={Paper}>
//         <Table size='small'>
//           <TableHead>
//             <TableRow>
//               <TableCell />
//               <TableCell>Service</TableCell>
//               <TableCell align='right'>Contract Totals</TableCell>
//               <TableCell align='right'>Spend Totals</TableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {Object.entries(productTotals).map(([productName, totals], index) => (
//               <React.Fragment key={index}>
//                 <TableRow hover onClick={() => handleToggle(productName)}>
//                   <TableCell>
//                     <IconButton size='small'>
//                       {open[productName] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
//                     </IconButton>
//                   </TableCell>
//                   <TableCell>{productName}</TableCell>
//                   <TableCell align='right'>${totals.total.toFixed(2)}</TableCell>
//                   <TableCell align='right'>${totals.mediaSpend.toFixed(2)}</TableCell>
//                 </TableRow>
//                 <TableRow>
//                   <TableCell colSpan={4} sx={{ pb: 0, pt: 0 }}>
//                     <Collapse in={open[productName]} timeout='auto' unmountOnExit>
//                       <Table size='small'>
//                         <TableHead>
//                           <TableRow>
//                             <TableCell>Service</TableCell>
//                             <TableCell>Status</TableCell>
//                             <TableCell align='right'>Contract Totals</TableCell>
//                             <TableCell align='right'>Spend Totals</TableCell>
//                           </TableRow>
//                         </TableHead>
//                         <TableBody>
//                           {deals.flatMap(deal =>
//                             deal.products
//                               .filter(p => p.productName === productName)
//                               .map(product => (
//                                 <TableRow key={product.rowId}>
//                                   <TableCell>{product.productName}</TableCell>
//                                   <TableCell>{product.status}</TableCell>
//                                   <TableCell align='right'>${product.total}</TableCell>
//                                   <TableCell align='right'>${product.mediaSpend}</TableCell>
//                                 </TableRow>
//                               ))
//                           )}
//                         </TableBody>
//                       </Table>
//                     </Collapse>
//                   </TableCell>
//                 </TableRow>
//               </React.Fragment>
//             ))}
//           </TableBody>
//         </Table>
//       </TableContainer>
//     </Card>
//   )
// }

// export default ContractsMediaSpends
