import React, { useState } from 'react'
import { Grid, Box, useTheme, Button, Typography, Tooltip, Chip } from '@mui/material'
import MassUpdateTypeSelect from '../MassUpdateComponents/MassUpdateTypeSelect'
import DeleteIcon from '@mui/icons-material/Delete'
import { GridFooterContainer } from '@mui/x-data-grid-pro'
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'
import MassUpdateInvoiceSelect from '../MassUpdateComponents/MassUpdateInvoiceSelect'
import RenderUpdateComponents from '../MassUpdateComponents/RenderUpdateComponents'
import RenderRowCount from '../DataGrid/RenderRowCount'
import { invoiceViewState, churnViewState, billingViewState } from './AccountingPage'

const tooltipGridFooter = title => {
  return <Typography>{title}</Typography>
}

export const AccountingGridFooter = ({
  deals = [],
  totalRowCount,
  visibleRowCount,
  rowCount,
  massUpdateData,
  selectionModel = [],
  onMassUpdateChange,
  handleMassUpdate,
  anchorEl,
}) => {
  const theme = useTheme()
  const { showSnackbar } = useSnackbarContext()
  const open = Boolean(anchorEl)
  const id = open ? 'accounting-grid-footer-popover' : undefined

  const gridStyles = {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
    pb: 0.5,
    pl: 1.5,
    pr: 0.5,
    pt: 1,
    mt: 0.5,
  }

  return (
    <>
      {selectionModel && selectionModel.length > 0 ? (
        <Grid
          container
          sx={{
            position: 'fixed',
            bottom: 60, // Keep it above the DataGrid footer
            left: '10%',
            right: '10%',
            maxWidth: '80%',
            zIndex: 1200,
            backgroundColor: theme.palette.background.default,
            boxShadow: '10px 21px 43px rgba(0, 0, 0, 0.1)',
            // borderRadius: '8px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            p: 1,
            gap: 2,
            border: '1px solid',
            borderColor: theme.palette.text.secondary,
          }}
        >
          {/* Right RenderUpdateComponents Section */}
          <RenderUpdateComponents
            deals={deals}
            visibleRowCount={visibleRowCount}
            rowCount={rowCount}
            selectionModel={selectionModel}
            massUpdateData={massUpdateData}
            onMassUpdateChange={onMassUpdateChange}
            handleMassUpdate={handleMassUpdate}
          />
        </Grid>
      ) : null}
    </>
  )
}
