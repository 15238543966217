import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { baseUrl } from '../queryMutations/mutationFunctions'

const updateItem = async ({ primaryKey, tableName, itemId, fieldName, newValue }) => {
  console.log('[UpdateItem] called with item: ', { primaryKey, tableName, itemId, fieldName, newValue })
  const url = `${baseUrl}/aws/update`
  try {
    const response = await axios.put(url, { primaryKey, tableName, itemId, fieldName, newValue })
    if (response.status !== 200) throw new Error('Network response was not ok')
    return response.data
  } catch (error) {
    console.error('Failed to update item:', error)
    throw error
  }
}

export const useUpdateItem = () => {
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: updateItem,
    onSuccess: (data, variables) => {
      console.log('[useUpdateItem] Updated item data:', data, 'VARIABLES: ', variables)
      // queryClient.invalidateQueries(['awsItems', variables.itemId])
    },
    onError: error => {
      console.error('[useUpdateItem] Error updating item:', error)
    },
  })
  return mutation
}
