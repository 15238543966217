import React from 'react'
import InfoIcon from '@mui/icons-material/Info'
import GroupsIcon from '@mui/icons-material/Groups'
import TaskAltIcon from '@mui/icons-material/TaskAlt'
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail'
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges'

const RenderTypeComponent = ({ type }) => {
  return (
    <>
      {type === 'team' ? (
        <GroupsIcon sx={{ height: 30, width: 30, color: 'rgb(114, 229, 218)' }} />
      ) : type === 'task' ? (
        <TaskAltIcon sx={{ height: 30, width: 30, color: 'rgb(159, 90, 253)' }} />
      ) : type === 'mention' ? (
        <AlternateEmailIcon sx={{ height: 30, width: 30, color: 'rgb(114, 229, 218)' }} />
      ) : type === 'status' ? (
        <PublishedWithChangesIcon sx={{ height: 30, width: 30, color: 'rgb(114, 229, 218)' }} />
      ) : (
        <InfoIcon sx={{ height: 30, width: 30, color: 'rgb(114, 229, 218)' }} />
      )}
    </>
  )
}

export default RenderTypeComponent
