import React, { useEffect, useState } from 'react'
import { Box, Button, Grid, IconButton, InputLabel, Typography, useTheme } from '@mui/material'
import { formatCurrency } from '../DealsKanban/aggregateStageTotals'
import RenderServiceIcon from '../RenderServiceIcons/RenderServiceIcon'
import CampaignIcon from '@mui/icons-material/Campaign'
import { Link, useParams } from 'react-router-dom'
import { useDealDetailsContext } from '../../context/DealsDetailsProvider/DealsDetailsProvider'
import { useCreateCampaign } from '../../api/aws/useCreateCampaign'
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'
import { useCampaign } from '../../api/aws/useCampaign'

const RenderProduct = ({ strategy, organization, dealId, service }) => {
  // const { dealId } = useParams()
  const { newDeal } = useDealDetailsContext()
  const { showSnackbar } = useSnackbarContext()
  const { isEditMode } = useDealDetailsContext()
  const theme = useTheme()
  const [isHovered, setIsHovered] = useState(false)
  const [campaignState, setCampaignState] = useState(null)
  const [campaignId, setCampaignId] = useState(service.instanceId || '')
  // const { campaign, isLoading: campaignLoading, isError: campaignError } = useCampaign(service?.instanceId || null)
  // const { mutate: createCampaign } = useCreateCampaign()
  // useEffect(() => {
  //   if (!campaignLoading && campaign !== undefined) {
  //     if (campaign.status === 204) {
  //       console.log('no campaign found, creating new....')
  //       handleCreateCampaign()
  //     } else {
  //       setCampaignState({
  //         name: `Campaign | ${newDeal?.name}`,
  //         ...campaign,
  //       })
  //     }
  //   }
  // }, [campaign, campaignLoading])
  // const handleCreateCampaign = () => {
  //   const campaignData = {
  //     strategy: strategy,
  //     product: service,
  //     organization: organization,
  //   }
  //   console.log('campaignData', campaignData)
  //   try {
  //     createCampaign(campaignData, {
  //       onSuccess: () => {
  //         console.log('Successfully created new campaign')
  //       },
  //       onError: e => {
  //         console.error('Error creating new campaign', e)
  //       },
  //     })
  //   } catch (error) {
  //     console.error('Error creating new campaign', error)
  //   }
  // }

  return (
    <Grid
      container
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        p: 1,
        border: '1px solid',
        borderColor: theme.palette.text.secondary,
        bgcolor: theme.palette.background.sectionContent,
        zIndex: 17, // Adjust stacking order
        width: '100%',
      }}
    >
      <Grid
        item
        xs={3}
        sx={{ display: 'flex', flexDirection: 'column' }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        gap={0.5}
      >
        <Box sx={{ position: 'relative' }}>
          <InputLabel
            sx={{
              fontSize: 12,
              opacity: isHovered ? 1 : 0,
              transition: 'opacity 0.5s ease-in-out',
            }}
          >
            {isHovered ? `${service.instanceId}` : ''}
          </InputLabel>
          <InputLabel
            sx={{
              fontSize: 12,
              opacity: !isHovered ? 1 : 0,
              transition: 'opacity 0.5s ease-in-out',
            }}
          >
            {!isHovered ? 'Service' : ''}
          </InputLabel>
        </Box>
        <RenderServiceIcon service={service.productName} />
      </Grid>
      <Grid item xs={2} sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
        <InputLabel sx={{ fontSize: 12 }}>Payer</InputLabel>
        <Typography>{service.payer || '—'}</Typography>
      </Grid>
      <Grid item xs={2} sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
        <InputLabel sx={{ fontSize: 12 }}>Account Used</InputLabel>
        <Typography>{service.accountUsed || '—'}</Typography>
      </Grid>
      <Grid item xs={2} sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
        <InputLabel sx={{ fontSize: 12 }}>Media Spend</InputLabel>
        <Typography>{formatCurrency(service.mediaSpend)}</Typography>
      </Grid>
      <Grid item xs={2} sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
        <InputLabel
          sx={{
            fontSize: 12,
          }}
        >
          Price
        </InputLabel>
        <Typography
          sx={{ color: service.payer === 'Client' ? theme.palette.text.warning : theme.palette.text.primary }}
        >
          {formatCurrency(service.price)}
        </Typography>
      </Grid>

      {!isEditMode ? (
        <Grid item xs={1} sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
          <InputLabel
            sx={{
              fontSize: 12,
            }}
          >
            Campaign
          </InputLabel>
          <Link
            to={`/deals/${dealId}/strategy/${campaignId}`}
            style={{
              display: 'flex',
              textDecoration: 'none',
              color: 'inherit',
              backgroundColor: 'transparent',
            }}
          >
            <CampaignIcon sx={{ height: 30, width: 30 }} />
          </Link>
          {/* <Button onClick={handleCreateCampaign}>Create Campaign</Button> */}
        </Grid>
      ) : null}
    </Grid>
  )
}

export default RenderProduct
