import React, { createContext, useState, useContext, useEffect } from 'react'

export const ThemeContext = createContext()

export const useTheme = () => useContext(ThemeContext)

export const ThemeProviderContext = ({ children }) => {
  // Initialize theme state from localStorage or default to 'dark'
  const [theme, setTheme] = useState(() => {
    const storedTheme = localStorage.getItem('theme')
    return storedTheme ? storedTheme : 'dark'
  })

  // Toggle theme and save to localStorage
  const toggleTheme = () => {
    setTheme(prevTheme => {
      const newTheme = prevTheme === 'light' ? 'dark' : 'light'
      localStorage.setItem('theme', newTheme)
      return newTheme
    })
  }

  return <ThemeContext.Provider value={{ theme, toggleTheme }}>{children}</ThemeContext.Provider>
}

// import React, { createContext, useState, useContext } from 'react'

// export const ThemeContext = createContext()

// export const useTheme = () => useContext(ThemeContext)

// export const ThemeProviderContext = ({ children }) => {
//   const [theme, setTheme] = useState('dark') // default theme

//   const toggleTheme = () => {
//     setTheme(prevTheme => (prevTheme === 'light' ? 'dark' : 'light'))
//   }

//   return <ThemeContext.Provider value={{ theme, toggleTheme }}>{children}</ThemeContext.Provider>
// }
