import { useGetQuery } from './useGetQuery'

export const useFetchNotifications = () => {
  const { data, isLoading: isNotificationsLoading, isError: isNotificationsError } = useGetQuery(
    '/aws/notifications',
    'notifications'
  )

  // Sort the notifications
  const notifications = (data ?? []).sort((a, b) => {
    // Unread notifications first
    if (!a.isRead && b.isRead) return -1
    if (a.isRead && !b.isRead) return 1

    // Then by descending createdTimestamp
    return new Date(b.createdTimestamp) - new Date(a.createdTimestamp)
  })

  return { notifications, isNotificationsLoading, isNotificationsError }
}
