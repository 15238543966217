import React, { useContext, useEffect, useState } from 'react'
import { Box, Typography, Grid, useTheme, Tooltip, Divider, InputLabel, CircularProgress } from '@mui/material'
import Loading from '../Loading/Loading'
import { useParams, Link } from 'react-router-dom'
import { useOrganizationById } from '../../api/aws/useOrganization'
import { useDealsByIds } from '../../api/aws/useDealByIds'
import OrganizationInfoCard from './OrganizationInfoCard'
import { useUpdateItem } from '../../api/aws/useUpdateItem'
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'
import UserImageComponent from '../Users/UserImageComponent'
import { useUpdateItems } from '../../api/aws/useUpdateItems'
import TeamMembers from '../TeamMembers/TeamMembers'
import { useUpdateWebsite } from '../../api/aws/useUpdateWebsite'
import { useQueryClient } from '@tanstack/react-query'
import { useValidationContext } from '../../context/ValidationProvider/ValidationProvider'
import { useGetQuery } from '../../api/aws/useGetQuery'
import { useGetQueryById } from '../../api/aws/useGetQueryById'
import { useUsersById } from '../../api/customHooks/useUsersById'
import DetailsLoading from '../Loading/DetailsLoading'
import { useContact } from '../../api/aws/useContact'

const OrganizationDetails = () => {
  const { orgId } = useParams()
  const { isValidWebsite } = useValidationContext()
  const { showSnackbar } = useSnackbarContext()
  const theme = useTheme()
  const { organization, isOrganizationLoading, isOrganizationError } = useOrganizationById(orgId)
  console.log('organization', organization)
  const { data: primaryContact, isLoading: primaryContactLoading, isError: primaryContactError } = useContact(
    organization && Array.isArray(organization.primaryContact) ? organization.primaryContact[0] : null
  )
  const { data: billingContact, isLoading: billingContactLoading, isError: billingContactError } = useContact(
    organization && Array.isArray(organization.billingContact) ? organization.billingContact[0] : null
  )
  const userIds = organization && Array.isArray(organization.owner) ? organization.owner.map(id => id) : []
  const { users, isUsersByidLoading, isUsersByIdError, usersByIdError } = useUsersById(userIds)
  const { mutate: updateItems } = useUpdateItems()
  const { mutate: updateWebsite } = useUpdateWebsite()
  const dealIds = organization?.dealIds || []
  const { data: deals, isLoading: dealsLoading, isError: dealsError, error: dealsErrorDetails } = useDealsByIds(dealIds)
  const gridBgColor = theme.palette.mode === 'dark' ? '#1e2735' : 'rgba(255,255,255)'
  const [isUpdating, setIsUpdating] = useState(false)
  const queryClient = useQueryClient()

  const handleUpdate = (fieldName, newValue) => {
    // console.log('newValue: ', newValue)
    if (organization[fieldName] !== newValue) {
      const updates = [
        {
          primaryKey: 'org_uuid',
          tableName: 'organizations',
          itemId: orgId,
          fieldName,
          newValue,
          dealIds: dealIds, // Include dealIds if needed
        },
      ]

      updateItems(
        { updates: updates },
        {
          onSuccess: () => {
            console.log('Update successful')
            showSnackbar(`Update Successful for ${fieldName}`)
          },
          onError: err => {
            console.error('Update failed', err)
          },
        }
      )
    }
  }
  const handleUpdateWebsite = (fieldName, newValue) => {
    console.log('newValue: ', newValue)
    if (organization[fieldName] !== newValue && isValidWebsite(newValue)) {
      const updates = [
        {
          primaryKey: 'org_uuid',
          tableName: 'organizations',
          itemId: orgId,
          fieldName,
          newValue,
          dealIds: dealIds, // Include dealIds if needed
        },
      ]

      updateWebsite(
        { updates: updates },
        {
          onSuccess: () => {
            console.log('Update successful')
            showSnackbar(`Update Successful for ${fieldName}`)
            queryClient.invalidateQueries(['organization', orgId]) // Invalidate and refetch the organization query
          },
          onError: err => {
            console.error('Update failed', err)
          },
        }
      )
    }
  }
  const isLoading = isOrganizationLoading || primaryContactLoading || dealsLoading
  const isError = isOrganizationError || primaryContactError || dealsError
  if (isLoading) {
    return <DetailsLoading />
  }

  if (isError) {
    return <Typography>Error loading component....</Typography>
  }

  if (!organization) {
    return <Typography>No organization data available.</Typography>
  }
  return (
    <Grid
      container
      spacing={2}
      justifyContent='center'
      sx={{ padding: 5, flexGrow: 1, minHeight: '100vh', boxSizing: 'border-box' }}
    >
      <Grid item xs={12} sm={12} md={9} lg={9}>
        {isLoading && <CircularProgress />}
        <OrganizationInfoCard
          organization={organization}
          primaryContact={primaryContact}
          billingContact={billingContact}
          users={users}
          orgId={orgId}
          onSave={handleUpdate}
          onUpdateWebsite={handleUpdateWebsite}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                padding: 2,
                backgroundColor: gridBgColor,
                borderRadius: '25px',
                flexGrow: 1, // Instead of maxHeight: 200
                overflowY: 'auto',
              }}
            >
              <TeamMembers organization={organization} />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                padding: 2,
                backgroundColor: gridBgColor,
                height: 140,
                borderRadius: '25px',
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                padding: 2,
                backgroundColor: gridBgColor,
                height: 130,
                borderRadius: '25px',
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={9} lg={9}>
        <Box
          sx={{
            width: '100%',
            backgroundColor: gridBgColor,
            padding: 4,
            borderRadius: '25px',
            mt: 2,
          }}
        >
          <Typography variant='h6' gutterBottom>
            Summary
          </Typography>
          <Typography>{organization && organization.summary ? organization.summary : 'No summary included'}</Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3}>
        <Box
          sx={{
            padding: 2,
            backgroundColor: 'none',
            height: 190,
            borderRadius: '25px',
          }}
        />
      </Grid>
    </Grid>
  )
}

export default OrganizationDetails
