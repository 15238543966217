import React, { useContext, useState } from 'react'
import {
  SpeedDial,
  SpeedDialAction,
  useTheme,
  Box,
  Tooltip,
  IconButton,
  Badge,
  CircularProgress,
  Popover,
  Typography,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { ThemeContext } from '../../themeProviders/ThemeContext'
import ShowNotifications from '../NotificationDrawer/ShowNotifications'
import LightModeIcon from '@mui/icons-material/LightMode'
import DarkModeIcon from '@mui/icons-material/DarkMode'
import UserImageComponent from '../Users/UserImageComponent'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'
import NotificationsIcon from '@mui/icons-material/Notifications'
import NotificationDrawer from '../NotificationDrawer/NotificationDrawer'
import { useNotify } from '../../context/NotificationProvider/NotificationProvider'
import { useUpdateMassItems } from '../../api/aws/useUpdateMassItems'
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'
import DetailsLoading from '../Loading/DetailsLoading'
import SettingsIcon from '@mui/icons-material/Settings'

const StyledSpeedDial = styled(SpeedDial)(({ theme }) => ({
  position: 'absolute',
  '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
    top: theme.spacing(2),
    left: theme.spacing(2),
  },
}))
const StyledSpeedDialAction = styled(SpeedDialAction)(({ theme }) => ({
  // backgroundColor: 'transparent',
  backgroundColor: theme.palette.background.icon,
  boxShadow: 'none',
  '&:hover': {
    // backgroundColor: 'transparent',
  },
  width: 48, // Adjust icon size
  height: 48,
}))

const SpeedDialComponent = ({ currentUser, isCurrentUserLoading }) => {
  const theme = useTheme()
  const { notifications, isNotificationsLoading, unreadCount, markAsRead } = useNotify()
  const [opacity, setOpacity] = useState(1)
  const [notifyDrawerOpen, setNotifyDrawerOpen] = useState(false)
  const [actionsOpen, setActionsOpen] = useState(false)
  const { showSnackbar } = useSnackbarContext()
  const [isUpdating, setIsUpdating] = useState(false)
  const { mutate: batchUpdateIsRead } = useUpdateMassItems(['gro_notify'])
  const [direction, setDirection] = React.useState('left')
  const { toggleTheme } = useContext(ThemeContext) // Access theme and toggleTheme
  ///////////////////////////////////////////
  const [anchorEl, setAnchorEl] = useState(null)
  const handleOpenNotifications = event => {
    if (!notifyDrawerOpen) {
      setNotifyDrawerOpen(true)
      setAnchorEl(event.currentTarget)
    }
    if (notifyDrawerOpen) {
      setNotifyDrawerOpen(false)
      setAnchorEl(null)
    }
  }

  const handleCloseNotifications = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)
  ///////////////////////////////////////////
  const updateNotificationsAsRead = async unreadIds => {
    if (!unreadIds.length) return

    const updates = unreadIds.map(notify_uuid => ({
      tableName: 'gro_notify',
      itemId: notify_uuid,
      primaryKey: 'notify_uuid',
      fieldName: 'isRead',
      newValue: true,
    }))

    // Immediately update the local state
    unreadIds.forEach(id => {
      markAsRead(id) // This will update the unreadCount and mark the notifications as read locally
    })

    batchUpdateIsRead(
      { updates },
      {
        onMutate: () => setIsUpdating(true),
        onSuccess: () => {
          setIsUpdating(false)
          showSnackbar('Notifications updated successfully', 'success')
          setOpacity(1)
        },
        onError: () => {
          setIsUpdating(false)
          showSnackbar('Notifications update failed', 'error')
        },
      }
    )
  }
  const handleOpen = () => {
    setNotifyDrawerOpen(true)
    setActionsOpen(false)
    setOpacity(1)
  }
  const closeDrawer = unreadIds => {
    setNotifyDrawerOpen(false)
    setActionsOpen(false)
    setOpacity(1)
    setAnchorEl(null)

    if (unreadIds.length > 0) {
      updateNotificationsAsRead(unreadIds) // Update notifications as read when closing the drawer
    }
  }

  const actions = [
    {
      icon: (
        <Box>
          {unreadCount > 0 ? ( // Only render the Badge if unreadCount is greater than 0
            <Badge badgeContent={unreadCount} color='error'>
              <NotificationsIcon
                sx={{
                  height: 30,
                  width: 30,
                }}
              />
            </Badge>
          ) : (
            <Badge>
              <NotificationsNoneIcon
                sx={{
                  height: 30,
                  width: 30,
                }}
              />
            </Badge>
          )}
        </Box>
      ),
      name: 'Notifications',
      delay: 3,
      action: handleOpenNotifications,
      // action: handleOpen,
    },
    {
      icon: (
        <>
          {theme.palette.mode === 'dark' ? (
            <LightModeIcon
              sx={{
                height: 30,
                width: 30,
              }}
            />
          ) : (
            <DarkModeIcon
              sx={{
                height: 30,
                width: 30,
              }}
            />
          )}
        </>
      ),
      name: 'Toggle Theme',
      delay: 1.5,
      action: toggleTheme,
    },
    {
      icon: (
        <SettingsIcon
          sx={{
            height: 30,
            width: 30,
          }}
        />
      ),

      name: 'Settings',
    },
  ]
  if (isNotificationsLoading || isUpdating) {
    return <DetailsLoading />
  }

  const handleMouseEnter = () => {
    setOpacity(0.6)
    if (!open) setActionsOpen(true)
  }
  const handleMouseLeave = () => {
    setOpacity(1)
    if (!open) setActionsOpen(false)
  }

  return (
    <Box onMouseLeave={handleMouseLeave}>
      <StyledSpeedDial
        open={actionsOpen || open}
        ariaLabel='Grobotic SpeedDial'
        sx={{
          position: 'fixed',
          bottom: 16,
          right: 16,
          mr: 2,
          zIndex: 1799,
          bgcolor: actionsOpen ? 'transparent' : '', // Transparent background
          backdropFilter: actionsOpen ? 'blur(8px)' : '', // Optional: adds blur effect
          boxShadow: actionsOpen ? 'none' : '', // Removes default shadow
        }}
        icon={
          <>
            {isCurrentUserLoading ? (
              <CircularProgress sx={{ color: theme.palette.text.primary }} />
            ) : (
              <Box
                sx={{
                  border: '2px solid',
                  borderColor: 'rgba(103, 228, 147, 0.8)',
                  borderRadius: '50px',
                  opacity: opacity,
                }}
              >
                <Box onMouseEnter={handleMouseEnter}>
                  {unreadCount > 0 ? (
                    <Badge badgeContent={unreadCount} color='error'>
                      <UserImageComponent
                        imageUrl={currentUser?.map(user => user?.image_512)}
                        width={60}
                        height={60}
                        opacity={opacity}
                      />
                    </Badge>
                  ) : null}
                  {unreadCount < 1 ? (
                    <Badge>
                      <UserImageComponent
                        imageUrl={currentUser?.map(user => user?.image_512)}
                        width={60}
                        height={60}
                        opacity={opacity}
                      />
                    </Badge>
                  ) : null}
                </Box>
              </Box>
            )}
          </>
        }
        direction={direction}
      >
        {actions.map(action => (
          <StyledSpeedDialAction key={action.name} icon={action.icon} tooltipTitle={''} onClick={action.action} />
        ))}
      </StyledSpeedDial>
      <NotificationDrawer
        //  drawerOpen={notifyDrawerOpen}
        // closeDrawer={closeDrawer}
        drawerOpen={open}
        closeDrawer={closeDrawer}
        anchorEl={anchorEl}
      />
    </Box>
  )
}

export default SpeedDialComponent
