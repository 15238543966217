import React, { useState, useEffect } from 'react'
import {
  Box,
  FormControl,
  Select,
  MenuItem,
  Chip,
  Typography,
  Popper,
  OutlinedInput,
  InputLabel,
  useTheme,
} from '@mui/material'
import { renderTypeColor } from '../DataGrid/createColumns/renderDealsColumns'

const MassUpdateInvoiceSelect = ({ name, value, options, displayLabel, onChange, onBlur }) => {
  const [selectedValue, setSelectedValue] = useState(value ?? '')
  const theme = useTheme()
  useEffect(() => {
    setSelectedValue(value ?? '')
  }, [value])

  const handleChange = event => {
    const newValue = event.target.value
    console.log('NEW VALUE: ', newValue)
    setSelectedValue(newValue)
    onChange({ target: { name, value: newValue } })
  }

  const handleBlur = () => {
    if (onBlur) {
      onBlur({ target: { name, value: selectedValue } })
    }
  }

  return (
    <Box sx={{ minWidth: '50%' }}>
      <FormControl fullWidth>
        <InputLabel htmlFor={`${name}-select`}>{displayLabel}</InputLabel>
        <Select
          label={displayLabel}
          labelId={`${name}-label`}
          id={`${name}-select`}
          value={selectedValue}
          onChange={handleChange}
          onBlur={handleBlur}
          // input={<OutlinedInput label={displayLabel} fullWidth sx={{ bgcolor: 'red' }} />}
          MenuProps={{
            style: { zIndex: 2000 },
          }}
          sx={{ maxHeight: 45 }}
        >
          {options.map(option => {
            return (
              <MenuItem key={option.label} value={option.label}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} gap={1}>
                  <Chip
                    size='small'
                    sx={{
                      bgcolor: option.color,
                      height: theme.palette.chip.sizeSmall.height,
                      width: theme.palette.chip.sizeSmall.width,
                    }}
                  />
                  <Typography variant='subtitle1'>{option.label}</Typography>
                </Box>
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    </Box>
  )
}

export default MassUpdateInvoiceSelect
