import React from 'react'
import { Tooltip, Stack, Typography, useTheme } from '@mui/material'
import WarningIcon from '@mui/icons-material/Warning'
import RenderErrors from './RenderErrors'

const RenderErrorTooltip = ({ strategy, isStrategyValid, errorKeys }) => {
  const theme = useTheme()
  return (
    <>
      {!strategy?.isCompleted && strategy?.status === 204 ? (
        <Tooltip
          title={
            <Stack>
              <Typography>No Strategy Attached</Typography>
              <Typography>Click to create</Typography>
            </Stack>
          }
        >
          <Stack sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '100%', gap: 1 }}>
            {!isStrategyValid && (
              <WarningIcon
                sx={{
                  height: 20,
                  width: 20,
                  color: theme.palette.text.error,

                  transition: 'color 0.3s ease-in-out',
                }}
              />
            )}
            <Typography>Strategy</Typography>
          </Stack>
        </Tooltip>
      ) : (
        <Tooltip title={isStrategyValid ? 'Access Strategy' : <RenderErrors errorKeys={errorKeys} />}>
          <Stack sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '100%', gap: 1 }}>
            {!strategy?.isCompleted && !isStrategyValid && (
              <WarningIcon
                sx={{ height: 20, width: 20, color: theme.palette.text.error, transition: 'color 0.3s ease-in-out' }}
              />
            )}
            <Typography>Strategy</Typography>
          </Stack>
        </Tooltip>
      )}
    </>
  )
}

export default RenderErrorTooltip
