import { Box, Chip, IconButton, Typography, Tooltip, Link as MuiLink, Avatar } from '@mui/material'
import dayjs from 'dayjs'
import { GridStatusChip, GridStageChip } from '../../../style/styleElements'
import { Link } from 'react-router-dom'
import PandaDocLinkIcon from '../../Icons/PandaDockLinkIcon'
import UserImageComponent from '../../Users/UserImageComponent'
import { getDensityStyles, userImageStyles, DisabledIconButton } from '../style'
import RenderVertical from '../../Organizations/RenderVertical'
import { getGridStringOperators } from '@mui/x-data-grid-pro'
import RenderContractedByColumn from '../RenderContractedByColumn'
import { formatCurrency } from '../../DealsKanban/aggregateStageTotals'
import StrategyProgress from '../../Icons/StrategyProgress'
import { analyzeStrategy } from '../../Strategy/analyzeStrategy'

export const renderTypeColor = (type, theme) => {
  switch (type) {
    case 'Marketing':
    case 'MSA':
    case 'RMSA':
    case 'MSA - Housing':
    case 'RMSA - Housing':
    case 'IO':
      return 'rgb(88, 188, 128)'
    case 'Creative':
    case 'CSA':
    case 'RCSA':
      return 'rgb(165, 147, 255)'
    case 'draft':
      return 'rgb(167, 172, 182)'
    case 'Draft':
      return 'rgb(167, 172, 182)'
    case 'saved':
      return 'rgb(35, 146, 173)'
    case 'Saved':
      return 'rgb(35, 146, 173)'
    case 'sent':
      return 'rgb(108, 218, 135)'
    case 'Sent':
      return 'rgb(93, 79, 251)'
    case 'created':
      return 'rgb(114, 229, 218)'
    case 'Created':
      return 'rgb(114, 229, 218)'
    case 'Active':
      return 'rgb(80, 200, 120)'
    case 'Approved':
      return 'rgb(6, 255, 167, 0.6)'
    case 'Resolved':
      return 'rgb(249, 167, 49)'
    case '30 Day Notice':
      return 'rgb(235, 77, 77)'
    case 'Rejected':
    case 'Expired':
      return 'rgba(220, 20, 60, 0.9)'
    case 'Onboarding':
      return 'rgb(110, 193, 232)'
      return 'rgba(137, 196, 244, 1)'
    case 'Awaiting Dependencies':
      return 'rgb(59, 152, 245)'
    case 'Dependencies Received':
      return 'rgb(245, 124, 79)'
    case 'Handoff Call':
      return 'rgba(106, 65, 192, 1)'
    case 'Launched':
      return 'rgb(52, 211, 145)'
    case 'Cancelled':
      return theme ? theme.palette.text.primary : 'rgba(0,0,0)'
    case 'Viewed':
      return 'rgb(213, 102, 201)'
    case 'Contract Approved':
      return 'rgb(255, 144, 69)'
    case 'Strategy Approved':
      return 'rgb(6, 255, 167)'
    case 'Sent For Approval':
      return 'rgb(163, 96, 205)'
    case 'Approval Requested':
      return 'rgba(255, 148, 112, 1)'
    case 'Needs Approval':
      return 'rgb(251, 193, 30)'
    case 'Pending Approval':
      return 'rgba(241, 130, 141, 1)'
    case 'Completed Internally':
      return 'rgba(22, 160, 133, 1)'
    case 'Completed':
      return 'rgb(80, 200, 120)'
    case 'Proposal':
      return 'rgb(174, 92, 174)'
    case 'Invoiced':
      return 'rgb(80, 200, 120)'
    case 'Not Invoiced':
      return 'rgb(249, 167, 49)'
    case 'Active - 30 Day Notice':
      return 'rgb(255, 123, 208)'
    case 'Month To Month':
      return 'rgb(22, 138, 239)'
    case 'N/A':
      return 'rgb(121, 126, 147)'
    case 'Requested':
      return 'rgb(253, 188, 100)'
    case 'Done':
      return 'rgb(51, 211, 145)'
    case 'In Progress':
      return 'rgb(253, 188, 100)'
    case 'Stuck':
      return 'rgb(232, 105, 125)'
    default:
      return 'rgb(178, 190, 181)'
  }
}
export const renderDealStatusColor = status => {
  switch (status) {
    case 'draft':
      return 'rgb(167, 172, 182, 0.6)'
    case 'Draft':
      return 'rgb(167, 172, 182, 0.6)'
    case 'saved':
      return 'rgb(35, 146, 173, 0.6)'
    case 'Saved':
      return 'rgb(35, 146, 173, 0.6)'
    case 'sent':
      return 'rgb(108, 218, 135, 0.6)'
    case 'Sent':
      return 'rgb(93, 79, 251, 0.6)'
    case 'created':
      return 'rgb(114, 229, 218, 0.6)'
    case 'Created':
      return 'rgb(114, 229, 218, 0.6)'
    case 'Active':
      return 'rgb(80, 200, 120, 0.6)'
    case 'Approved':
      return 'rgb(6, 255, 167, 1)'
    case 'Resolved':
      return 'rgb(249, 167, 49)'
    case 'Rejected':
      return 'rgba(220, 20, 60, 0.8)'
    case '30 Day Notice':
      return 'rgb(235, 77, 77)'
    case 'Viewed':
      return 'rgb(213, 102, 201, 0.6)'
    case 'Contract Approved':
      return 'rgb(255, 144, 69, 0.6)'
    case 'Strategy Approved':
      return 'rgb(6, 255, 167, 0.6)'
    case 'Sent For Approval':
      return 'rgb(163, 96, 205, 0.6)'
    case 'Approval Requested':
      return 'rgba(255, 148, 112, 0.6)'
    case 'Needs Approval':
      return 'rgb(245, 189, 30)'
    case 'Pending Approval':
      return 'rgba(241, 130, 141, 0.6)'
    case 'Completed Internally':
      return 'rgba(22, 160, 133, 0.6)'
    case 'Completed':
      return 'rgb(80, 200, 120, 0.6)'
    case 'Proposal':
      return 'rgb(174, 92, 174, 0.6)'
    case 'Active - 30 Day Notice':
      return 'rgb(255, 123, 208, 0.6)'
    case 'Month To Month':
      return 'rgb(22, 138, 239, 0.6)'
    default:
      return 'rgb(178, 190, 181, 0.6)'
  }
}
export const renderStatusColor = status => {
  switch (status) {
    case 'Prospect':
      return 'rgb(93, 79, 251)'
    case 'Onboarding':
      return 'rgb(92, 179, 253)'
    case 'In-Progress':
      return 'rgb(110, 156, 222)'
    case 'Active':
      return 'rgb(152, 214, 129)'
    case 'Active (SEO)':
      return 'rgb(152, 98, 161)'
    case 'Active (Creative)':
      return 'rgb(147, 111, 218)'
    case 'Active (Website)':
      return 'rgb(102, 69, 169)'
    case 'Active (Past Due)':
      return 'rgb(255, 131, 88)'
    case 'Active (LTP)':
      return 'rgb(51, 158, 205)'
    case '30 day notice':
      return 'rgb(235, 77, 77)'
    case 'Cancelled':
      return 'rgb(0,0,0)'
    case 'Inactive / Paused':
      return 'rgb(255, 145, 145)'
    default:
      return 'rgb(178, 190, 181)'
  }
}
export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
export const formatDayjsDate = dateStr => {
  // console.log('[renderDealsColumns][formatDayjsDate] dateStr: ', dateStr)
  if (dateStr) {
    return dayjs(dateStr).format('MM/DD/YYYY hh:mm A')
  } else {
    return null
  }
}
export const formatDayJsTime = dateStr => {
  // console.log('[renderDealsColumns][formatDayjsDate] dateStr: ', dateStr)
  if (dateStr) {
    return dayjs(dateStr).format('hh:mm A')
  } else {
    return null
  }
}
const formatDateString = date => {
  if (!date) return ''
  return dayjs.isDayjs(date) ? date.format('MM/DD/YYYY') : dayjs(date).format('MM/DD/YYYY')
}

export const renderDealsColumns = (organizations, users, deals, density = 'standard') => {
  const containsOperator = getGridStringOperators().find(op => op.value === 'contains')

  // Custom operator that wraps the 'contains' logic to invert it
  const customNotContainsOperator = {
    ...containsOperator,
    label: 'does not contain',
    value: 'notContains',
    getApplyFilterFn: filterItem => {
      // console.log('[customNotContainsOperator] filterItem:', filterItem)

      // Check if the filter value is defined
      if (filterItem.value === undefined || filterItem.value === null) {
        // console.log('[customNotContainsOperator] Filter value is undefined or null:', filterItem.value)
        return null
      }

      const containsFn = containsOperator.getApplyFilterFn(filterItem)
      if (!containsFn) {
        // console.log('[customNotContainsOperator] No containsFn found for filterItem:', filterItem)
        return null
      }

      // Log the cellValue during the filter function application
      return cellValue => {
        // console.log('[customNotContainsOperator] cellValue:', cellValue)
        const result = !containsFn(cellValue)
        // console.log('[customNotContainsOperator] Result of filter application:', result)
        return result
      }
    },
  }

  // Log the applied filterOperators

  const densityStyles = getDensityStyles(density)
  const stringAvatar = name => {
    // Ensure 'name' is a string, default to an empty string if not
    const validName = typeof name === 'string' ? name : ''

    const nameParts = validName ? validName.split(' ') : ['']

    if (nameParts.length < 2) {
      return {
        children: (
          <Typography
            sx={{
              fontSize: densityStyles.fontSize,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%',
            }}
          >
            {nameParts[0][0]}
          </Typography>
        ),
      }
    }
    return {
      children: (
        <Typography
          sx={{
            fontSize: densityStyles.fontSize,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          {`${nameParts[0][0]}${nameParts[1][0]}`}
        </Typography>
      ),
    }
  }
  return [
    {
      field: 'name',
      headerName: 'Deal Name',
      width: 100,
      flex: 1.75,
      headerAlign: 'left',
      valueGetter: params => {
        return params
      },
      filterOperators: [...getGridStringOperators(), customNotContainsOperator],
      renderCell: params => {
        const dealId = params.row.id
        return (
          <Link
            to={`/deals/${dealId}`}
            style={{
              textDecoration: 'none',
              color: 'inherit',
              backgroundColor: 'transparent',
            }}
          >
            <Tooltip
              title={<Typography sx={{ backgroundColor: 'transparent' }}>{params.value}</Typography>}
              enterDelay={2000}
              sx={{
                backgroundColor: 'transparent', // Transparent background for tooltip
                boxShadow: 'none', // Remove box shadow
                '.MuiTooltip-tooltip': {
                  backgroundColor: 'transparent', // Make tooltip content background transparent
                  boxShadow: 'none', // Remove box shadow around tooltip
                  padding: 0, // Remove padding if you want only the text to be visible
                },
              }}
            >
              <Box>{params.value}</Box>
            </Tooltip>
          </Link>
        )
      },
    },
    {
      field: 'dealStatus',
      headerName: 'Deal Status',
      width: 100,
      flex: 0.8,
      headerAlign: 'center',
      valueGetter: params => {
        return params
      },
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <GridStatusChip size='small' type={params.value} label={params.value} />
          </Box>
        )
      },
    },
    {
      field: 'type',
      headerName: 'Type',
      width: 100,
      flex: 0.8,
      headerAlign: 'center',
      valueGetter: params => {
        return params
      },
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <GridStatusChip size='small' type={params.value} label={params.value} />
          </Box>
        )
      },
    },
    {
      field: 'startDate',
      headerName: 'Start Date',
      width: 100,
      flex: 0.5,
      headerAlign: 'center',
      valueGetter: params => {
        return params ? formatDateString(params) : null
      },
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            {params && params.value ? params.value : ''}
          </Box>
        )
      },
    },
    {
      field: 'endDate',
      headerName: 'End Date',
      width: 100,
      flex: 0.5,
      headerAlign: 'center',
      valueGetter: params => {
        return params ? formatDateString(params) : null
      },
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            {params && params.value ? params.value : ''}
          </Box>
        )
      },
    },
    {
      field: 'contractId',
      headerName: 'Contract',
      width: 100,
      flex: 0.55,
      headerAlign: 'center',
      valueGetter: params => {
        return params
      },
      renderCell: params => {
        const previewUrl = `https://app.pandadoc.com/a/#/documents`
        const contractId = params && params.value ? params.value : null
        const contractUrl = previewUrl && contractId ? `${previewUrl}/${contractId}` : null
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              padding: densityStyles.padding,
              gap: densityStyles.gap,
            }}
          >
            {contractUrl ? (
              <MuiLink
                href={`${contractUrl}`}
                onClick={e => e.stopPropagation()}
                target='_blank'
                rel='noopener noreferrer'
              >
                <IconButton>
                  <PandaDocLinkIcon size={densityStyles.globeSize} />
                </IconButton>
              </MuiLink>
            ) : (
              <DisabledIconButton disabled>
                <PandaDocLinkIcon size={densityStyles.globeSize} />
              </DisabledIconButton>
            )}
          </Box>
        )
      },
    },
    {
      field: 'organizations',
      headerName: 'Organization',
      width: 100,
      flex: 1.45,
      headerAlign: 'left',
      valueGetter: params => {
        const orgId = params
          ? Array.isArray(params)
            ? params[0]
            : typeof params === 'object' && params.id
            ? params.id
            : null
          : null
        const arrayOrg = organizations.find(org => org.id === orgId) || organizations.find(org => org.zoho_id === orgId)
        return arrayOrg ? arrayOrg.name : ''
      },
      filterOperators: [...getGridStringOperators(), customNotContainsOperator],
      renderCell: params => {
        const orgId =
          params &&
          params.row &&
          params.row.organizations &&
          Array.isArray(params.row.organizations) &&
          params.row.organizations[0]
            ? params.row.organizations[0]
            : params && params.row && params.row.organizations && params.row.organizations.id
            ? params.row.organizations.id
            : null
        const photoUrl = organizations ? organizations.find(org => org.id === orgId)?.photoUrl : null
        const orgName = params && params.value ? params.value : ''

        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'left',
              alignItems: 'center',
              height: '100%',
              padding: densityStyles.padding,
              gap: densityStyles.gap,
            }}
          >
            <Link to={`/organizations/${orgId}`} style={{ textDecoration: 'none', color: 'inherit' }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'left',
                  height: '100%',
                  width: '100%',
                  fontWeight: 'bold',
                }}
              >
                {photoUrl ? (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'left',
                      alignItems: 'center',
                      height: '100%',
                      gap: 1,
                    }}
                  >
                    <Avatar
                      src={photoUrl}
                      sx={{
                        width: densityStyles.avatarSize,
                        height: densityStyles.avatarSize,
                        objectFit: 'cover',
                        objectPosition: 'center',
                      }}
                      alt={params && params.value ? params.value : params}
                    />

                    <Typography
                      sx={{
                        fontSize: densityStyles.fontSize,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '100%',
                      }}
                    >
                      {params && params.value ? params.value : params}
                    </Typography>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'left',
                      alignItems: 'center',
                      height: '100%',
                      gap: 1,
                    }}
                  >
                    <Avatar
                      {...stringAvatar(params && params.value ? params.value : params)}
                      sx={{
                        backgroundColor: 'rgb(114, 229, 218)',
                        width: densityStyles.avatarSize,
                        height: densityStyles.avatarSize,
                      }}
                      alt={params && params.value ? params.value : params}
                    />
                    <Typography
                      sx={{
                        fontSize: densityStyles.fontSize,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '100%',
                      }}
                    >
                      {params && params.value ? params.value : params}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Link>
          </Box>
        )
      },
    },
    {
      field: 'contractedBy',
      headerName: 'Contracted By',
      width: 100,
      flex: 1.15,
      headerAlign: 'left',
      filterOperators: [...getGridStringOperators(), customNotContainsOperator],
      valueGetter: params => {
        const contractedById = params && Array.isArray(params) ? params[0] : []
        const contractedBy = organizations ? organizations.find(org => org.id === contractedById) : null
        return contractedBy ? contractedBy.name : ''
      },
      renderCell: params => {
        const orgId =
          params?.row?.organizations && Array.isArray(params.row.organizations) && params.row.organizations[0]
            ? params.row.organizations[0]
            : null
        const org = organizations ? organizations.find(org => org.id === orgId) : null
        const contractedById = org && Array.isArray(org.contractedBy) ? org.contractedBy[0] : null

        return (
          <>
            {params && params.value ? (
              <RenderContractedByColumn
                key={`${contractedById}`}
                orgName={params.value ?? ''}
                orgId={contractedById}
                densityStyles={densityStyles}
              />
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'left',
                  alignItems: 'center',
                  height: '100%',
                  padding: densityStyles.padding,
                  gap: densityStyles.gap,
                }}
              >
                <Typography sx={{ color: 'rgb(60, 57, 57)', fontSize: 14 }}>No Organization Assigned</Typography>
              </Box>
            )}
          </>
        )
      },
      sortComparator: (a, b) => {
        if (!a && !b) return 0 // Both are empty
        if (!a) return 1 // Treat empty as larger, so a (empty) goes after b (non-empty)
        if (!b) return -1 // Treat empty as larger, so b (empty) goes after a (non-empty)
        return a.localeCompare(b) // Standard string comparison for non-empty values
      },
    },
    {
      field: 'status',
      headerName: 'Document Status',
      width: 100,
      flex: 1,
      headerAlign: 'center',
      valueGetter: params => {
        return params
      },
      filterOperators: [...getGridStringOperators(), customNotContainsOperator],
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              padding: densityStyles.padding,
              gap: densityStyles.gap,
            }}
          >
            <GridStatusChip size='small' type={params.value} label={capitalizeFirstLetter(params.value)} />
          </Box>
        )
      },
    },

    {
      field: 'strategy',
      headerName: 'Strategy',
      width: 100,
      flex: 0.55,
      headerAlign: 'left',
      sortable: true,
      valueGetter: params => {
        return params?.progress ?? ''
      },
      renderCell: params => {
        const status = params.row.status
        const deal = params && params.row ? params.row : null
        const dealId = deal.id
        const orgId = deal.organizations && deal.organizations.length > 0 ? deal.organizations[0] : null
        const selectedOrganization = organizations.find(org => org.id === orgId)
        const selectedVerticals = selectedOrganization ? selectedOrganization.vertical : []
        // const { progress, completed } = analyzeStrategy(params.row)
        // const isCreated = status === 'created' || status === 'Created'
        return (
          <Link
            to={`/deals/${dealId}/strategy`}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '100%',
              textDecoration: 'none',
              color: 'inherit',
            }}
          >
            {/* <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '100%',
              textDecoration: 'none',
              color: 'inherit',
            }}
          > */}
            <StrategyProgress
              dealId={dealId}
              // progress={progress}
              // completed={completed}
              size={densityStyles.globeSize}
            />
            {/* </Box> */}
          </Link>
        )
      },
    },
    {
      field: 'id',
      headerName: 'Vertical',
      flex: 0.7,
      headerAlign: 'center',
      sortable: false,
      valueGetter: params => {
        const deal = deals.find(deal => deal.deal_uuid === params)
        const orgId = deal && Array.isArray(deal.organizations) && deal.organizations[0] ? deal.organizations[0] : []
        const organization = organizations.find(org => org.org_uuid === orgId)
        const verticals = organization && Array.isArray(organization.vertical) ? organization.vertical : []
        const verticalParams = verticals && Array.isArray(verticals) ? verticals[0] : []
        return verticalParams
      },
      filterOperators: [...getGridStringOperators(), customNotContainsOperator],
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              width: '100%',
            }}
          >
            <RenderVertical
              vertical={params.value}
              width={densityStyles.verticalWidth}
              height={densityStyles.verticalHeight}
              size={densityStyles.verticalSize}
            />
          </Box>
        )
      },
    },
    // {
    //   field: 'creation_timestamp',
    //   headerName: 'Time Created',
    //   width: 100,
    //   flex: 1,
    //   headerAlign: 'left',
    //   renderCell: params => {
    //     return (
    //       <Box
    //         sx={{
    //           display: 'flex',
    //           flexDirection: 'row',
    //           justifyContent: 'center',
    //           alignItems: 'center',
    //           height: '100%',
    //           padding: densityStyles.padding,
    //           gap: densityStyles.gap,
    //         }}
    //       >
    //         {formatDayjsDate(params.value)}
    //       </Box>
    //     )
    //   },
    // },
    {
      field: 'stage',
      headerName: 'Deal Stage',
      width: 100,
      flex: 1,
      headerAlign: 'center',
      valueGetter: params => {
        return params
      },
      filterOperators: [...getGridStringOperators(), customNotContainsOperator],
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              padding: densityStyles.padding,
              gap: densityStyles.gap,
            }}
          >
            <GridStageChip size='small' stage={params.value} label={params.value} />
          </Box>
        )
      },
    },
    {
      field: 'lastModified',
      headerName: 'Modified',
      width: 100,
      flex: 1,
      headerAlign: 'left',
      valueGetter: params => params,
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              padding: densityStyles.padding,
              gap: densityStyles.gap,
            }}
          >
            {formatDayjsDate(params && params.value ? params.value : 'No Modified Time')}
          </Box>
        )
      },
    },

    {
      field: 'owner',
      headerName: 'Owner',
      flex: 0.1,
      minWidth: 100,
      headerAlign: 'center',
      valueGetter: params => {
        const userId = params && params[0] ? params[0] : null
        const user = users.find(u => u.id === userId)
        return user && user.name ? user.name : 'No User Assigned'
      },
      renderCell: params => {
        const userId = params && params.row && params.row.owner ? params.row.owner[0] : null
        const user = users.find(u => u.id === userId)
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              padding: densityStyles.padding,
              gap: densityStyles.gap,
            }}
          >
            {user ? (
              <UserImageComponent
                style={userImageStyles}
                imageUrl={user.image_512}
                width={densityStyles.avatarSize}
                height={densityStyles.avatarSize}
              />
            ) : (
              <Box
                sx={{
                  width: densityStyles.avatarSize,
                  height: densityStyles.avatarSize,
                  borderRadius: '50%',
                  backgroundColor: 'lightgray',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: 'rgba(0, 0, 0, 0.6)',
                  fontSize: densityStyles.fontSize,
                }}
              >
                ?
              </Box>
            )}
          </Box>
        )
      },
    },
    {
      field: 'implementationFee',
      headerName: 'Impl. Fee',
      width: 100,
      flex: 0.5,
      headerAlign: 'center',
      valueGetter: params => params,
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              padding: densityStyles.padding,
              gap: densityStyles.gap,
            }}
          >
            {params.value}
          </Box>
        )
      },
    },
    {
      field: 'total',
      headerName: 'Total',
      width: 100,
      flex: 0.5,
      headerAlign: 'center',
      valueGetter: params => params,
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              padding: densityStyles.padding,
              gap: densityStyles.gap,
            }}
          >
            {formatCurrency(params.value)}
          </Box>
        )
      },
    },
  ]
}
export default renderDealsColumns
