import React, { useState } from 'react'
import { Autocomplete, Box, TextField, Chip, useTheme, Tooltip } from '@mui/material'
import CustomCompanyDialog from './CustomCompanyDialog'
import RenderCompanyChip from '../RenderChipComponents/RenderCompanyChip'
import { useCreateOrganization } from '../../api/aws/useCreateOrganization'
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'
import { sortOrganizations } from '../../context/OrganizationsProvider/sortOrganizations'
import AddRenderOptionButton from '../Contacts/AddRenderOptionButton'
import { useOrganizations } from '../../api/aws/useOrganizations'
import DetailsLoading from '../Loading/DetailsLoading'

const CustomParentCompanyAutocomplete = ({ label, value, onChange, context, onBlur, type }) => {
  const { showSnackbar } = useSnackbarContext()
  const theme = useTheme()
  const themeColor = theme.palette.mode === 'dark' ? 'white' : 'black'
  const [dialogOpen, setDialogOpen] = useState(false)
  const [tempInput, setTempInput] = useState('')
  const [inputValue, setInputValue] = useState('')
  const [selectedOrganizations, setSelectedOrganizations] = useState(value || [])
  const { mutate: createOrganization } = useCreateOrganization()
  const { organizations, isOrganizationsLoading, isOrganizationsError } = useOrganizations()
  console.log('[CustomParentCompanyAutocomplete] organizations', organizations)
  const handleOpenDialog = inputValue => {
    setTempInput(inputValue)
    setDialogOpen(true)
  }

  const handleCloseDialog = () => {
    setDialogOpen(false)
    setTempInput('')
    setInputValue('')
  }

  const handleSave = newValue => {
    createOrganization(newValue, {
      onSuccess: data => {
        const newOrg = {
          ...data,
          org_uuid: data.org_uuid,
        }
        const updatedOrganizations = [...selectedOrganizations, newOrg]
        setSelectedOrganizations(updatedOrganizations)
        onChange(
          context,
          updatedOrganizations.map(org => org.org_uuid)
        )
        handleCloseDialog()
        showSnackbar('Organization created successfully', 'success')
      },
    })
  }

  const handleSelectExistingOrganization = (event, newValue) => {
    setSelectedOrganizations(newValue)
    onChange(
      context,
      newValue.map(org => org.id)
    )
    onBlur(
      context,
      newValue.map(org => org.id)
    )
  }

  const handleRemoveOrganization = orgId => {
    const newSelectedOrganizations = selectedOrganizations.filter(org => org.org_uuid !== orgId)
    setSelectedOrganizations(newSelectedOrganizations)
    onChange(
      context,
      newSelectedOrganizations.map(org => org.org_uuid)
    )
    onBlur(
      context,
      newSelectedOrganizations.map(org => org.org_uuid)
    )
  }
  const housingParentCompanyTypes = ['Property Management Company', 'Ownership Group']
  const parentCompanyTypes = ['Parent Client', 'Agency Whitelabel']
  const allParentCompanyTypes = ['Property Management Company', 'Ownership Group', 'Parent Client', 'Agency Whitelabel']
  const sortedOrganizations = sortOrganizations(organizations)
  const filteredOrganizations = sortedOrganizations.filter(organization =>
    housingParentCompanyTypes.includes(organization.type)
  )
  const filteredParentClients = sortedOrganizations.filter(organization =>
    parentCompanyTypes.includes(organization.type)
  )
  const allParentCompanies = sortedOrganizations.filter(organization =>
    allParentCompanyTypes.includes(organization.type)
  )

  const renderExtraChip = (extraCount, extraOrganizations) => (
    <Tooltip
      title={extraOrganizations.map(org => (
        <RenderCompanyChip key={org.id || org.org_uuid} option={org} />
      ))}
      arrow
    >
      <Chip label={`+${extraCount}`} size='small' />
    </Tooltip>
  )

  return (
    <>
      {isOrganizationsLoading && <DetailsLoading />}
      <Autocomplete
        multiple
        id='custom-company-autocomplete'
        // options={
        //   context === 'parentClient'
        //     ? filteredParentClients
        //     : housingParentCompanyTypes.includes(context) && type === 'Housing Property'
        //     ? filteredOrganizations
        //     : filteredOrganizations
        // }
        options={allParentCompanies}
        getOptionLabel={option => option.name || ''}
        value={selectedOrganizations}
        onChange={handleSelectExistingOrganization}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
        filterOptions={(options, { inputValue }) => {
          let filtered = options.filter(option => option.name.toLowerCase().includes(inputValue.toLowerCase()))
          if (inputValue !== '' && !filtered.some(option => option.name.toLowerCase() === inputValue.toLowerCase())) {
            filtered.unshift({
              inputValue,
              specialOption: true,
              org_uuid: `add-new-${inputValue}`,
            })
          }
          return filtered
        }}
        renderOption={(props, option) => {
          const { key, ...rest } = props
          return option.specialOption ? (
            <AddRenderOptionButton
              key={option.org_uuid}
              props={props}
              option={option}
              handleOpenDialog={handleOpenDialog}
            />
          ) : (
            <Box {...rest} key={option.org_uuid}>
              <RenderCompanyChip option={option} />
            </Box>
          )
        }}
        renderTags={(tagValue, getTagProps) => {
          const maxVisibleChips = 1
          const extraCount = tagValue.length - maxVisibleChips
          return (
            <>
              {tagValue.slice(0, maxVisibleChips).map((option, index) => {
                const { key, ...rest } = getTagProps({ index })

                return (
                  <Box key={option.org_uuid} sx={{ maxWidth: '100%', overflow: 'hidden', margin: 0 }}>
                    <Chip
                      key={key}
                      label={<RenderCompanyChip option={option} />}
                      {...rest}
                      onDelete={() => handleRemoveOrganization(option.org_uuid)}
                      size='small'
                      style={{
                        height: 'auto',
                        maxWidth: '220px',
                        width: 'auto',
                        borderColor: themeColor,
                        borderWidth: '0.5px',
                        borderStyle: 'solid',
                        backgroundColor: 'transparent',
                        color: themeColor,
                        borderRadius: '4px',
                        marginRight: '8px',
                      }}
                    />
                  </Box>
                )
              })}
              {extraCount > 0 && renderExtraChip(extraCount, tagValue.slice(maxVisibleChips))}
            </>
          )
        }}
        renderInput={params => <TextField {...params} label={label} autoComplete='off' />}
        fullWidth
        freeSolo
      />
      <CustomCompanyDialog open={dialogOpen} onClose={handleCloseDialog} tempInput={tempInput} onSave={handleSave} />
    </>
  )
}

export default CustomParentCompanyAutocomplete
