import React, { useContext, useEffect, useState } from 'react'
import { Box, Typography, Grid, Button, useTheme, CircularProgress, Tooltip, ListItem, Stack } from '@mui/material'
import { InfoBox, InfoRow, InfoLabel, InfoValueWrapper, CustomInfoBox } from './style'
import AssignOwnerAutocomplete from '../EditModeComponents/AssignOwnerAutocomplete'
import AssignCompaniesAutocomplete from '../EditModeComponents/AssignCompaniesAutocomplete'
import { CreateButton, gridItemStyles, SaveButton } from '../ContractDialog/style'
import PandaDocImageComponent from '../AvatarImageComponents/PandaDocImageComponent'
import DealStatusSelect from '../DealStatusSelect/DealStatusSelect'
import { useDealDetailsContext } from '../../context/DealsDetailsProvider/DealsDetailsProvider'
import ApprovalRequestButton from '../ui/approval-request-button'
import { useActiveUser } from '../../api/slack/useActiveUser'
import { useStrategy } from '../../api/aws/useStrategy'
import { useValidationContext } from '../../context/ValidationProvider/ValidationProvider'
import RequestButtonTooltip from '../ui/request-button-tooltip'
import RenderErrorTooltip from '../Strategy/RenderErrorTooltip'
import RenderErrors from '../Strategy/RenderErrors'

const EditDealInfo = ({
  organization,
  contractedBy,
  onUpdate,
  isCreating,
  onClose,
  onSave,
  onCreate,
  validationErrors,
  onRequestApproval,
  onChange,
  onSaveModal,
  setShowModal,
  setTempDealStatus,
}) => {
  const { newDeal, setNewDeal, resetState, setIsDealStatusChange } = useDealDetailsContext()
  const { strategy, isLoading: isStrategyLoading, isError: isStrategyError } = useStrategy(newDeal.deal_uuid ?? null)
  const { strategyErrors, errorKeys, handleValidateStrategy, isStrategyValid } = useValidationContext()
  const theme = useTheme()
  const gridBgColor = theme.palette.mode === 'dark' ? '#1e2735' : 'rgba(255,255,255)'
  const { approvalState, handleRequestApproval } = useDealDetailsContext()
  const [isError, setIsError] = useState(false)
  const verticalsApprovalNotRequired = ['Student', 'Multifamily']
  const [isRequesting, setIsRequesting] = useState(false)
  const { activeUser } = useActiveUser()
  const [canCreateContract, setCanCreateContract] = useState(
    organization && Array.isArray(organization.vertical)
      ? !verticalsApprovalNotRequired.includes(organization.vertical)
      : false
  )

  useEffect(() => {
    if (newDeal && strategy) {
      handleValidateStrategy(strategy, newDeal)
    }
  }, [newDeal, strategy])
  useEffect(() => {
    if (errorKeys) {
      console.log('errorKeys', errorKeys)
    }
  }, [errorKeys])

  // useEffect(() => {
  //   if (strategyErrors) {
  //     const newErrorKeys = Object.entries(strategyErrors)
  //       .filter(([key, value]) => value === true) // Filter out only the keys where the value is true
  //       .map(([key, value]) => ({ key, value })) // Map the filtered entries to objects
  //     setErrorKeys(newErrorKeys)
  //   }
  // }, [strategyErrors])
  const handleCancel = () => {
    resetState()
    onClose()
  }
  return (
    <>
      {/* <Grid item xs={12} sm={10} md={8} lg={2} xl={9} sx={gridItemStyles}> */}
      <Grid item xs={3} sm={3} md={6} lg={12} xl={12}>
        <CustomInfoBox sx={{ backgroundColor: gridBgColor, position: 'relative' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 2,
            }}
          >
            <Typography
              sx={{
                color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,1)' : 'rgba(0,0,0,1)',
              }}
              variant='h6'
              gutterBottom
            >
              Information
            </Typography>
            <Box sx={{ display: 'flex', gap: 1 }}>
              {isCreating && (
                <CircularProgress
                  sx={{
                    color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,1)' : 'black',
                  }}
                />
              )}
              {isError && <Typography color='error'>Error creating deal</Typography>}
              <Button onClick={handleCancel} color='primary' variant='outlined'>
                Cancel
              </Button>
              <SaveButton onClick={onSave} color='secondary' variant='contained'>
                Save
              </SaveButton>
              {newDeal && !newDeal.noSignature && (
                <Tooltip
                  sx={{ width: '100%' }}
                  title={
                    strategy?.status === 204 && !newDeal.isCompleted && !newDeal.isRequested && !newDeal.isApproved ? (
                      // <RenderErrorTooltip strategy={strategy} isStrategyValid={isStrategyValid} errorKeys={errorKeys} />
                      <Stack>
                        <Typography>No Strategy Attached</Typography>
                        <Typography
                          sx={{
                            textAlign: 'left',
                            fontWeight: 'bold',
                            fontStyle: 'italic',
                            color: theme.palette.text.secondary,
                          }}
                        >
                          {'Important but not required'}
                        </Typography>
                        <Typography>Click Strategy tab to create</Typography>
                      </Stack>
                    ) : !isStrategyValid &&
                      !newDeal.isRequested &&
                      !newDeal.isApproved &&
                      !newDeal.isCompleted &&
                      errorKeys?.length > 0 ? (
                      <RenderErrors errorKeys={errorKeys} />
                    ) : !newDeal.isCompleted ? (
                      <RequestButtonTooltip newDeal={newDeal} />
                    ) : (
                      <Typography>Create Contract</Typography>
                    )
                  }
                >
                  {/* <Tooltip sx={{ width: '100%' }} title={<RequestButtonTooltip newDeal={newDeal} />}> */}
                  <Box>
                    {newDeal && !newDeal.isApproved && !newDeal.isCompleted ? (
                      // !['IO'].includes(newDeal.type) ? (
                      <ApprovalRequestButton
                        isRequested={newDeal.isRequested}
                        isRequesting={isRequesting}
                        onRequest={onRequestApproval}
                        startIcon={true}
                        errorIcon={errorKeys?.length > 0}
                        isValid={isStrategyValid}
                      />
                    ) : (
                      <CreateButton
                        onClick={onCreate}
                        color='primary'
                        variant='contained'
                        startIcon={<PandaDocImageComponent size={30} />}
                        // disabled={newDeal && newDeal.isApproved && newDeal.isCreated}
                      >
                        Create Contract
                      </CreateButton>
                    )}
                  </Box>
                </Tooltip>
              )}
            </Box>
          </Box>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={8} xl={3}>
              <InfoRow>
                <InfoLabel>Owner</InfoLabel>
                <InfoValueWrapper>
                  <AssignOwnerAutocomplete
                    userId={(newDeal.owner && newDeal.owner[0]) || ''}
                    label=''
                    onChange={onChange}
                    context={'owner'}
                    // onBlur={handleObjectBlur}
                    multiple={false}
                  />
                </InfoValueWrapper>
              </InfoRow>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={8} xl={3}>
              <InfoRow>
                <InfoLabel>Organization</InfoLabel>
                <InfoValueWrapper>
                  <AssignCompaniesAutocomplete
                    label=''
                    value={organization ? [organization] : []}
                    context={'organizations'}
                    disabled={true}
                  />
                </InfoValueWrapper>
              </InfoRow>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={8} xl={3}>
              <InfoRow>
                <InfoLabel>Contracted By</InfoLabel>
                <InfoValueWrapper>
                  <AssignCompaniesAutocomplete
                    label=''
                    value={contractedBy ? [contractedBy] : []}
                    context={'organizations'}
                    disabled={true}
                  />
                </InfoValueWrapper>
              </InfoRow>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={8} xl={3}>
              <InfoRow>
                <InfoLabel sx={{ pt: 0.8 }}>Deal Status</InfoLabel>
                <InfoValueWrapper>
                  <DealStatusSelect
                    newDeal={newDeal}
                    setNewDeal={setNewDeal}
                    setIsDealStatusChange={setIsDealStatusChange}
                    onSaveModal={onSaveModal}
                    setShowModal={setShowModal}
                    setTempDealStatus={setTempDealStatus}
                    error={validationErrors.dealStatus}
                    helperText={validationErrors.dealStatus && 'Deal Status is required'}
                  />
                </InfoValueWrapper>
              </InfoRow>
            </Grid>
          </Grid>
        </CustomInfoBox>
      </Grid>
      {/* </Grid> */}
    </>
  )
}

export default EditDealInfo
