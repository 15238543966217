import { useGetQuery } from './useGetQuery'
// Import the UserList type from your types file
import { UserList } from '../../components/Mentions/types.ts'

/**
 * Custom hook to fetch users.
 * @returns {{
 *   users: UserList[],
 *   isUsersLoading: boolean,
 *   isUsersError: boolean
 * }}
 */
export const useUsers = () => {
  const { data, isLoading: isUsersLoading, isError: isUsersError } = useGetQuery('/aws/users', 'users')
  const users = data ?? []
  return { users, isUsersLoading, isUsersError }
}
