import React from 'react'
import { Typography, useTheme, Box, Badge } from '@mui/material'
import RenderSeverityIcon from './RenderSeverityIcon'
import { Link } from 'react-router-dom'
import RenderTypeComponent from './RenderTypeIcon'

const ReadNotification = ({
  itemKey,
  board,
  boardId,
  boardName,
  imageUrl,
  url,
  timestamp,
  message,
  severity,
  title,
  type,
  userId,
  isRead,
  onHover,
}) => {
  const theme = useTheme()

  const renderSeverityColor = severity => {
    switch (severity) {
      case 'info':
      case 'warning':
        return theme.palette.warning.main
      case 'success':
        return theme.palette.success.main
      case 'prio':
      case 'error':
        return theme.palette.error.main
      default:
        return theme.palette.text.primary
    }
  }

  return (
    <Box
      key={itemKey}
      onMouseEnter={onHover} // Trigger read action on hover
      severity={severity}
      gap={1}
      sx={{
        display: 'flex',
        alignItems: 'center',
        border: '1px solid',
        borderColor: isRead ? theme.palette.text.secondary : renderSeverityColor(severity),
        padding: theme.spacing(2),
        borderRadius: theme.shape.borderRadius,
        cursor: 'pointer',
        width: '100%', // Ensure the notification takes the full width of its container
        backgroundColor: theme.palette.background.paper, // Gray out if read
        transition: 'background-color 0.3s ease, opacity 0.3s ease', // Smooth transition
        '&:hover': {
          transform: 'scale(1.01)',
          transition: 'transform 0.2s ease',
        },
      }}
    >
      <Badge
        variant='dot'
        invisible={isRead} // Clear badge only if visually read
        overlap='rectangular'
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <RenderTypeComponent type={type} />
        <Link
          to={`/${board}/${boardId}`}
          style={{
            textDecoration: 'none',
            color: 'inherit',
            backgroundColor: 'transparent',
          }}
        >
          <Typography
            sx={{
              marginLeft: theme.spacing(2),
              color: theme.palette.text.secondary, // Dim text if read
              wordBreak: 'break-word', // Handle long messages gracefully
              flexGrow: 1,
              textAlign: 'left',
            }}
          >
            {message ? `${message} | ${timestamp}` : 'No Notifications'}
          </Typography>
        </Link>
      </Badge>
    </Box>
  )
}

export default ReadNotification
