const productHasValue = params => {
  return params && Array.isArray(params) && params.length > 0
    ? params.map(item => ({
        id: item?.instanceId ?? '',
        creativeSuppliedBy: item?.creativeSuppliedBy ?? '',
        copySuppliedBy: item?.copySuppliedBy ?? '',
      }))
    : []
}

export const analyzeStrategy = (params, itemParams) => {
  const initialAnalyzedState = {
    website: false,
    street: false,
    city: false,
    state: false,
    zip: false,
    cmsUsed: false,
    productsAndServices: false,
    demographicTargeting: false,
    marketingObjective: false,
    geoTargeting: false,
    digitalAdPhone: false,
    // updatedProducts: [], // Array to store each product's status
  }

  let hasValue = []

  // General fields check
  if (params?.generalInfo?.website && params?.generalInfo?.website !== '') initialAnalyzedState.website = true
  if (params?.addtlInfo?.street && params?.addtlInfo?.street !== '') initialAnalyzedState.street = true
  if (params?.addtlInfo?.city && params?.addtlInfo?.city !== '') initialAnalyzedState.city = true
  if (params?.addtlInfo?.state && params?.addtlInfo?.state !== '') initialAnalyzedState.state = true
  if (params?.addtlInfo?.zip && params?.addtlInfo?.zip !== '') initialAnalyzedState.zip = true
  if (params?.addtlInfo?.cmsUsed && params?.addtlInfo?.cmsUsed !== '') initialAnalyzedState.cmsUsed = true
  if (params?.addtlInfo?.productsAndServices && params?.addtlInfo?.productsAndServices !== '')
    initialAnalyzedState.productsAndServices = true
  if (params?.addtlInfo?.demographicTargeting && params?.addtlInfo?.demographicTargeting !== '')
    initialAnalyzedState.demographicTargeting = true
  if (params?.addtlInfo?.marketingObjective && params?.addtlInfo?.marketingObjective !== '')
    initialAnalyzedState.marketingObjective = true
  if (params?.addtlInfo?.geoTargeting && params?.addtlInfo?.geoTargeting !== '')
    initialAnalyzedState.geoTargeting = true
  if (params?.addtlInfo?.digitalAdPhone && params?.addtlInfo?.digitalAdPhone !== '')
    initialAnalyzedState.digitalAdPhone = true

  // Add filled general fields to hasValue
  Object.keys(initialAnalyzedState).forEach(field => {
    if (initialAnalyzedState[field] && field !== 'updatedProducts') {
      hasValue.push(field)
    }
  })
  /*
  // Product fields check for creativeSuppliedBy and copySuppliedBy
  itemParams.forEach(product => {
    const productStatus = {
      id: product.instanceId,
      creativeSuppliedBy: !!product.creativeSuppliedBy,
      copySuppliedBy: !!product.copySuppliedBy,
    }

    // Add to updatedProducts in initialAnalyzedState
    initialAnalyzedState.updatedProducts.push(productStatus)

    // If fields are filled, add them to hasValue for progress calculation
    if (productStatus.creativeSuppliedBy) hasValue.push(`Creative Supplied By (${product.instanceId})`)
    if (productStatus.copySuppliedBy) hasValue.push(`Copy Supplied By (${product.instanceId})`)
  })
*/
  // Calculate progress based on total number of unique fields
  // const totalFields = Object.keys(initialAnalyzedState).length - 1 + itemParams.length * 2 // 10 general fields + 2 fields per product
  const totalFields = Object.keys(initialAnalyzedState).length // 10 general fields + 2 fields per product
  const filledFieldsCount = hasValue.length

  const progress = (filledFieldsCount / totalFields) * 100
  const percentage = parseFloat(progress.toFixed(0)) // Convert to integer percentage

  return { progress: percentage, completed: filledFieldsCount, totalFields: totalFields }
}
