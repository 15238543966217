import { Typography, Chip, Box, useTheme } from '@mui/material'
import React from 'react'
import { renderTypeColor } from '../DataGrid/createColumns/renderDealsColumns'
import { render } from '@testing-library/react'
const TypographyChip = ({ text }) => {
  console.log('text: ', text)
  const theme = useTheme()
  return (
    <Box>
      <Chip size='small' sx={{ height: 15, width: 15, color: renderTypeColor(text) }} />
      <Typography sx={{ color: theme.palette.text.primary, fontSize: 14 }}>{text}</Typography>
    </Box>
  )
}

export default TypographyChip
