import { useGetQueryById } from './useGetQueryById'

export const useOrganizationById = orgId => {
  const { data: organization, isLoading: isOrganizationLoading, isError: isOrganizationError } = useGetQueryById(
    '/aws/organizations',
    'organizations',
    orgId
  )
  return { organization, isOrganizationLoading, isOrganizationError }
}
