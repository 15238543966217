import React from 'react'
import { Box, Typography, useTheme } from '@mui/material'
const RequestButtonTooltip = ({ newDeal }) => {
  return (
    <Box>
      <Typography sx={{ fontSize: 12 }}>
        {!newDeal.isRequested
          ? 'Request Approval'
          : !newDeal.isApproved && !newDeal.isCompleted
          ? 'Deal needs approval before creating contract'
          : 'Create Contract'}
      </Typography>
    </Box>
  )
}

export default RequestButtonTooltip
