import React from 'react'
import { Grid, Box, Typography, TextField, useTheme, Stack, InputLabel, Tooltip } from '@mui/material'
import { useValidationContext } from '../../context/ValidationProvider/ValidationProvider'

const RenderStrategySection = ({ page, fields, formState, isEditMode, onChange, errors }) => {
  const theme = useTheme()
  const handleChange = (fieldName, newValue) => {
    onChange(fieldName, newValue)
  }

  return (
    <Box
      sx={{
        border: '1px solid',
        borderColor: theme.palette.text.secondary,
      }}
    >
      <Grid
        container
        p={0.5}
        sx={{
          backgroundColor: theme.palette.background.section,
          borderRadius: 1,
          alignItems: 'center',
        }}
      >
        {fields.map(field => {
          return (
            <Grid item xs={12} sm={6} key={field.name}>
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}>
                <Typography
                  sx={{
                    fontWeight: 'bold',
                    ml: 2,
                    width: '40%',
                  }}
                  variant='subtitle1'
                >
                  {field.label}
                </Typography>

                {isEditMode ? (
                  <Tooltip
                    title={
                      field.placeholder ? <Typography sx={{ fontSize: 12 }}>{field.placeholder}</Typography> : null
                    }
                  >
                    <TextField
                      name={field.name}
                      value={formState[field.name] || ''}
                      onChange={e => handleChange(field.name, e.target.value)}
                      fullWidth
                      variant='outlined'
                      // disabled={field.readOnly}
                      placeholder={field.placeholder}
                      helperText={
                        errors?.[field.name]?.value && errors?.[field.name]?.errorText
                          ? errors?.[field.name]?.errorText
                          : null
                      } // Show error message
                      size='small'
                      // error={errors[field.name]}
                      error={errors?.[field.name]?.value ? errors?.[field.name]?.value : null}
                      InputProps={{
                        readOnly: field.readOnly,
                      }}
                      sx={{
                        flexGrow: 1,
                        backgroundColor: theme.palette.background.input,
                        borderRadius: '4px',
                        mb: 0.5,
                      }}
                    />
                  </Tooltip>
                ) : (
                  <Typography
                    sx={{
                      flexGrow: 1,
                      padding: '8px 16px',
                      backgroundColor: theme.palette.background.input,
                      borderRadius: '4px',
                      fontSize: '0.875rem',
                      color: theme.palette.mode === 'dark' ? 'rgb(181, 191, 205)' : 'rgb(97, 97, 97)',
                      mb: 0.5,
                    }}
                  >
                    {formState[field.name] || '—'}
                  </Typography>
                )}
              </Box>
            </Grid>
          )
        })}
      </Grid>
    </Box>
  )
}

export default RenderStrategySection
