import React from 'react'
import { Box, Grid, Typography, useTheme } from '@mui/material'
import { renderTypeColor } from '../DataGrid/createColumns/renderDealsColumns'
import format from 'date-fns/format'
import { getStatusColor } from '../DataGrid/createColumns/renderOrganizationColumns'

function formatDate(timestamp) {
  if (!timestamp) return
  return format(new Date(timestamp), 'MM/dd/yyyy')
}
const NcoDrawerHeader = ({ newDeal }) => {
  const theme = useTheme()

  return (
    <Box
      sx={{
        p: 3,
        backgroundColor: theme.palette.background.paper,
        borderRadius: '8px',
        boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)',
      }}
    >
      <Grid
        container
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          overflowY: 'hidden',
        }}
      >
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant='h5' sx={{ fontWeight: 'bold', textAlign: 'left', color: theme.palette.text.primary }}>
              {newDeal?.name || 'no deal name'}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={4} xl={4} sx={{ p: 1, pl: 1.5, pr: 1.5 }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant='subtitle2' color='textSecondary' sx={{ textAlign: 'left' }}>
              Est Launch Date
            </Typography>
            <Typography sx={{ textAlign: 'left', color: theme.palette.text.primary }}>
              {formatDate(newDeal?.estLaunchDate)}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant='subtitle2' color='textSecondary' sx={{ textAlign: 'left' }}>
              Start Date
            </Typography>
            <Typography sx={{ textAlign: 'left', color: theme.palette.text.primary }}>
              {formatDate(newDeal?.startDate)}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant='subtitle2' color='textSecondary' sx={{ textAlign: 'left' }}>
              End Date
            </Typography>
            <Typography sx={{ textAlign: 'left', color: theme.palette.text.primary }}>
              {formatDate(newDeal?.endDate)}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={4} xl={4} sx={{ p: 1, pl: 1.5, pr: 1.5 }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant='subtitle2' color='textSecondary' sx={{ textAlign: 'left' }}>
              NCO Status
            </Typography>
            <Typography sx={{ color: getStatusColor(newDeal?.ncoStatus) }}>
              {newDeal?.ncoStatus || 'Onboarding'}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant='subtitle2' color='textSecondary' sx={{ textAlign: 'left' }}>
              Contract Status
            </Typography>
            <Typography sx={{ color: renderTypeColor(newDeal?.status) }}>
              {newDeal?.status || 'no nco status'}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant='subtitle2' color='textSecondary' sx={{ textAlign: 'left' }}>
              Deal Status
            </Typography>
            <Typography sx={{ color: renderTypeColor(newDeal?.dealStatus) }}>
              {newDeal?.dealStatus || 'no nco status'}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={4} xl={4} sx={{ p: 1, pl: 1.5, pr: 1.5 }}>
          <Box
            sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}
          ></Box>
          <Box
            sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}
          ></Box>
          <Box
            sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}
          ></Box>
          <Box
            sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}
          ></Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default NcoDrawerHeader
