// Creative task in to get ads created, social display, tiktok
// as things come in check in with ryan or andy to see if they have access
// handoff call status : scheduled,
//PM : Set strategy done
// handoff call, once that occurs it goes to campaign creation ready, flip tasks, triggers dms to get started on setting up campaigns
//DMS: google setup task set (marked as qa approved) status dropdown: campaign creation: not started, created/ready for qa
//QA:
//MIA: Create GTM and GA4 accounts, always ask client to grant access, when that occurs slack ryan or andy to confirm if we have access or not.  same with facbook, if having trouble send out request create account shells, verify dependencies,
// would be sitting in awaiting dependencies
//AM: add budgets to MCL/involved in handoff call/once campaigns are launched, look am with client and introduce, let client know theyll set first reporting call in about 2 weeks or when they are available.
// AM STATUS: Client Introduction (email), first call is reporting call after email (usually within 2 weeks, also setup tapclicks before reporting call)
/// Stuck button ran into issues // QA needs edit // QA Edits made //

// when FB Page is checked done, send message to ryan and andy same with GTM, stuck red,
//handoff call kickoff call, target launch date, date closed
//sometimes ryan and andy have them send out gsc tag
// Integration Checklist

// GA4

// Create Gro Managed GA4
// Configure domains
// Configure Unwanted Referrals "Cross-Domain"
// Enable Google Signals
// Set Both Data retention options to 14 Months
// Enable Key Event
// Update Key Event Counting Method to "once per session"

// GSC

// Create HTML Tag & send
// Verfy Account
// Link Google Ads
// Link GA4

// GTM

// Verfiy Account Level Access As admin
// Add Platform Tags
// Add Event Tags
// Confirm Tags are firing in preview mode
// events are populating in GA4 Debug mode
// Confirm Event show up in Facebook Event Debugger
// Confirm Events fire off in tiktok prevew mode

// Google Ads

// Create Google Ads Account
// Link Google Ads Account to GA4

// Facebook

// Create Account
// Reasign FB pixel To the new account
// remove FB pixel from Old GTM
// Remove old custom Conversions

// TikTok

// Create Account

// Simplifi

// Create Account
// Create Site Tags
// Create Audience and Link to Tags

export const ncoTeamTasks = [
  {
    PM: [
      {
        checklistSent: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
      {
        creativeTasksSent: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
      {
        creativesReady: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
    ],
    MIA: [
      {
        verifyDependencies: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
      {
        createGTMGA4: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
      ///////
      {
        configureDomains: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
      {
        enableKeyEvent: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
      {
        enableGoogleSignals: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
      {
        keyCountingMethod: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
      //////////
      {
        createAccountShells: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
    ],
    SEO: [
      {
        createGSCTagForPM: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
      {
        onboardingChecklist: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
    ],
    AM: [
      {
        introEmail: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
      {
        tapclicksSetup: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
      {
        setReportingCallDate: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
    ],
    DMS: [
      {
        campaignCreation: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
      {
        launchCampaigns: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
    ],

    QA: [
      {
        campaignQuality: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
      {
        adApprovals: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
    ],
  },
]

export const taskNameMapping = {
  checklistSent: 'Checklist Sent',
  creativeTasksSent: 'Creative Tasks Sent',
  creativesReady: 'Creatives Ready',
  verifyDependencies: 'Verify Dependencies',
  createGTMGA4: 'Create GTM/GA4',
  //////
  configureDomains: 'Configure Domains',
  enableKeyEvent: 'Enable Key Event',
  enableGoogleSignals: 'Enable Google Signals',
  keyCountingMethod: 'Update Key Event Counting Method to "once per session"',
  //////
  createAccountShells: 'Create Account Shells',
  createGSCTagForPM: 'Create GSC Tag for PM',
  onboardingChecklist: 'Onboarding Checklist',
  introEmail: 'Intro Email',
  tapclicksSetup: 'Tapclicks Setup',
  setReportingCallDate: 'Set Reporting Call Date',
  campaignCreation: 'Campaign Creation',
  launchCampaigns: 'Launch Campaigns',
  campaignQuality: 'Campaign Quality',
  adApprovals: 'Ad Approvals',
}

export const ncoDependenciesList = [
  {
    Dependency: [
      {
        fbPage: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'Requested', color: 'rgb(253, 188, 100)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
      {
        creativeAssets: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'Requested', color: 'rgb(253, 188, 100)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
      {
        GA4: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'Requested', color: 'rgb(253, 188, 100)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
      {
        GTM: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'Requested', color: 'rgb(253, 188, 100)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
      {
        Integration: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'Requested', color: 'rgb(253, 188, 100)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
      // {
      //   Creatives: [
      //     { name: 'N/A', color: 'rgb(121, 126, 147)' },
      //     { name: 'Requested', color: 'rgb(253, 188, 100)' },
      //     { name: 'Done', color: 'rgb(51, 211, 145)' },
      //   ],
      // },
      {
        websiteAccess: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'Requested', color: 'rgb(253, 188, 100)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
    ],
  },
]
export const dependenciesNameMapping = {
  fbPage: 'FB Page',
  creativeAssets: 'Creative Assets',
  GA4: 'GA4',
  GTM: 'GTM',
  Integration: 'Integration',
  // Creatives: 'Creatives',
  websiteAccess: 'Website Access',
}

export const ncoChannelTasks = [
  {
    Channel: [
      {
        Search: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'Ready To Create', color: 'rgb(51, 158, 205)' },
          { name: 'Ready for QA', color: 'rgb(87, 155, 252)' },
          { name: 'Launched', color: 'rgb(51, 211, 145)' },
          { name: 'Awaiting Client', color: 'rgb(232, 105, 125)' },
        ],
      },
      {
        Social: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'Ready To Create', color: 'rgb(51, 158, 205)' },
          { name: 'Ready for QA', color: 'rgb(87, 155, 252)' },
          { name: 'Launched', color: 'rgb(51, 211, 145)' },
          { name: 'Awaiting Client', color: 'rgb(232, 105, 125)' },
        ],
      },
      {
        Display: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'Ready To Create', color: 'rgb(51, 158, 205)' },
          { name: 'Ready for QA', color: 'rgb(87, 155, 252)' },
          { name: 'Launched', color: 'rgb(51, 211, 145)' },
          { name: 'Awaiting Client', color: 'rgb(232, 105, 125)' },
        ],
      },
    ],
  },
]

export const ncoMasterStatus = [
  {
    status_name: 'Awaiting Dependencies',
    status_color_hexcode: 'rgb(87, 155, 252)',
  },
  {
    status_name: 'Dependencies Received',
    status_color_hexcode: 'rgb(255, 131, 88)',
  },
  { status_name: 'Handoff Call', status_color_hexcode: 'rgb(120, 75, 209)' },
  { status_name: 'Ready To Create', status_color_hexcode: 'rgb(250, 200, 50)' },
  { status_name: 'Launched', status_color_hexcode: 'rgb(3, 127, 76)' },
  // {
  //   status_name: 'Dependencies Verified',
  //   status_color_hexcode: 'rgb(226, 68, 92)',
  // },
  // { status_name: 'Agreement Signed', status_color_hexcode: 'rgb(0, 200, 117)' },
]
