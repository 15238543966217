import React, { useState } from 'react'
import { Box, Typography, Grid, IconButton, useTheme, Link as MuiLink } from '@mui/material'
import {
  InfoBox,
  InfoRow,
  InfoLabel,
  InfoValueWrapper,
  InfoValue,
  StyledLink,
  CityStateZipRow,
  PhoneRow,
} from './style'
import UserImageComponent from '../Users/UserImageComponent'
import { useUsersById } from '../../api/customHooks/useUsersById'
import { renderTypeColor } from '../DataGrid/createColumns/renderDealsColumns'
import GroboticLight from '../../images/Grobotic png-lightmode.png'
import GroboticDark from '../../images/Grobotic png.png'
import { formatNumber } from '../DealsKanban/aggregateStageTotals'
import RenderDealContacts from './RenderDealContacts'
import RenderDealInfoLink from './RenderDealInfoLink'
import EditIconButton from '../ui/edit-icon-button'
import PandaDocLinkIcon from '../Icons/PandaDockLinkIcon'
import { DisabledIconButton } from '../DataGrid/style'
import { useOrganizationById } from '../../api/aws/useOrganization'
import DetailsLoading from '../Loading/DetailsLoading'
import { useDealDetailsContext } from '../../context/DealsDetailsProvider/DealsDetailsProvider'
import { calculatePaymentSchedule } from '../PaymentSchedule/calculatePaymentSchedule'
import { calculateDayjsTotalMonths } from '../../utility/calculateTotalMonths'
import useTotals from '../../api/customHooks/useTotals'

const DealInfoCard = ({ onSave, onEdit, newContacts }) => {
  const { newDeal, productRows } = useDealDetailsContext()
  const totals = useTotals(productRows, newDeal?.implementationFee || 0, newDeal.type)
  const orgId = newDeal && newDeal.organizations && Array.isArray(newDeal.organizations) ? newDeal.organizations[0] : []
  const theme = useTheme()
  const gridBgColor = theme.palette.mode === 'dark' ? '#1e2735' : 'rgba(255,255,255)'
  const userIds = newDeal && Array.isArray(newDeal.owner) ? newDeal.owner.map(id => id) : []
  const [isDownloading, setIsDownloading] = useState(false)
  const { users, isUsersByIdLoading, isUsersByIdError } = useUsersById(userIds)
  const { organization, isOrganizationLoading, isOrganizationError } = useOrganizationById(orgId)
  const { organization: contractedBy } = useOrganizationById(
    organization && Array.isArray(organization.contractedBy) ? organization.contractedBy[0] : null
  )
  const payments = calculatePaymentSchedule(newDeal)
  const { totalMonths, allSame } = newDeal ? calculateDayjsTotalMonths(newDeal) : { totalsMonths: 1 }
  const csaTypes = ['CSA', 'RCSA']

  return (
    <InfoBox sx={{ backgroundColor: gridBgColor }}>
      {isOrganizationLoading && <DetailsLoading />}
      <Box sx={{ display: 'flex', justifyContent: 'right' }}>
        <EditIconButton color={'primary'} variant='contained' onEdit={onEdit}>
          Edit Deal
        </EditIconButton>
      </Box>
      <Typography
        sx={{ color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,1)' : 'rgba(0,0,0,1)' }}
        variant='h6'
        gutterBottom
      >
        Information
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          {/* Owner Row */}
          <InfoRow>
            <InfoLabel>Owner</InfoLabel>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
              {users && Array.isArray(users) ? (
                users.map(user => (
                  <Box key={user.id} sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <UserImageComponent imageUrl={user.image_512} width={40} height={40} name={user.name} />
                    <Typography>{user.name}</Typography>
                  </Box>
                ))
              ) : (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <img height={40} width={40} src={theme.palette.mode === 'dark' ? GroboticDark : GroboticLight} />
                  <Typography>No Owner Assigned</Typography>
                </Box>
              )}
            </Box>
          </InfoRow>
          <InfoRow>
            <InfoLabel>Name</InfoLabel>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2, m: 0, p: 0 }}>
              <InfoValue>{newDeal.name}</InfoValue>

              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {newDeal.contractId ? (
                  <MuiLink
                    href={`https://app.pandadoc.com/a/#/documents/${newDeal.contractId}`}
                    onClick={e => e.stopPropagation()}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <IconButton sx={{ padding: 0 }}>
                      <PandaDocLinkIcon size={36} />
                    </IconButton>
                  </MuiLink>
                ) : (
                  <DisabledIconButton sx={{ padding: 0 }} disabled>
                    <PandaDocLinkIcon size={36} />
                  </DisabledIconButton>
                )}
              </Box>
            </Box>
          </InfoRow>
          <InfoRow>
            <InfoValueWrapper>
              <InfoLabel>Type</InfoLabel>
              {newDeal && newDeal.type ? (
                <InfoValue sx={{ color: renderTypeColor(newDeal.type) }}>{newDeal.type}</InfoValue>
              ) : (
                <InfoValue>No Type Assigned</InfoValue>
              )}
            </InfoValueWrapper>
          </InfoRow>
          <InfoRow>
            <InfoLabel>Organization</InfoLabel>
            <InfoValueWrapper>
              <StyledLink key={orgId} to={`/organizations/${orgId}`}>
                <RenderDealInfoLink organization={organization} />
              </StyledLink>
            </InfoValueWrapper>
          </InfoRow>
          <InfoRow>
            <InfoLabel>Contracted By</InfoLabel>
            <InfoValueWrapper>
              {contractedBy ? (
                <StyledLink key={contractedBy.org_uuid} to={`/organizations/${contractedBy.org_uuid}`}>
                  <RenderDealInfoLink organization={contractedBy} />
                </StyledLink>
              ) : (
                <Typography sx={{ color: 'rgba(211,211,211, 0.7)' }}>No Organization Assigned</Typography>
              )}
            </InfoValueWrapper>
          </InfoRow>
        </Grid>
        <Grid item xs={12} sm={6}>
          <InfoRow>
            <InfoLabel>Deal Status</InfoLabel>
            <InfoValueWrapper>
              <InfoValue sx={{ color: renderTypeColor(newDeal.dealStatus) }}>{newDeal.dealStatus}</InfoValue>
            </InfoValueWrapper>
          </InfoRow>
          {/* Ensure consistent margins for each info section */}
          <InfoRow>
            <InfoLabel>Contacts</InfoLabel>
            <InfoValueWrapper>
              <RenderDealContacts contacts={newContacts} />
            </InfoValueWrapper>
          </InfoRow>

          {/* Authorized Recipient */}
          <InfoRow>
            <InfoLabel>Authorized Recipient</InfoLabel>
            <InfoValueWrapper>
              {newDeal?.recipients?.authorizedRecipient?.map(ar => (
                <StyledLink key={ar.id} to={`/users/${ar.id}`}>
                  {ar.email}
                </StyledLink>
              ))}
            </InfoValueWrapper>
          </InfoRow>

          {/* Contact Recipient */}
          <InfoRow>
            <InfoLabel>Contact Recipient</InfoLabel>
            <InfoValueWrapper>
              {newDeal?.recipients?.contactRecipient?.map(cr => (
                <StyledLink key={cr.id} to={`/contacts/${cr.id}`}>
                  {cr.email}
                </StyledLink>
              ))}
            </InfoValueWrapper>
          </InfoRow>

          {/* Financial Totals */}
          <CityStateZipRow>
            <Box sx={{ flex: 1 }}>
              <InfoLabel>Gross Profit</InfoLabel>
              <InfoValueWrapper>
                {allSame ? (
                  <InfoValue>{`$ ${newDeal && newDeal.totals ? formatNumber(totals.grossProfit) : 0}`}</InfoValue>
                ) : (
                  <InfoValue sx={{ color: 'rgb(140, 144, 150)' }}>Varying dates</InfoValue>
                )}
              </InfoValueWrapper>
            </Box>
            <Box sx={{ flex: 1 }}>
              <InfoLabel>Monthly Total</InfoLabel>
              <InfoValueWrapper>
                {newDeal && csaTypes.includes(newDeal.type) ? (
                  <InfoValue>{`${newDeal ? '—' : ''}`}</InfoValue>
                ) : (
                  <>
                    {allSame ? (
                      <InfoValue>{`$ ${newDeal && newDeal.totals ? formatNumber(totals.monthlyTotal) : 0}`}</InfoValue>
                    ) : (
                      <InfoValue sx={{ color: 'rgb(140, 144, 150)' }}>Varying dates</InfoValue>
                    )}
                  </>
                )}
              </InfoValueWrapper>
            </Box>
          </CityStateZipRow>

          <PhoneRow>
            <Box sx={{ flex: 1 }}>
              <InfoLabel>Total Gross Profit</InfoLabel>
              <InfoValueWrapper>
                {allSame ? (
                  <InfoValue>
                    {newDeal && newDeal.totals && csaTypes.includes(newDeal.type)
                      ? `$ ${formatNumber(totals.grossProfit)}`
                      : newDeal && newDeal.totals && !csaTypes.includes(newDeal.type)
                      ? `$ ${formatNumber(totals.totalGrossProfit)}`
                      : null}
                  </InfoValue>
                ) : (
                  <InfoValue sx={{ color: 'rgb(140, 144, 150)' }}>Varying dates</InfoValue>
                )}
              </InfoValueWrapper>
            </Box>
            <Box sx={{ flex: 1 }}>
              <InfoLabel>Total</InfoLabel>
              <InfoValueWrapper>
                <InfoValue>{`$ ${newDeal && newDeal.totals ? formatNumber(totals.total) : 0}`}</InfoValue>
              </InfoValueWrapper>
            </Box>
          </PhoneRow>
        </Grid>
      </Grid>
    </InfoBox>
  )
}

export default DealInfoCard
