import { Box, Typography, useTheme } from '@mui/material'
import React from 'react'

const renderErrorText = errorKey => {
  switch (errorKey) {
    case 'cmsUsed':
      return 'CMS used'
    case 'demographicTargeting':
      return 'Demographic targeting'
    case 'geoTargeting':
      return 'Geo targeting'
    case 'marketingObjective':
      return 'Marketing objective'
    case 'digitalAdPhone':
      return 'Digital ads #'
    case 'productsAndServices':
      return 'Products and services'
    default:
      return errorKey
  }
}

const RenderErrors = ({ errorKeys }) => {
  const theme = useTheme()
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography sx={{ textAlign: 'left', fontWeight: 'bold' }}>{'Missing strategy fields'}</Typography>
      <Typography
        sx={{ textAlign: 'left', fontWeight: 'bold', fontStyle: 'italic', color: theme.palette.text.secondary }}
      >
        {'Important but not required'}
      </Typography>
      {errorKeys && errorKeys?.length > 0
        ? errorKeys.map(error => (
            <Typography
              sx={{
                color: 'rgba(220, 20, 60)',
                textAlign: 'left',
                fontWeight: 'bold',
                transition: 'color 0.3s ease-in-out',
                fontSize: 18,
              }}
              key={error.key}
            >
              {renderErrorText(error.key)}
            </Typography>
          ))
        : null}
    </Box>
  )
}

export default RenderErrors
