import React, { useMemo } from 'react'
import { FormControl, InputLabel, Box, MenuItem, Select } from '@mui/material'
import { taskNameMapping } from './ncoTaskObjects'
import { renderTypeColor } from '../DataGrid/createColumns/renderDealsColumns'
import { useDealDetailsContext } from '../../context/DealsDetailsProvider/DealsDetailsProvider'

const RoleTaskComponent = ({ taskGroupName, taskOptions, role, onChange }) => {
  const { newDeal: deal } = useDealDetailsContext()
  const roleTasksPropertyName = `${role.toLowerCase()}Tasks`
  // Check if roleTasks exist and retrieve the initial value
  // const roleTasks = deal[roleTasksPropertyName]
  const initialValue = useMemo(() => {
    const roleTasks = deal[roleTasksPropertyName]
    return roleTasks && roleTasks[taskGroupName]
      ? roleTasks[taskGroupName]
      : taskOptions.length > 0
      ? taskOptions[0].name
      : 'N/A'
  }, [deal, roleTasksPropertyName, taskGroupName, taskOptions])
  const displayLabel = taskNameMapping[taskGroupName]
  return (
    <FormControl
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <InputLabel id={`${taskGroupName}-select-label`}>{displayLabel || taskGroupName}</InputLabel>
      <Select
        labelId={`${taskGroupName}-select-label`}
        id={`${taskGroupName}-select`}
        value={initialValue}
        onChange={event => onChange(taskGroupName, event.target.value, role)}
        sx={{ width: '100%', maxHeight: '58px' }}
        label={displayLabel}
        renderValue={selected => (
          <Box
            sx={{
              padding: '6px',
              backgroundColor: renderTypeColor(selected),
              width: '100%',
            }}
          >
            {selected}
          </Box>
        )}
        IconComponent={() => null}
      >
        {taskOptions.map((option, index) => (
          <MenuItem key={index} value={option.name} sx={{ backgroundColor: renderTypeColor(option.name) }}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
export default RoleTaskComponent
