import React from 'react'
import { Box, Typography, Table, TableRow, TableCell, TableBody, TableHead, useTheme, Grid } from '@mui/material'
import { formatCurrency } from '../DealsKanban/aggregateStageTotals'
import RenderServiceIcon from '../RenderServiceIcons/RenderServiceIcon'
import { tableCellStyles, tableHeaderStyles } from '../DealApproval/style'
import { calculateRangeForProducts, calculateTotalMonthsForProducts } from '../../utility/calculateTotalMonths'
import PandaDocDownload from './PandaDocDownload'
import PandaDocViewer from './PandDocViewer'
import { useDealDetailsContext } from '../../context/DealsDetailsProvider/DealsDetailsProvider'

const IOTotalSchedule = ({ totals, hideViewIcon }) => {
  const { newDeal: deal } = useDealDetailsContext()
  const theme = useTheme()
  const {
    dateRange: { start, end },
  } = deal && Array.isArray(deal.products) ? calculateTotalMonthsForProducts(deal.products) : { dateRange: {} }
  const { totalMonths, allSame } =
    deal && Array.isArray(deal.products) ? calculateTotalMonthsForProducts(deal.products) : { totalMonths: 1 }
  // const { allSame } =
  //   deal && Array.isArray(deal.products) ? calculateTotalMonthsForProducts(deal.products) : { totalMonths: 1 }
  // Ensure the deal type is IO
  if (deal.type !== 'IO') {
    return <Typography>This component is only for IO deals</Typography>
  }

  // Calculate total IO cost by summing all product costs and multiplying by totalMonths
  // const ioTotal = deal.products.reduce((total, product) => total + parseFloat(product.price || 0), 0)
  const ioTotal = deal && deal.totals ? totals.total : deal.totals.total
  const varyingDateTotal = deal.products.reduce((total, product) => {
    const productQty = product?.qty || 1
    const price = product.payer === 'Client' ? parseFloat(product.billedPrice || 0) : parseFloat(product.price || 0)
    const rowTotal = price * productQty
    return total + rowTotal // Ensure price is a valid number
  }, 0)

  // Calculate the complete total by multiplying the total cost of products by the total months
  const completeTotal = totalMonths > 0 ? ioTotal * totalMonths : ioTotal
  return (
    <Grid container spacing={1} sx={{ maxWidth: '80vw' }}>
      <Grid item xs={12}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={deal && deal.status === 'Completed' ? 1.5 : 2} sx={tableHeaderStyles}>
                {start && end ? `${start} - ${end} | ${totalMonths} mo(s).` : `No Start and End Date`}
              </TableCell>
              {deal && deal.status === 'Completed' && (
                <TableCell colSpan={deal && deal.status === 'Completed' ? 0.5 : 2} sx={tableHeaderStyles}>
                  <Box sx={{ display: hideViewIcon ? 'none' : '' }}>
                    <PandaDocViewer contractId={deal ? deal.contractId : null} />
                  </Box>
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {deal.products.map((product, idx) => {
              const productRowPrice = product && product.payer === 'Client' ? product.billedPrice : product.price
              return (
                <TableRow key={`${idx}-${product.productName}`}>
                  <TableCell sx={tableCellStyles(theme)}>
                    <RenderServiceIcon service={product.productName} />
                  </TableCell>
                  <TableCell sx={tableCellStyles(theme)}>
                    {' '}
                    <Typography
                      sx={{
                        color: product?.payer === 'Client' ? theme.palette.text.warning : theme.palette.text.primary,
                      }}
                    >
                      {formatCurrency(productRowPrice)}
                    </Typography>
                  </TableCell>
                </TableRow>
              )
            })}
            <TableRow>
              <TableCell
                sx={{
                  ...tableCellStyles(theme),
                  textAlign: 'right',
                  borderRight: 'none',
                }}
                colSpan={1}
              >
                Total IO Cost:
              </TableCell>
              <TableCell
                sx={{
                  ...tableCellStyles(theme),
                  color: 'rgb(50, 187, 130)',
                  borderLeft: 'none',
                }}
                colSpan={1}
              >
                {formatCurrency(allSame ? completeTotal : varyingDateTotal)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  )
}

export default IOTotalSchedule

// import React from 'react'
// import {
//   Box,
//   Typography,
//   Table,
//   TableRow,
//   TableCell,
//   TableBody,
//   TableHead,
//   useTheme,
//   Grid,
//   IconButton,
// } from '@mui/material'
// import { useParams } from 'react-router-dom'
// import { useDeal } from '../../api/aws/useDeal'
// import { formatCurrency } from '../DealsKanban/aggregateStageTotals'
// import RenderServiceIcon from '../RenderServiceIcons/RenderServiceIcon'
// import { tableCellStyles, tableHeaderStyles } from '../DealApproval/style'
// import { calculateRangeForProducts, calculateTotalMonthsForProducts } from '../../utility/calculateTotalMonths'
// import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline'
// import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
// import DownloadIcon from '@mui/icons-material/Download'
// import PandaDocDownload from './PandaDocDownload'
// import { downloadContract } from '../Deals/downloadContract'

// const IOTotalSchedule = ({ deal, isDownloading, onDownload }) => {
//   const theme = useTheme()
//   const {
//     dateRange: { start, end },
//   } = deal && Array.isArray(deal.products) ? calculateTotalMonthsForProducts(deal.products) : { dateRange: {} }
//   const { totalMonths } =
//     deal && Array.isArray(deal.products) ? calculateRangeForProducts(deal.products) : { totalMonths: 1 }
//   // Ensure the deal type is IO
//   if (deal.type !== 'IO') {
//     return <Typography>This component is only for IO deals</Typography>
//   }

//   // Calculate total IO cost by summing all product costs
//   const ioTotal = deal.products.reduce((total, product) => total + parseFloat(product.price || 0), 0)
//   const completeTotal = ioTotal ? ioTotal * totalMonths : ioTotal
//   return (
//     <Grid container spacing={1} sx={{ maxWidth: '80vw' }}>
//       <Grid item xs={12}>
//         <Table>
//           <TableHead>
//             <TableRow>
//               <TableCell colSpan={deal && deal.status === 'Completed' ? 1.5 : 2} sx={tableHeaderStyles}>
//                 {start && end ? `${start} - ${end} | ${totalMonths} mo(s).` : `No Start and End Date`}
//               </TableCell>
//               {deal && deal.status === 'Completed' && (
//                 <TableCell colSpan={deal && deal.status === 'Completed' ? 0.5 : 2} sx={tableHeaderStyles}>
//                   <PandaDocDownload onDownload={onDownload} isDownloading={isDownloading} />
//                 </TableCell>
//               )}
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {deal.products.map((product, idx) => (
//               <TableRow key={`${idx}-${product.productName}`}>
//                 <TableCell sx={tableCellStyles(theme)}>{<RenderServiceIcon service={product.productName} />}</TableCell>
//                 <TableCell sx={tableCellStyles(theme)}>{formatCurrency(product.price)}</TableCell>
//               </TableRow>
//             ))}
//             <TableRow>
//               <TableCell
//                 sx={{
//                   ...tableCellStyles(theme),
//                   textAlign: 'right',
//                   borderRight: 'none',
//                 }}
//                 colSpan={1}
//               >
//                 Total IO Cost:
//               </TableCell>
//               <TableCell
//                 sx={{
//                   ...tableCellStyles(theme),
//                   color: 'rgb(50, 187, 130)',
//                   borderLeft: 'none',
//                 }}
//                 colSpan={1}
//               >
//                 {formatCurrency(completeTotal)}
//               </TableCell>
//             </TableRow>
//           </TableBody>
//         </Table>
//       </Grid>
//     </Grid>
//   )
// }

// export default IOTotalSchedule
