export const strategyFieldConfig = {
  'General Information': [
    {
      name: 'organizationName',
      label: 'Organization Name',
      type: 'text',
      required: true,
      fullLabel: 'Name',
      readOnly: true,
      path: 'generalInfo.organizationName',
    },
    {
      name: 'vertical',
      label: 'Vertical',
      type: 'text',
      required: true,
      fullLabel: 'Vertical',
      readOnly: true,
      path: 'generalInfo.vertical',
    },
    {
      name: 'type',
      label: 'Contract Type',
      type: 'text',
      required: true,
      fullLabel: 'Contract Type',
      readOnly: true,
    },
    {
      name: 'website',
      label: 'Website',
      type: 'url',
      required: true,
      fullLabel: 'Website',
      readOnly: true,
    },
    {
      name: 'groContact',
      label: 'GRO Email',
      type: 'email',
      required: true,
      fullLabel: 'GRO Email',
      readOnly: true,
    },
    {
      name: 'orgContact',
      label: 'Contact Email',
      type: 'email',
      required: true,
      fullLabel: 'Contact Email',
      readOnly: true,
    },
  ],
  "Addt'l Info": [
    {
      name: 'street',
      label: 'Street',
      type: 'text',
      required: true,
      fullLabel: "Organization's Street Address",
      readOnly: true,
    },
    {
      name: 'city',
      label: 'City',
      type: 'text',
      required: true,
      fullLabel: "Organization's City",
      readOnly: true,
    },
    {
      name: 'state',
      label: 'State',
      type: 'text',
      required: true,
      fullLabel: "Organization's State",
      readOnly: true,
    },
    {
      name: 'zip',
      label: 'Zip',
      type: 'text',
      required: true,
      fullLabel: "Organization's Zip Code",
      readOnly: true,
    },
    {
      name: 'productsAndServices',
      label: 'Products and services to focus on',
      type: 'text',
      required: true,
      fullLabel: 'Products and services to focus on',
      placeholder: 'HVAC (heating & AC, plumbing, electrical)',
      // readOnly: true,
    },
    {
      name: 'marketingObjective',
      label: 'Marketing Objective',
      type: 'text',
      required: true,
      fullLabel: `Marketing Objective <br /> a. What is the client's overall marketing objective? Do they want Leads, Branding/awareness, In-Store Visits, Website traffic or a mix of these? <br /> i. Keep in mind that more than one objective should come with more budget`,
      placeholder: `What is the client's overall marketing objective? Do they want Leads, Branding/awareness, In-Store Visits, Website traffic or a mix of these?`,
      // readOnly: true,
    },
    {
      name: 'geoTargeting',
      label: 'Geographic Targeting',
      type: 'text',
      required: true,
      fullLabel: 'Geographic Targeting <br /> a. State, City, Zip codes, etc',
      placeholder: 'City, State, Zipcode, Radius',
      // readOnly: true,
    },
    {
      name: 'demographicTargeting',
      label: 'Demographic Targeting',
      type: 'text',
      required: true,
      fullLabel: 'Search Demographic Targeting',
      placeholder: 'Age, Gender, HHI',
    },
    {
      name: 'cmsUsed',
      label: 'CMS Being Used',
      type: 'text',
      required: true,
      fullLabel: 'e.g. Wordpress, entrata, squarespace',
      // readOnly: true,
    },
    {
      name: 'digitalAdPhone',
      label: 'Digital Ads Phone Number Used',
      type: 'text',
      required: true,
      // readOnly: true,
    },
    {
      name: 'callTracking',
      label: 'Is the client using call tracking?',
      type: 'radio',
      options: ['Yes', 'No'],
      required: true,
      fullLabel: 'Is the client using call tracking?',
    },
  ],
  Integration: [
    {
      name: 'targetingSpecifics',
      label: 'Targeting Specifics',
      type: 'text',
      required: false,
      fullLabel: 'Search Targeting Specifics',
      placeholder: 'Campaign breakdown, Keyword specifics',
    },
    {
      name: 'formPresent',
      label: 'Is there a third party form present on website?',
      type: 'text',
      required: false,
      fullLabel: 'Is there a third party form present on website?',
    },
  ],
  'Paid Search': [
    {
      name: 'landingPageUrl',
      label: 'Landing Page URL',
      type: 'text',
      required: false,
      fullLabel: 'Search Landing Page URL',
    },
    {
      name: 'searchMarketingObjective',
      label: 'Marketing Objective',
      type: 'text',
      required: true,
      fullLabel: `Marketing Objective <br /> a. What is the client's overall marketing objective? Do they want Leads, Branding/awareness, In-Store Visits, Website traffic or a mix of these? <br /> i. Keep in mind that more than one objective should come with more budget`,
      placeholder: 'Marketing Objective',
      readOnly: true,
    },
  ],
  'Paid Social': [
    {
      name: 'socialMarketingObjective',
      label: 'Marketing Objective',
      type: 'text',
      required: true,
      fullLabel: `Marketing Objective <br /> a. What is the client's overall marketing objective? Do they want Leads, Branding/awareness, In-Store Visits, Website traffic or a mix of these? <br /> i. Keep in mind that more than one objective should come with more budget`,
      placeholder: 'Marketing Objective',
      readOnly: true,
    },
    {
      name: 'socialLandingPageUrl',
      label: 'Landing Page URL',
      type: 'text',
      required: false,
      fullLabel: 'Search Landing Page URL',
    },
  ],
  Display: [
    {
      name: 'displayLandingPage',
      label: 'Landing Page URL',
      type: 'text',
      required: false,
      fullLabel: 'Landing Page URL?',
    },
    {
      name: 'displayGeoTargeting',
      label: 'Geo Targeting',
      type: 'text',
      required: false,
      fullLabel: 'Geographic Targeting',
    },
    {
      name: 'displayTargetingSpecifics',
      label: 'Targeting Specifics',
      type: 'text',
      required: false,
      fullLabel: 'Targeting Specifics',
    },
  ],
  SEO: [
    {
      name: 'cmsLogin',
      label: 'CMS Login',
      type: 'text',
      required: true,
      fullLabel: 'CMS login info',
    },
    {
      name: 'street',
      label: 'Street',
      type: 'text',
      required: true,
      fullLabel: "Organization's Street Address",
      readOnly: true,
    },
    {
      name: 'city',
      label: 'City',
      type: 'text',
      required: true,
      fullLabel: "Organization's City",
      readOnly: true,
    },
    {
      name: 'state',
      label: 'State',
      type: 'text',
      required: true,
      fullLabel: "Organization's State",
      readOnly: true,
    },
    {
      name: 'zip',
      label: 'Zip',
      type: 'text',
      required: true,
      fullLabel: "Organization's Zip Code",
      readOnly: true,
    },
    {
      name: 'cmsUsed',
      label: 'CMS Used',
      type: 'text',
      required: true,
      fullLabel: "Organization's CMS",
      readOnly: true,
    },
  ],
  SMM: [
    {
      name: 'SMM1',
      label: 'Is their Google Business profile up to date?',
      type: 'text',
      required: false,
      fullLabel: 'Is their Google Business profile up to date?',
    },
  ],
}
export const productFieldsConfig = {
  /////// Search Products //////////////
  'Paid Search Advertising': [
    // { fieldName: 'searchPlatform', label: 'Platform (Select)' }, // SELECT ['Facebook', 'Instagram', 'Facebook/Instagram'] / THIS FEEL IS NEEDED BUT HIDDEN****
    { fieldName: 'landingPageUrl', label: 'Landing Page URL' },
    {
      fieldName: 'marketingObjective',
      label: 'Marketing Objective',
      options: ['Traffic', 'Awareness', 'Branding', 'Leads', 'Engagement'],
      placeholder: `What is the client's overall marketing objective? Do they want Leads, Branding/awareness, In-Store Visits, Website traffic or a mix of these?`,
    },
    // { fieldName: 'searchPlatform', label: 'Platform' }, // SELECT [''] // HIDDEN MANDATORY FIELD
    { fieldName: 'geoLocationType', label: 'Geo Type', options: ['State', 'City', 'Entire US', 'Geo Fencing'] }, // location type, location name // SELECT ['State', 'City', 'Entire US']
    { fieldName: 'geoLocationName', label: 'Geo Location' }, // location type, location name
    { fieldName: 'audience', label: 'Audience(first, third, none)', options: ['First Party', 'Third Party', 'None'] }, // location type, location name // first party, third party, none //SELECT ['First Party', 'Third Party', 'None']
    { fieldName: 'creativeSuppliedBy', label: 'Creative Supplied By', options: ['Client', 'GRO'] }, // location type, location name // first party, third party, none //SELECT ['Client', 'GRO']
    { fieldName: 'copySuppliedBy', label: 'Copy Supplied By', options: ['Client', 'GRO'] }, // location type, location name // first party, third party, none // SELECT
    {
      fieldName: 'searchTargetingSpecifics',
      label: 'Search Targeting Specifics',
      placeholder: 'Campaign breakdown, Keyword specifics',
    },
    // Add other specific fields
  ],
  'Paid Search Management Fee': [
    // { fieldName: 'searchPlatform', label: 'Platform (Select)' }, // SELECT ['Facebook', 'Instagram', 'Facebook/Instagram'] / THIS FEEL IS NEEDED BUT HIDDEN****
    { fieldName: 'landingPageUrl', label: 'Landing Page URL' },
    {
      fieldName: 'marketingObjective',
      label: 'Marketing Objective',
      options: ['Traffic', 'Awareness', 'Branding', 'Leads', 'Engagement'],
    },
    { fieldName: 'searchPlatform', label: 'Platform' }, // SELECT [''] // HIDDEN MANDATORY FIELD
    { fieldName: 'geoLocationType', label: 'Geo Type', options: ['State', 'City', 'Entire US', 'Geo Fencing'] }, // location type, location name // SELECT ['State', 'City', 'Entire US']
    { fieldName: 'geoLocationName', label: 'Geo Location' }, // location type, location name
    { fieldName: 'audience', label: 'Audience(first, third, none)', options: ['First Party', 'Third Party', 'None'] }, // location type, location name // first party, third party, none //SELECT ['First Party', 'Third Party', 'None']
    { fieldName: 'creativeSuppliedBy', label: 'Creative Supplied By', options: ['Client', 'GRO'] }, // location type, location name // first party, third party, none //SELECT ['Client', 'GRO']
    { fieldName: 'copySuppliedBy', label: 'Copy Supplied By', options: ['Client', 'GRO'] }, // location type, location name // first party, third party, none // SELECT
    // Add other specific fields
  ],
  'Paid Search - Media Spend': [
    // { fieldName: 'searchPlatform', label: 'Platform (Select)' }, // SELECT ['Facebook', 'Instagram', 'Facebook/Instagram'] / THIS FEEL IS NEEDED BUT HIDDEN****
    { fieldName: 'landingPageUrl', label: 'Landing Page URL' },
    {
      fieldName: 'marketingObjective',
      label: 'Marketing Objective',
      options: ['Traffic', 'Awareness', 'Branding', 'Leads', 'Engagement'],
    },
    { fieldName: 'searchPlatform', label: 'Platform' }, // SELECT [''] // HIDDEN MANDATORY FIELD
    { fieldName: 'geoLocationType', label: 'Geo Type', options: ['State', 'City', 'Entire US', 'Geo Fencing'] }, // location type, location name // SELECT ['State', 'City', 'Entire US']
    { fieldName: 'geoLocationName', label: 'Geo Location' }, // location type, location name
    { fieldName: 'audience', label: 'Audience(first, third, none)', options: ['First Party', 'Third Party', 'None'] }, // location type, location name // first party, third party, none //SELECT ['First Party', 'Third Party', 'None']
    { fieldName: 'creativeSuppliedBy', label: 'Creative Supplied By', options: ['Client', 'GRO'] }, // location type, location name // first party, third party, none //SELECT ['Client', 'GRO']
    { fieldName: 'copySuppliedBy', label: 'Copy Supplied By', options: ['Client', 'GRO'] }, // location type, location name // first party, third party, none // SELECT
    // Add other specific fields
  ],
  'Microsoft Advertising': [
    // { fieldName: 'searchPlatform', label: 'Platform (Select)' }, // SELECT ['Facebook', 'Instagram', 'Facebook/Instagram'] / THIS FEEL IS NEEDED BUT HIDDEN****
    { fieldName: 'landingPageUrl', label: 'Landing Page URL' },
    {
      fieldName: 'marketingObjective',
      label: 'Marketing Objective',
      options: ['Traffic', 'Awareness', 'Branding', 'Leads', 'Engagement'],
    },
    { fieldName: 'searchPlatform', label: 'Platform' }, // SELECT [''] // HIDDEN MANDATORY FIELD
    { fieldName: 'geoLocationType', label: 'Geo Type', options: ['State', 'City', 'Entire US', 'Geo Fencing'] }, // location type, location name // SELECT ['State', 'City', 'Entire US']
    { fieldName: 'geoLocationName', label: 'Geo Location' }, // location type, location name
    { fieldName: 'audience', label: 'Audience(first, third, none)', options: ['First Party', 'Third Party', 'None'] }, // location type, location name // first party, third party, none //SELECT ['First Party', 'Third Party', 'None']
    { fieldName: 'creativeSuppliedBy', label: 'Creative Supplied By', options: ['Client', 'GRO'] }, // location type, location name // first party, third party, none //SELECT ['Client', 'GRO']
    { fieldName: 'copySuppliedBy', label: 'Copy Supplied By', options: ['Client', 'GRO'] }, // location type, location name // first party, third party, none // SELECT
    // Add other specific fields
  ],
  'Spotify Advertising': [
    // { fieldName: 'searchPlatform', label: 'Platform (Select)' }, // SELECT ['Facebook', 'Instagram', 'Facebook/Instagram'] / THIS FEEL IS NEEDED BUT HIDDEN****
    { fieldName: 'landingPageUrl', label: 'Landing Page URL' },
    {
      fieldName: 'marketingObjective',
      label: 'Marketing Objective',
      options: ['Traffic', 'Awareness', 'Branding', 'Leads', 'Engagement'],
    },
    { fieldName: 'searchPlatform', label: 'Platform' }, // SELECT [''] // HIDDEN MANDATORY FIELD
    { fieldName: 'geoLocationType', label: 'Geo Type', options: ['State', 'City', 'Entire US', 'Geo Fencing'] }, // location type, location name // SELECT ['State', 'City', 'Entire US']
    { fieldName: 'geoLocationName', label: 'Geo Location' }, // location type, location name
    { fieldName: 'audience', label: 'Audience(first, third, none)', options: ['First Party', 'Third Party', 'None'] }, // location type, location name // first party, third party, none //SELECT ['First Party', 'Third Party', 'None']
    { fieldName: 'creativeSuppliedBy', label: 'Creative Supplied By', options: ['Client', 'GRO'] }, // location type, location name // first party, third party, none //SELECT ['Client', 'GRO']
    { fieldName: 'copySuppliedBy', label: 'Copy Supplied By', options: ['Client', 'GRO'] }, // location type, location name // first party, third party, none // SELECT
    // Add other specific fields
  ],
  'Amazon Advertising': [
    // { fieldName: 'searchPlatform', label: 'Platform (Select)' }, // SELECT ['Facebook', 'Instagram', 'Facebook/Instagram'] / THIS FEEL IS NEEDED BUT HIDDEN****
    { fieldName: 'landingPageUrl', label: 'Landing Page URL' },
    {
      fieldName: 'marketingObjective',
      label: 'Marketing Objective',
      options: ['Traffic', 'Awareness', 'Branding', 'Leads', 'Engagement'],
    },
    { fieldName: 'searchPlatform', label: 'Platform' }, // SELECT [''] // HIDDEN MANDATORY FIELD
    { fieldName: 'geoLocationType', label: 'Geo Type', options: ['State', 'City', 'Entire US', 'Geo Fencing'] }, // location type, location name // SELECT ['State', 'City', 'Entire US']
    { fieldName: 'geoLocationName', label: 'Geo Location' }, // location type, location name
    { fieldName: 'audience', label: 'Audience(first, third, none)', options: ['First Party', 'Third Party', 'None'] }, // location type, location name // first party, third party, none //SELECT ['First Party', 'Third Party', 'None']
    { fieldName: 'creativeSuppliedBy', label: 'Creative Supplied By', options: ['Client', 'GRO'] }, // location type, location name // first party, third party, none //SELECT ['Client', 'GRO']
    { fieldName: 'copySuppliedBy', label: 'Copy Supplied By', options: ['Client', 'GRO'] }, // location type, location name // first party, third party, none // SELECT
    // Add other specific fields
  ],
  'Agency Retainer - Digital': [
    // { fieldName: 'searchPlatform', label: 'Platform (Select)' }, // SELECT ['Facebook', 'Instagram', 'Facebook/Instagram'] / THIS FEEL IS NEEDED BUT HIDDEN****
    { fieldName: 'landingPageUrl', label: 'Landing Page URL' },
    {
      fieldName: 'marketingObjective',
      label: 'Marketing Objective',
      options: ['Traffic', 'Awareness', 'Branding', 'Leads', 'Engagement'],
    },
    { fieldName: 'searchPlatform', label: 'Platform' }, // SELECT [''] // HIDDEN MANDATORY FIELD
    { fieldName: 'geoLocationType', label: 'Geo Type', options: ['State', 'City', 'Entire US', 'Geo Fencing'] }, // location type, location name // SELECT ['State', 'City', 'Entire US']
    { fieldName: 'geoLocationName', label: 'Geo Location' }, // location type, location name
    { fieldName: 'audience', label: 'Audience(first, third, none)', options: ['First Party', 'Third Party', 'None'] }, // location type, location name // first party, third party, none //SELECT ['First Party', 'Third Party', 'None']
    { fieldName: 'creativeSuppliedBy', label: 'Creative Supplied By', options: ['Client', 'GRO'] }, // location type, location name // first party, third party, none //SELECT ['Client', 'GRO']
    { fieldName: 'copySuppliedBy', label: 'Copy Supplied By', options: ['Client', 'GRO'] }, // location type, location name // first party, third party, none // SELECT
    // Add other specific fields
  ],
  'Management Fee / Retainer': [
    // { fieldName: 'searchPlatform', label: 'Platform (Select)' }, // SELECT ['Facebook', 'Instagram', 'Facebook/Instagram'] / THIS FEEL IS NEEDED BUT HIDDEN****
    { fieldName: 'landingPageUrl', label: 'Landing Page URL' },
    {
      fieldName: 'marketingObjective',
      label: 'Marketing Objective',
      options: ['Traffic', 'Awareness', 'Branding', 'Leads', 'Engagement'],
    },
    { fieldName: 'geoLocationType', label: 'Geo Type', options: ['State', 'City', 'Entire US', 'Geo Fencing'] }, // location type, location name // SELECT ['State', 'City', 'Entire US']
    { fieldName: 'geoLocationName', label: 'Geo Location' }, // location type, location name
    { fieldName: 'audience', label: 'Audience(first, third, none)', options: ['First Party', 'Third Party', 'None'] }, // location type, location name // first party, third party, none //SELECT ['First Party', 'Third Party', 'None']
    { fieldName: 'creativeSuppliedBy', label: 'Creative Supplied By', options: ['Client', 'GRO'] }, // location type, location name // first party, third party, none //SELECT ['Client', 'GRO']
    { fieldName: 'copySuppliedBy', label: 'Copy Supplied By', options: ['Client', 'GRO'] }, // location type, location name // first party, third party, none // SELECT
    // Add other specific fields
  ],
  'Meta Advertising': [
    { fieldName: 'socialPlatform', label: 'Platform ', options: ['Facebook', 'Instagram', 'Facebook/Instagram'] }, // SELECT ['Facebook', 'Instagram', 'Facebook/Instagram']
    { fieldName: 'landingPageUrl', label: 'Landing Page URL' },
    {
      fieldName: 'marketingObjective',
      label: 'Marketing Objective',
      options: ['Traffic', 'Awareness', 'Branding', 'Leads', 'Engagement'],
    },
    { fieldName: 'geoLocationType', label: 'Geo Type', options: ['State', 'City', 'Entire US', 'Geo Fencing'] }, // location type, location name // SELECT ['State', 'City', 'Entire US']
    { fieldName: 'geoLocationName', label: 'Geo Location' }, // location type, location name
    { fieldName: 'audience', label: 'Audience(first, third, none)', options: ['First Party', 'Third Party', 'None'] }, // location type, location name // first party, third party, none //SELECT ['First Party', 'Third Party', 'None']
    { fieldName: 'creativeSuppliedBy', label: 'Creative Supplied By', options: ['Client', 'GRO'] }, // location type, location name // first party, third party, none //SELECT ['Client', 'GRO']
    { fieldName: 'copySuppliedBy', label: 'Copy Supplied By', options: ['Client', 'GRO'] }, // location type, location name // first party, third party, none // SELECT
    {
      fieldName: 'socialTargetingSpecifics',
      label: 'Social Targeting Specifics',
      placeholder: 'Social Channel / Campaign Breakdowns, Audience targeting specifics',
    },
  ],
  'Simplifi Advertising': [
    { fieldName: 'socialPlatform', label: 'Platform (Select)' }, // SELECT ['Facebook', 'Instagram', 'Facebook/Instagram']
    { fieldName: 'landingPageUrl', label: 'Landing Page URL' },
    { fieldName: 'audienceTargeting', label: 'Audience Targeting' },
  ],
  'Linkedin Advertising': [
    { fieldName: 'socialPlatform', label: 'Platform (Select)' }, // SELECT ['Facebook', 'Instagram', 'Facebook/Instagram']
    { fieldName: 'landingPageUrl', label: 'Landing Page URL' },
    { fieldName: 'audienceTargeting', label: 'Audience Targeting' },
  ],
  'Google Display Advertising': [
    // { fieldName: 'displayPlatform', label: 'Platform ' },
    { fieldName: 'landingPageUrl', label: 'Landing Page URL' },
    {
      fieldName: 'marketingObjective',
      label: 'Marketing Objective',
      options: ['Traffic', 'Awareness', 'Branding', 'Leads', 'Engagement'],
    },
    { fieldName: 'geoLocationType', label: 'Geo Type', options: ['State', 'City', 'Entire US', 'Geo Fencing'] }, // location type, location name // SELECT ['State', 'City', 'Entire US']
    { fieldName: 'geoLocationName', label: 'Geo Location' }, // location type, location name
    { fieldName: 'audience', label: 'Audience(first, third, none)', options: ['First Party', 'Third Party', 'None'] }, // location type, location name // first party, third party, none //SELECT ['First Party', 'Third Party', 'None']
    { fieldName: 'creativeSuppliedBy', label: 'Creative Supplied By', options: ['Client', 'GRO'] }, // location type, location name // first party, third party, none //SELECT ['Client', 'GRO']
    { fieldName: 'copySuppliedBy', label: 'Copy Supplied By', options: ['Client', 'GRO'] }, // location type, location name // first party, third party, none // SELECT
  ],
  'Programmatic Display Advertising': [
    { fieldName: 'displayPlatform', label: 'Platform ', options: ['Google', 'Microsoft', 'Simplifi'] }, // SELECT ['Facebook', 'Instagram', 'Facebook/Instagram']

    { fieldName: 'landingPageUrl', label: 'Landing Page URL' },
    { fieldName: 'geoLocationType', label: 'Geo Type', options: ['State', 'City', 'Entire US', 'Geo Fencing'] }, // location type, location name // SELECT ['State', 'City', 'Entire US']
    { fieldName: 'geoLocationName', label: 'Geo Location' }, // location type, location name
    { fieldName: 'audience', label: 'Audience(first, third, none)', options: ['First Party', 'Third Party', 'None'] }, // location type, location name // first party, third party, none //SELECT ['First Party', 'Third Party', 'None']
    { fieldName: 'creativeSuppliedBy', label: 'Creative Supplied By', options: ['Client', 'GRO'] }, // location type, location name // first party, third party, none //SELECT ['Client', 'GRO']
    { fieldName: 'copySuppliedBy', label: 'Copy Supplied By', options: ['Client', 'GRO'] }, // location type, location name // first party, third party, none // SELECT
  ],
  'SEO - Custom Tier': [
    { fieldName: 'cmsLoginUsername', label: 'CMS Login Username' },
    { fieldName: 'cmsLoginPassword', label: 'CMS Login Password' },
    { fieldName: 'cmsLoginUrl', label: 'CMS Login URL' },
  ],
  'SEO - Tier 1': [
    { fieldName: 'cmsLoginUsername', label: 'CMS Login Username' },
    { fieldName: 'cmsLoginPassword', label: 'CMS Login Password' },
    { fieldName: 'cmsLoginUrl', label: 'CMS Login URL' },
  ],
  'Monthly SEO Plan - Tier 1': [
    { fieldName: 'cmsLoginUsername', label: 'CMS Login Username' },
    { fieldName: 'cmsLoginPassword', label: 'CMS Login Password' },
    { fieldName: 'cmsLoginUrl', label: 'CMS Login URL' },
  ],
  'SEO - Additional Location': [
    { fieldName: 'cmsLoginUsername', label: 'CMS Login Username' },
    { fieldName: 'cmsLoginPassword', label: 'CMS Login Password' },
    { fieldName: 'cmsLoginUrl', label: 'CMS Login URL' },
  ],
  'Listing Sync Pro': [
    { fieldName: 'cmsLoginUsername', label: 'CMS Login Username' },
    { fieldName: 'cmsLoginPassword', label: 'CMS Login Password' },
    { fieldName: 'cmsLoginUrl', label: 'CMS Login URL' },
  ],
  'SEO - Tier 2': [
    { fieldName: 'cmsLoginUsername', label: 'CMS Login Username' },
    { fieldName: 'cmsLoginPassword', label: 'CMS Login Password' },
    { fieldName: 'cmsLoginUrl', label: 'CMS Login URL' },
  ],
  'SEO - Tier 3': [
    { fieldName: 'cmsLoginUsername', label: 'CMS Login Username' },
    { fieldName: 'cmsLoginPassword', label: 'CMS Login Password' },
    { fieldName: 'cmsLoginUrl', label: 'CMS Login URL' }, // creative supplied by, copy supplied by
  ],
  'SEO - Tier 4': [
    { fieldName: 'cmsLoginUsername', label: 'CMS Login Username' },
    { fieldName: 'cmsLoginPassword', label: 'CMS Login Password' },
    { fieldName: 'cmsLoginUrl', label: 'CMS Login URL' },
  ],
  'Website Hosting': [{ fieldName: 'websiteUrl', label: 'Website Url' }],
  'Website Build - Page Creation': [{ fieldName: 'websiteUrl', label: 'Website Url' }],
  'Website Hosting + 30 Minutes of Maintenance': [{ fieldName: 'websiteUrl', label: 'Website Url' }],
  'Website - Add On': [{ fieldName: 'websiteUrl', label: 'Website Url' }],
  'Website Build - Additional Page Creation': [{ fieldName: 'websiteUrl', label: 'Website Url' }],
  'Website Revisions - Additional': [{ fieldName: 'websiteUrl', label: 'Website Url' }],
  'Standard Website Build': [{ fieldName: 'websiteUrl', label: 'Website Url' }],
  'Website Revisions': [{ fieldName: 'websiteUrl', label: 'Website Url' }],
  'Website Hosting': [{ fieldName: 'websiteUrl', label: 'Website Url' }],
  'Premium Website Build': [{ fieldName: 'websiteUrl', label: 'Website Url' }],
  'Monthly Website Plan': [{ fieldName: 'websiteUrl', label: 'Website Url' }],
  'Website Maintenance Retainer': [{ fieldName: 'websiteUrl', label: 'Website Url' }],
  'Website Transfer Fee': [{ fieldName: 'websiteUrl', label: 'Website Url' }],
  'Website Hosting + 60 Minutes of Maintenance': [{ fieldName: 'websiteUrl', label: 'Website Url' }],
  'Agency Retainer - Website': [{ fieldName: 'websiteUrl', label: 'Website Url' }],
  'E-Commerce Website Build': [{ fieldName: 'websiteUrl', label: 'Website Url' }],
  'Monthly Hosting Plan': [{ fieldName: 'websiteUrl', label: 'Website Url' }],
}
