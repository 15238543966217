import { Grid, Stack, useTheme, Box } from '@mui/material'
import { useState, useEffect } from 'react'
import { useCampaign } from '../../api/aws/useCampaign'
import { useParams } from 'react-router-dom'
import { useCreateCampaign } from '../../api/aws/useCreateCampaign'
import { useDealDetailsContext } from '../../context/DealsDetailsProvider/DealsDetailsProvider'
import { useOrganizationById } from '../../api/aws/useOrganization'
import EditCampaign from './EditCampaign'
import ViewMode from './ViewMode'
import RenderCampaignHeader from './RenderCampaignHeader'
import { stackStyles, StickyBox } from './style'
import DetailsLoading from '../Loading/DetailsLoading'
import { useStrategy } from '../../api/aws/useStrategy'
import useSaveCampaign from '../../api/customHooks/useSaveCampaign'
import { useQueryClient } from '@tanstack/react-query'
import { createAdSetObject, createNewCampaignObject } from './createCampaigns'
import StrategyNotesHeader from '../Strategy/StrategyNotesHeader'
import StrategyNotesSection from '../Strategy/StrategyNotesSection'
import { useUpdateItems } from '../../api/aws/useUpdateItems'
import { useActiveUser } from '../../api/slack/useActiveUser'
import { createNoteData } from './handleFunctions'
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'

const RenderCampaign = () => {
  const { dealId, campaignId } = useParams()
  const queryClient = useQueryClient()
  const theme = useTheme()
  const { newDeal, isEditMode, handleStartEdit, handleStopEdit } = useDealDetailsContext()
  const { strategy, isLoading, isError } = useStrategy(dealId)
  const { mutate: saveCampaign } = useSaveCampaign()
  const [service, setService] = useState('')
  const orgId = newDeal?.organizations?.[0] || null
  const { organization } = useOrganizationById(orgId)
  const { campaign, isLoading: campaignLoading, isError: campaignError } = useCampaign(campaignId || null)
  const [campaignState, setCampaignState] = useState({
    ...campaign,
    items: campaign?.items || [],
  })
  const { mutate: createCampaign } = useCreateCampaign()
  //////// Notes /////////
  const { mutate: updateItems } = useUpdateItems()
  const { activeUser } = useActiveUser()
  const { showSnackbar } = useSnackbarContext()
  const [newNoteContent, setNewNoteContent] = useState('')
  const [notes, setNotes] = useState(strategy?.notes || [])
  const [isCreatingNote, setIsCreatingNote] = useState(false)
  const [isCreatingCampaign, setIsCreatingCampaign] = useState(false)

  useEffect(() => {
    if (!campaignLoading && campaign !== undefined && strategy && service && organization) {
      if (campaign.status === 204) {
        console.log('no campaign found, creating new....')
        handleCreateCampaign()
      } else {
        setCampaignState({
          name: `Campaign | ${newDeal?.name}`,
          ...campaign,
          items: campaign.items || [], // Ensure items array exists
        })
      }
    }
  }, [campaign, campaignLoading, strategy, service, organization])

  useEffect(() => {
    if (strategy) {
      setService(strategy?.updatedProducts?.find(product => product.instanceId === campaignId))
    }
  }, [strategy])

  const handleCreateCampaign = () => {
    if (!strategy || !service || !organization) {
      console.warn('Dependencies missing for campaign creation:', { strategy, service, organization })
      return // Exit if dependencies are not yet loaded
    }

    setIsCreatingCampaign(true)
    const campaignData = {
      strategy: strategy,
      product: service,
      organization: organization,
    }

    try {
      createCampaign(campaignData, {
        onSuccess: data => {
          console.log('Successfully created new campaign', data)
          queryClient.invalidateQueries('/aws/campaigns', campaignId)
          setCampaignState({ ...data.campaign })
          setIsCreatingCampaign(false)
        },
        onError: e => {
          console.error('Error creating new campaign', e)
          setIsCreatingCampaign(false)
        },
      })
    } catch (error) {
      console.error('Error creating new campaign', error)
      setIsCreatingCampaign(false)
    }
  }

  useEffect(() => {
    if (campaign) {
      // console.log('campaign', campaign)
      setCampaignState({
        ...campaign,
        items: campaign.items || [], // Ensure items array exists
      })
    }
  }, [campaign])

  const handleChange = (fieldName, newValue) => {
    setCampaignState(prevState => {
      const fieldParts = fieldName.split(/[\[\].]+/).filter(Boolean)
      let updatedState = { ...prevState }

      if (fieldParts.length > 1) {
        let current = updatedState
        for (let i = 0; i < fieldParts.length - 1; i++) {
          const part = fieldParts[i]
          if (!current[part]) current[part] = isNaN(fieldParts[i + 1]) ? {} : [] // Initialize if undefined
          current = current[part]
        }
        current[fieldParts[fieldParts.length - 1]] = newValue
      } else {
        updatedState[fieldName] = newValue
      }

      return updatedState
    })
  }

  const handleSave = () => {
    saveCampaign(
      { campaignId: campaignState.campaign_uuid, campaignData: campaignState },
      {
        onSuccess: () => {
          console.log('Campaign updated successfully!')
          handleStopEdit()
          queryClient.invalidateQueries(['/aws/campaigns', campaignId])
        },
        onError: error => {
          console.error('Failed to update campaign:', error)
          handleStopEdit()
        },
      }
    )
  }

  // Add a new campaign to the items array
  const handleAddCampaign = () => {
    const newCampaignData = createNewCampaignObject(campaign)
    console.log('newCampaignData', newCampaignData)
    setCampaignState(prevState => ({
      ...prevState,
      items: [...prevState.items, newCampaignData], // Add new campaign to items array
    }))
  }
  // Add a new ad set to a social campaign's ads array
  const handleCreateAdSet = itemIndex => {
    const newAdSet = createAdSetObject(campaignState, itemIndex)

    setCampaignState(prevState => {
      const updatedItems = [...prevState.items]

      // Ensure `ads` is initialized as an array if undefined
      if (!updatedItems[itemIndex].ads) {
        updatedItems[itemIndex].ads = []
      }

      updatedItems[itemIndex].ads.push(newAdSet)

      return {
        ...prevState,
        items: updatedItems,
      }
    })
  }
  /////////////////////////////Notes///////////////////////////////////
  ////////////////////////////////////////////////////////////////////
  const handleAddNote = () => {
    if (newNoteContent && newNoteContent !== '<p></p>') {
      setIsCreatingNote(true)
      // Avoid adding empty notes
      const newNote = createNoteData(newNoteContent, activeUser)

      const updatedNotes = [...notes, newNote]
      setNotes(updatedNotes)

      updateItems(
        {
          updates: [
            {
              primaryKey: 'strategy_uuid',
              tableName: 'strategy',
              itemId: newDeal.deal_uuid,
              fieldName: 'notes',
              newValue: updatedNotes,
            },
          ],
        },
        {
          onSuccess: () => {
            showSnackbar('Note added successfully', 'success')
            handleClear()
          },
          onError: err => {
            console.error('Failed to add note:', err)
            showSnackbar('Failed to add note', 'error')
          },
        }
      )
    }
  }
  const handleClear = () => {
    setIsCreatingNote(false)
    setNewNoteContent('') // This clears the note content state
  }
  ////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////

  if (!campaign) {
    return null // Early return if campaign is undefined
  }
  if (isLoading || campaignLoading || isCreatingCampaign || campaign.state === 204) {
    return <DetailsLoading />
  }
  return (
    <Grid container>
      {isCreatingCampaign && <DetailsLoading />}
      <Grid item xs={9} key={`campaigns-${campaign?.key}`} sx={stackStyles}>
        <StickyBox key={campaignId}>
          <RenderCampaignHeader
            key={campaignId}
            service={service}
            handleSave={handleSave}
            onAddCampaign={handleAddCampaign}
          />
        </StickyBox>
        {isEditMode ? (
          <EditCampaign campaignState={campaignState} onChange={handleChange} onCreateAdSet={handleCreateAdSet} />
        ) : (
          <ViewMode campaign={campaignState} />
        )}
      </Grid>
      {/* {[campaign].map(campaign => (
        <Grid item xs={12} key={campaign.id}>
          <StickyBox>
            <RenderCampaignHeader
              service={service}
              handleSave={() => handleSave(campaign.id)}
              onAddCampaign={handleAddCampaign}
            />
          </StickyBox>
          {isEditMode ? (
            <EditCampaign
              campaignState={campaign}
              onChange={newValue => handleChange(campaign.id, newValue)}
              onCreateAdSet={itemIndex => handleCreateAdSet(campaign.id, itemIndex)}
            />
          ) : (
            <ViewMode campaign={campaign} />
          )}
        </Grid>
      ))} */}
      <Grid item xs={3} sx={stackStyles}>
        <Box
          sx={{
            position: 'sticky',
            top: 0,
            zIndex: 10,
            mb: 1,
            bgcolor: theme.palette.mode === 'dark' ? 'rgb(26, 22, 22)' : 'rgb(232, 246, 252)',
          }}
        >
          <StrategyNotesHeader
            newNoteContent={newNoteContent}
            setNewNoteContent={setNewNoteContent}
            handleAddNote={handleAddNote}
            onClear={handleClear}
            isCreatingNote={isCreatingNote}
            setIsCreatingNote={setIsCreatingNote}
          />
        </Box>
        <Box>
          <StrategyNotesSection
            newNoteContent={newNoteContent}
            setNewNoteContent={setNewNoteContent}
            handleAddNote={handleAddNote}
            handleClear={handleClear}
            isCreatingNote={isCreatingNote}
            setIsCreatingNote={setIsCreatingNote}
            isEditMode={isEditMode}
            notes={notes}
            setNotes={setNotes}
          />
        </Box>
      </Grid>
    </Grid>
  )
}

export default RenderCampaign
