import React, { useEffect, useRef, useState } from 'react'
import { Box, Grid, Typography, useTheme } from '@mui/material'
import { useParams } from 'react-router-dom'
import DealInfoCard from './DealInfoCard'
import { useUpdateItem } from '../../api/aws/useUpdateItem'
import RenderContract from './RenderContract'
import { gridStyles } from './style'
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'
import useSaveDeal from '../../api/customHooks/useSaveDeal'
import { useNewContactsById } from '../../api/customHooks/useNewContactsById'
import { useDealDetailsContext } from '../../context/DealsDetailsProvider/DealsDetailsProvider'
import { useUsersById } from '../../api/customHooks/useUsersById'
import { determineApproverIds } from '../../context/DealsDetailsProvider/determineApprovers'
import { useActiveUser } from '../../api/slack/useActiveUser'
import DetailsLoading from '../Loading/DetailsLoading'
import { useApproversById } from '../../api/customHooks/useApproversById'
import RenderApprover from '../Approvals/RenderApprover'
import { useUser } from '../../api/aws/useUser'
import { useCreateTimeline } from '../../api/aws/useCreateTimeline'
import { updateDealTimelineData } from '../Timeline/createTimelineData'
import { calculateProgress } from '../Approvals/calculateProgress'
import { useQueryClient } from '@tanstack/react-query'
import { useNotify } from '../../context/NotificationProvider/NotificationProvider'
import DealStatusModal from './DealStatusModal'

const DealsDetails = () => {
  const { dealId } = useParams()
  const queryClient = useQueryClient()
  const theme = useTheme()
  const {
    newDeal,
    setNewDeal,
    resetState,
    productRows,
    strategyState,
    handleRequestApproval,
    handleApproveDeal,
    timelineData = {},
    isDealStatusChange,
    setIsDealStatusChange,
  } = useDealDetailsContext()
  const { addNotification, createMessagePayload } = useNotify()
  const approverIds = newDeal ? determineApproverIds(newDeal) : []
  const { approversById, isApproversByIdLoading, isApproversByIdError } = useApproversById(
    approverIds ? approverIds : []
  )
  const {
    activeUser: { user: activeUser },
  } = useActiveUser()

  const [totalGrossProfit, setTotalGrossProfit] = useState()
  const { user: requestor, isLoading: isRequestorLoading, isError: isRequestorError } = useUser(activeUser.id)
  const { mutate: createTimelineEntry } = useCreateTimeline()
  const [isError, setIsError] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const [isApproving, setIsApproving] = useState(false)
  const gridBgColor = theme.palette.mode === 'dark' ? '#1e2735' : 'rgba(255,255,255)'
  const { mutate: saveDeal } = useSaveDeal()
  const { showSnackbar } = useSnackbarContext()
  const { mutate: updateItem } = useUpdateItem()
  // Retrieve contacts associated with the deal
  const newContactIds = newDeal && newDeal.contacts && Array.isArray(newDeal.contacts) ? newDeal.contacts : []
  const newContacts = useNewContactsById(newContactIds)
  const scrollRef = useRef(null)
  /////////// Churned Status State///////////////////
  const [showModal, setShowModal] = useState(false) // To control the modal visibility
  const [tempDealStatus, setTempDealStatus] = useState('') // To temporarily hold the new deal status
  // const [lastDateOfService, setLastDateOfService] = useState('') // Modal input
  // const [churnReason, setChurnReason] = useState('') // Modal input

  const handleUpdateModal = (churnNote, lastDate, reasonChurn) => {
    console.log('Last Date of Service:', lastDate)
    console.log('Churned Reason:', reasonChurn)
    console.log('Churned Notes:', churnNote)
    if (!reasonChurn || !lastDate || !churnNote) {
      showSnackbar('All fields are required.', 'error')
      return
    }
    // const formattedLastDate = lastDate.format('dddd, MMMM D, YYYY') // e.g., "Tuesday, December 31, 2024"
    // console.log('formattedLastDate of Service:', formattedLastDate)

    // Update deal data with formattedLastDate and newReason
    setNewDeal(prev => ({
      ...prev,
      lastDateOfService: lastDate,
      reasonChurn: reasonChurn,
      churnNote: churnNote,
      dealStatus: tempDealStatus,
    }))

    setShowModal(false)
  }
  useEffect(() => {
    if (newDeal) {
      console.log('newDeal', newDeal)
    }
  }, [newDeal])
  //////////////////////////////////////////////////
  // Function to update deal data
  // const handleUpdate = (fieldName, newValue) => {
  //   console.log('fieldName, newValue', fieldName, newValue)
  //   if (newDeal[fieldName] !== newValue) {
  //     updateItem(
  //       {
  //         primaryKey: 'deal_uuid',
  //         tableName: 'deals',
  //         itemId: dealId,
  //         fieldName,
  //         newValue,
  //       },
  //       {
  //         onSuccess: () => {
  //           console.log('Update successful')
  //           showSnackbar('Successfully Updated', 'success')
  //         },
  //         onError: err => {
  //           console.error('Update failed', err)
  //           showSnackbar('Update Failed', 'error')
  //         },
  //       }
  //     )
  //   }
  // }
  // Save updated deal

  const handleSave = (deal, organization) => {
    console.log('Product rows before saving:', productRows)
    console.log('Strategy state before saving:', strategyState)

    const { progressPercentage, individualProgress } = calculateProgress(deal.approvers)
    console.log('has status changed????', isDealStatusChange)
    const dealData = {
      ...deal,
      status: !deal.hasProducts ? 'Draft' : deal.hasProducts && deal.status === 'Draft' ? 'Saved' : deal.status,
      stage: !deal.hasProducts ? 'Discovery' : deal.hasProducts && deal.stage === 'Discovery' ? 'Proposal' : deal.stage,
      products: productRows, // Make sure this reflects the correct state
      // lastDateOfService: deal.lastDateOfService || lastDateOfService,
      // churnReason: deal.churnReason || churnReason,
      lastModified: new Date(),
      progress: individualProgress,
      progressPercentage,
    }
    console.log('dealData', dealData)
    if (isDealStatusChange) {
      try {
        const payload = createMessagePayload(
          dealData?.owner,
          'status',
          'success',
          'Deal status changed',
          `${dealData.name} changed to ${dealData?.dealStatus}`,
          'deals',
          dealData?.name,
          dealData?.deal_uuid,
          dealData?.image_url,
          `https://grobotic.app/deals/${dealData?.deal_uuid}`
        )
        console.log('DEAL STATUS CHANGED: ', payload)
        addNotification(payload)
      } catch (error) {
        console.error('error creating notification for status', error)
      }
    }
    saveDeal(
      {
        dealId: deal.deal_uuid,
        dealData: dealData,
      },
      {
        onSuccess: () => {
          setIsEditMode(false)
          setIsOpen(false)
          if (timelineData) {
            createTimelineEntry(timelineData)
          }
          setNewDeal({ ...dealData })
          setIsDealStatusChange(false)
          setShowModal(false)
        },
        onError: () => {
          setIsDealStatusChange(false)
          setIsEditMode(false)
          setIsOpen(false)
          setShowModal(false)
          setIsError(true)
        },
      }
    )
  }
  const handleCancelModal = () => {
    setShowModal(false)
    setTempDealStatus('')
    setNewDeal(prev => ({
      ...prev,
      dealStatus: prev.dealStatus, // Revert to old status
    }))
  }
  const handleApprovalRequest = () => {
    console.log('Approval Requested!!!!!!')
    try {
      const dealData = {
        ...newDeal,
        isRequested: true,
        typeChange: false,
        requestedBy: activeUser && activeUser.user ? activeUser.user.id : activeUser.id,
        approvers: approversById,
        status: 'Approval Requested',
        requestedTime: new Date(),
        requestor: requestor
          ? {
              id: requestor.id,
              name: requestor.name,
              email: requestor.email,
              image: requestor.image_512,
              hasRequested: true,
            }
          : {},
      }
      const timelineData = updateDealTimelineData(
        newDeal?.deal_uuid,
        activeUser,
        'Request',
        'Approval',
        `Approval requested`
      )
      handleRequestApproval(requestor, activeUser, approversById)
      createTimelineEntry(timelineData)
      setIsOpen(false)
      setNewDeal({ ...dealData })
    } catch (e) {
      console.error(e)
    }
  }

  const handleEdit = () => {
    setIsEditMode(true)
    setIsOpen(true)
  }

  const handleCloseEditMode = () => {
    setIsEditMode(false)
    setIsOpen(false)
  }

  const isLoading = isApproversByIdLoading || isRequestorLoading
  return (
    <Grid container spacing={0} justifyContent='center' sx={gridStyles(theme)}>
      {!isEditMode ? (
        <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }} gap={2}>
          <Grid item xs={12} sm={12} md={9} lg={9}>
            <DealInfoCard key={dealId} deal={newDeal} onEdit={handleEdit} newContacts={newContacts} />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <Grid container spacing={2} sx={{ overflowY: 'auto', maxHeight: 'calc(100vh - 100px)' }}>
              <Grid item xs={12}>
                {isApproversByIdLoading ? (
                  <DetailsLoading />
                ) : (
                  <Box
                    sx={{
                      padding: 2,
                      backgroundColor: gridBgColor,
                      height: 250,
                      borderRadius: '25px',
                      width: '100%',
                    }}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    padding: 2,
                    backgroundColor: gridBgColor,
                    height: 130,
                    borderRadius: '25px',
                    width: '100%',
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Grid item xs={12} md={12}>
          <Box
            sx={{
              width: '100%',
              maxHeight: 'calc(100vh - 145px)',
              overflowY: 'auto',
              backgroundColor: gridBgColor,
              borderRadius: '25px',
            }}
            ref={scrollRef}
          >
            <RenderContract
              dealId={dealId}
              onSaveDeal={handleSave}
              onClose={handleCloseEditMode}
              open={isOpen}
              // onUpdate={handleUpdate}
              onRequestApproval={handleApprovalRequest}
              isEditMode={isEditMode}
              totalGrossProfit={totalGrossProfit}
              setTotalGrossProfit={setTotalGrossProfit}
              scrollRef={scrollRef}
              onSaveModal={handleUpdateModal}
              setShowModal={setShowModal}
              setTempDealStatus={setTempDealStatus}
            />
          </Box>
        </Grid>
      )}
      <DealStatusModal
        isOpen={showModal}
        onClose={handleCancelModal}
        // onSave={(date, reason) => {
        //   handleUpdateModal(reason, date) // Update state
        //   setShowModal(false) // Close modal
        //   setTempDealStatus('') // Reset temp state
        // }}
        onSave={handleUpdateModal}
      />
    </Grid>
  )
}

export default DealsDetails
