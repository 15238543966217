import React, { useState } from 'react'
import { Tooltip, Box, IconButton, Typography, useTheme } from '@mui/material'
import DescriptionIcon from '@mui/icons-material/Description'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'

const AccountingRichTextTooltip = ({ open, onToggle, content, onCopy, isCopied }) => {
  const theme = useTheme()
  return (
    <Tooltip
      open={open}
      title={
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            color: theme.palette.text.primary,
            fontSize: '0.875rem',
            lineHeight: '1.4em',
            gap: 1,
            userSelect: 'text',
          }}
        >
          {/* Render rich text content */}
          <Box
            dangerouslySetInnerHTML={{ __html: content }}
            sx={{ userSelect: 'text' }} // Allow text highlighting
          />
          {/* Add a copy button */}
          <IconButton
            onClick={() => onCopy(content)}
            sx={{
              alignSelf: 'flex-end',
              padding: '4px',
              color: theme.palette.text.secondary,
            }}
            size='small'
          >
            {isCopied ? (
              <Typography>{'Description copied to clipboard!'}</Typography>
            ) : (
              <ContentCopyIcon fontSize='small' />
            )}
          </IconButton>
        </Box>
      }
      arrow
      interactive={'true'}
      placement='right'
      slotProps={{
        tooltip: {
          sx: {
            maxWidth: '600px',
            backgroundColor: theme.palette.background.tooltip,
            color: theme.palette.text.primary,
            padding: '16px',
            borderRadius: '8px',
            textAlign: 'left',
            userSelect: 'text', // Enable text highlighting
          },
        },
        arrow: {
          sx: {
            color: theme.palette.background.tooltip,
          },
        },
      }}
    >
      <IconButton
        onClick={onToggle}
        sx={{ bgcolor: open ? theme.palette.background.selected : null }}
        disabled={!content}
      >
        <DescriptionIcon fontSize='small' sx={{ cursor: 'pointer' }} />
      </IconButton>
    </Tooltip>
  )
}

export default AccountingRichTextTooltip
