import React, { useState } from 'react'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  CircularProgress,
  IconButton,
  Badge,
  useTheme,
} from '@mui/material'
import { fetchViewLink } from '../../api/customHooks/fetchViewLink'
import PandaDocLogo from '../../images/pandadoc.png'
import FileOpenIcon from '@mui/icons-material/FileOpen'
import SearchIcon from '@mui/icons-material/Search'
import PandaDocLinkIcon from '../Icons/PandaDockLinkIcon'
import PageviewIcon from '@mui/icons-material/Pageview'
import FindInPageIcon from '@mui/icons-material/FindInPage'

const PandaDocViewer = ({ contractId, height, width, disabled }) => {
  const [open, setOpen] = useState(false)
  const [blobUrl, setBlobUrl] = useState(null)
  const [loading, setLoading] = useState(false)
  const theme = useTheme()
  const handleClickOpen = async () => {
    setLoading(true)
    const url = await fetchViewLink(contractId)
    setBlobUrl(url)
    setLoading(false)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setBlobUrl(null) // Clean up the Blob URL when closing
  }

  return (
    <>
      <IconButton color='inherit' onClick={handleClickOpen} sx={{ ml: 2 }} disabled={disabled}>
        {/* <Badge
          badgeContent={
            <SearchIcon />
            // <img style={{ width: 20, borderRadius: 25 }} src={} />
          }
        >
          <FileOpenIcon sx={{ width: width ?? 40, height: height ?? 40 }} />
          <PandaDocLinkIcon />
          <img style={{ width: size ? size : 40, borderRadius: 25 }} src={PandaDocLogo} />
        </Badge> */}
        <FindInPageIcon
          sx={{ color: disabled ? theme.palette.text.secondary : 'rgb(37, 133, 103)', height: 30, width: 30 }}
        />
      </IconButton>
      <Dialog open={open} onClose={handleClose} maxWidth='lg' fullWidth>
        <DialogTitle>Document Preview</DialogTitle>
        <DialogContent>
          {loading ? (
            <CircularProgress />
          ) : blobUrl ? (
            <iframe src={blobUrl} width='100%' height='800px' title='PandaDoc Document' style={{ border: 'none' }} />
          ) : (
            <p>Error loading document.</p>
          )}
        </DialogContent>
      </Dialog>
    </>
  )
}

export default PandaDocViewer
