import React, { useState, useEffect, useMemo } from 'react'
import { Box, Typography } from '@mui/material'
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro'
import CustomDealsGridToolbar from '../Deals/CustomDealsGridToolbar'
import { useTheme } from '@mui/material'
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'
import { useDeleteMutation } from '../../api/aws/useDeleteMutation'
import EditContractDialog from '../EditContractDialog/EditContractDialog'
import { filterPanelProps, gridContainerStyles } from './style'
import { DealsGridFooter } from '../Deals/DealsGridFooter'
import { useOrganizations } from '../../api/aws/useOrganizations'
import { useUsers } from '../../api/aws/useUsers'
import { useDeals } from '../../api/aws/useDeals'
import { DealDetailsProvider, useDealDetailsContext } from '../../context/DealsDetailsProvider/DealsDetailsProvider'
import renderNcoColumns from '../DataGrid/createColumns/renderNcoColumns'
import NcoDrawer from './NcoDrawer'
import { useUpdateDeal } from '../../api/aws/useUpdateDeal'
import NcoGridToolbar from './NcoGridToolbar'
import dayjs from 'dayjs'

export const initialSortModel = [
  { field: 'ncoStatus', sort: 'desc' },
  { field: 'organizations', sort: 'asc' },
]

const initialVisibilityState = {
  implementationFee: false,
  total: false,
  status: false,
  id: false,
  strategy: false,
  vertical: false,
  stage: false,
  contractId: false,
  type: false,
  startDate: false,
  endDate: false,
}
const NCOPage = () => {
  const { deals: rawDeals, isDealsLoading, isDealsError } = useDeals()
  const { resetState, ncoDrawerOpen, handleNcoDrawerOpen, handleNcoDrawerClose } = useDealDetailsContext()
  const [selectedDeal, setSelectedDeal] = useState(null)
  const [selectionModel, setSelectionModel] = useState([])
  const [density, setDensity] = useState('standard')
  const [selectedOrganization, setSelectedOrganization] = useState()
  const { mutate: updateDeal } = useUpdateDeal()
  const { organizations, isOrganizationsLoading, isOrganizationsError } = useOrganizations()
  const { users, isUsersLoading, isUsersError } = useUsers()
  const { showSnackbar } = useSnackbarContext()
  const theme = useTheme()
  const filterMenuBorder =
    theme.palette.mode === 'dark' ? '1px solid rgba(255,255,255,0.5)' : '1px solid rgba(0,0,0,0.5)'
  const [isDeleting, setIsDeleting] = useState(false)
  const [filterModel, setFilterModel] = useState(() => {
    const savedFilters = localStorage.getItem('ncoFilterModel')
    return savedFilters ? JSON.parse(savedFilters) : { items: [], quickFilterValues: [] }
  })
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(() => {
    const savedVisibility = localStorage.getItem('ncoColumnVisibilityModel')
    return savedVisibility ? JSON.parse(savedVisibility) : { ...initialVisibilityState }
  })
  const [sortModel, setSortModel] = useState(() => {
    const savedSortModel = localStorage.getItem('ncoSortModel')
    return savedSortModel ? JSON.parse(savedSortModel) : initialSortModel
  })
  const [isContractDialogOpen, setIsContractDialogOpen] = useState(false)
  const { mutate: deleteItem, isLoading } = useDeleteMutation()
  const [massUpdateData, setMassUpdateData] = useState()
  const [anchorEl, setAnchorEl] = useState(null)
  const [deals, setDeals] = useState([])
  // const columns = renderDealsColumns(organizations, users, deals, density)
  const apiRef = useGridApiRef()
  const [visibleRowCount, setVisibleRowCount] = useState(deals ? deals.length : 0)
  const [rowCount, setRowCount] = useState(deals ? deals.length : 0)

  useEffect(() => {
    if (filterModel) {
      // console.log('setting filter model.....')
      // Save the filter model to localStorage when it changes
      localStorage.setItem('ncoFilterModel', JSON.stringify(filterModel))
    }
  }, [filterModel])
  const handleDeleteClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleDealClick = deal => {
    setSelectedDeal(deal)
    handleNcoDrawerOpen()
  }
  useEffect(() => {
    if (rawDeals && organizations) {
      const ncoStatusList = ['Onboarding', 'Awaiting Dependencies', 'Dependencies Received', 'Handoff Call', 'Launched']
      const processedDeals = rawDeals
        .map(deal => {
          const organizationId = Array.isArray(deal.organizations) ? deal.organizations[0] : null
          const organization = organizations.find(org => org.id === organizationId)
          const contractedById =
            organization && Array.isArray(organization.contractedBy) ? organization.contractedBy[0] : null
          return { ...deal, contractedBy: [contractedById] }
        })
        .filter(deal => ncoStatusList.includes(deal.ncoStatus) || (deal.status === 'Completed' && deal.type === 'MSA'))
      setDeals(processedDeals) // Update state with processed deals
    } else {
      setDeals([]) // Ensure deals is always set
    }
  }, [rawDeals, organizations])

  const columns = useMemo(() => {
    return renderNcoColumns(organizations, users, deals, density, handleDealClick, theme)
  }, [organizations, users, deals, density])
  useEffect(() => {
    if (selectedDeal) {
      const selectedOrgId =
        selectedDeal.organizations && Array.isArray(selectedDeal.organizations) ? selectedDeal.organizations[0] : []
      setSelectedOrganization(organizations.find(org => org.id === selectedOrgId))
    }
  }, [selectedDeal, setSelectedOrganization])

  // Cleanup function for when deals component unmounts
  useEffect(() => {
    // console.log('cleanup function...')
    return () => {
      setSelectionModel([])
      setSelectedDeal(null)
    }
  }, [])

  const handleConfirmDelete = async () => {
    setIsDeleting(true)
    if (selectedDeal) {
      const params = {
        endpoint: `/aws/delete/deals/${selectedDeal.id}`,
        table: 'deals',
      }
      deleteItem(params, {
        onSuccess: message => {
          setSelectedDeal(null)
          showSnackbar(message, 'success')
          setIsDeleting(false)
        },
        onError: error => {
          showSnackbar(error.message, 'error')
          setIsDeleting(false)
        },
      })
    }
  }

  const handleClosePopover = () => {
    setAnchorEl(null)
    if (selectionModel.length > 0) {
      selectionModel.forEach(id => {
        apiRef.current.selectRow(id, false)
      })
      setSelectionModel([])
      setSelectedDeal(null)
    }
  }

  const handleCloseSnackbar = () => {
    if (selectionModel.length > 0) {
      selectionModel.forEach(id => {
        apiRef.current.selectRow(id, false)
      })
      setSelectionModel([])
      setSelectedDeal(null)
      handleClosePopover()
    }
  }

  const handleCloneDeal = () => {
    console.log('This icon will be used to clone the selected deal.')
  }

  const handleRowSelected = deal => {
    setSelectedDeal(deal)
  }

  // const handleEditClick = () => {
  //   if (selectedDeal) {
  //     setIsContractDialogOpen(true)
  //   }
  // }

  const handleCloseContractDialog = () => {
    setIsContractDialogOpen(false)
    setSelectedDeal(null)
    handleClosePopover()
  }

  const handleContractDialogAction = () => {
    setIsContractDialogOpen(false)
    handleCloseSnackbar()
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const handleSelectionModelChange = newSelectionModel => {
    if (newSelectionModel.length !== selectionModel.length) {
      setSelectionModel(newSelectionModel)
      if (newSelectionModel.length === 0 && handleRowSelected) {
        handleRowSelected(null)
      } else {
        const selectedRowData = deals.find(row => row.id === newSelectionModel[0])
        if (handleRowSelected) {
          handleRowSelected(selectedRowData)
        }
      }
    }
  }

  const handleMassUpdateChange = e => {
    const { name, value } = e.target
    setMassUpdateData(prev => ({ ...prev, [name]: value }))
  }

  const handleColumnResizeStop = () => {
    if (apiRef.current) {
      apiRef.current.updateColumns()
    }
  }

  useEffect(() => {
    const api = apiRef.current

    const handleFilterModelChange = () => {
      if (api?.state?.filter?.filteredRowsLookup) {
        const filteredRowsCount = Object.values(api.state.filter.filteredRowsLookup).filter(isVisible => isVisible)
          .length
        setVisibleRowCount(filteredRowsCount)
      }
    }

    // Subscribe to the filterModelChange event
    const unsubscribe = api?.subscribeEvent('filterModelChange', handleFilterModelChange)

    // Initialize the visible row count when the component mounts
    handleFilterModelChange()

    // Cleanup function to unsubscribe
    return () => {
      if (unsubscribe) unsubscribe()
    }
  }, [apiRef, deals])

  const totalRowCount = deals ? deals.length : 0
  useEffect(() => {
    if (massUpdateData) {
      console.log('Mass Update Data: ', massUpdateData)
    }
  }, [massUpdateData])
  const handleDensityChange = newDensity => {
    setDensity(newDensity)
  }

  ////////////////// handleUpdate ////////////////////////
  const handleUpdate = async (dealId, updatedRow, updatedData) => {
    console.log('updatedData: ', updatedData)
    console.log('updatedRow', updatedRow)
    try {
      // Call the API mutation with the deal ID and updated fields
      updateDeal(
        { dealId, dealData: updatedData },
        {
          onSuccess: () => {
            showSnackbar(
              <Box sx={{ display: 'flex', alignItems: 'center' }} gap={1}>
                <Typography sx={{ color: theme.palette.text.primary, fontWeight: 'bold' }}>
                  {updatedRow?.name || ''}
                </Typography>
                <Typography>Updated successfully</Typography>
              </Box>,
              'success'
            )
          },
          onError: error => {
            showSnackbar(`Failed to update deal ${dealId}: ${error.message}`, 'error')
          },
        }
      )
    } catch (error) {
      console.error(`Failed to update deal ${dealId}:`, error)
    }
  }

  // const handleClearSorting = e => {
  //   e.preventDefault()
  //   const testSort = [...initialSortModel]
  //   console.log('settingSort model: ', testSort)
  //   setSortModel([...initialSortModel]) // Reset the sort model
  //   localStorage.setItem('ncoSortModel', JSON.stringify(initialSortModel)) // Also update localStorage
  // }
  const handleClearSorting = e => {
    e.preventDefault()
    setSortModel(initialSortModel) // Update state first
    localStorage.setItem('ncoSortModel', JSON.stringify(initialSortModel))
    if (apiRef.current) {
      apiRef.current.setSortModel(initialSortModel) // Use API to sync
    }
  }
  const handleClearVisibility = e => {
    e.preventDefault()
    setColumnVisibilityModel(initialVisibilityState)
    localStorage.setItem('ncoColumnVisibilityModel', JSON.stringify(initialVisibilityState))
  }

  return (
    <>
      <Box sx={{ ...gridContainerStyles, height: 'calc(100vh - 20px)' }}>
        <DataGridPro
          initialState={{
            sorting: {
              sortModel: [
                { field: 'lastModified', sort: 'desc' },
                { field: 'name', sort: 'asc' },
                { field: 'organizations', sort: 'asc' },
                { field: 'status', sort: 'asc' },
              ],
            },
            filter: {
              filterModel: filterModel,
            },
            columns: {
              columnVisibilityModel: initialVisibilityState,
            },
          }}
          rows={deals}
          columns={columns}
          // processRowUpdate={async (updatedRow, oldRow) => {
          //   const updatedFields = {}

          //   Object.keys(updatedRow).forEach(key => {
          //     if (updatedRow[key] !== oldRow[key]) {
          //       updatedFields[key] = updatedRow[key]
          //     }
          //   })

          //   if (Object.keys(updatedFields).length > 0) {
          //     await handleUpdate(updatedRow.id, updatedRow, updatedFields)
          //   }

          //   return { ...oldRow, ...updatedFields } // Return the updated row directly
          // }}
          // onProcessRowUpdateError={error => {
          //   console.error('Error updating row:', error)
          // }}
          processRowUpdate={async (updatedRow, oldRow) => {
            const updatedFields = { ...updatedRow }

            if (
              updatedRow.slaDays !== oldRow.slaDays ||
              updatedRow.dependenciesDueDate !== oldRow.dependenciesDueDate
            ) {
              const dependenciesDueDate = updatedRow.dependenciesDueDate ? dayjs(updatedRow.dependenciesDueDate) : null
              const slaDays = updatedRow.slaDays || 0

              if (dependenciesDueDate) {
                updatedFields.estLaunchDate = dependenciesDueDate.add(slaDays, 'day').toISOString() // Store ISO string in the backend
              } else {
                updatedFields.estLaunchDate = null
              }
            }

            await handleUpdate(updatedRow.id, updatedRow, updatedFields) // Persist changes
            return updatedFields // Return updated row for UI
          }}
          onProcessRowUpdateError={error => {
            console.error('Error updating row:', error)
          }}
          rowHeight={density === 'compact' ? 40 : density === 'comfortable' ? 60 : 52}
          getRowId={row => row.deal_uuid || row.id || row.deal.id}
          checkboxSelection
          disableRowSelectionOnClick
          selectionModel={selectionModel}
          onRowSelectionModelChange={newSelectionModel => setSelectionModel(newSelectionModel)}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={newModel => {
            setColumnVisibilityModel(newModel)
            localStorage.setItem('ncoColumnVisibilityModel', JSON.stringify(newModel))
          }}
          sortModel={sortModel}
          onSortModelChange={newModel => {
            setSortModel(newModel)
            localStorage.setItem('ncoSortModel', JSON.stringify(newModel))
          }}
          onRowCountChange={count => setRowCount(count)}
          filterModel={filterModel}
          onFilterModelChange={model => setFilterModel(model)}
          apiRef={apiRef}
          slots={{
            toolbar: NcoGridToolbar,
            footer: DealsGridFooter,
          }}
          slotProps={{
            toolbar: {
              filterModel: filterModel,
              visibilityModel: columnVisibilityModel,
              sortModel: sortModel,
              setFilterModel: setFilterModel,
              onClearSorting: handleClearSorting,
              onClearVisibility: handleClearVisibility,
              context: 'deals',
            },
            footer: {
              selectionModel: selectionModel,
              totalRowCount: totalRowCount,
              visibleRowCount: visibleRowCount,
              rowCount: rowCount,
              massUpdateData: massUpdateData,
              deals: deals,
              anchorEl: anchorEl,
              handleDeleteClick: handleDeleteClick,
              handleConfirmDelete: handleConfirmDelete,
              handleClosePopover: handleClosePopover,
              // handleEditClick: handleEditClick,
              handleClone: handleCloneDeal,
              selectedData: selectedDeal,
            },
            panel: {
              sx: {
                border: filterMenuBorder,
                borderRadius: '4px',
              },
            },
            filterPanel: {
              sx: {
                ...filterPanelProps,
              },
            },
          }}
          onColumnResizeStop={handleColumnResizeStop}
          disableExtendRowFullWidth
          onDensityChange={handleDensityChange}
          loading={isDealsLoading || isOrganizationsLoading}
        />
      </Box>

      <DealDetailsProvider dealData={selectedDeal}>
        <NcoDrawer open={ncoDrawerOpen} onClose={handleNcoDrawerClose} />
      </DealDetailsProvider>
    </>
  )
}

export default NCOPage
