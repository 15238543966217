import React, { useEffect, useState, useRef } from 'react'
import {
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarColumnsButton,
  GridFilterOperator,
  GridToolbarQuickFilter,
  useGridApiRef,
  getGridStringOperators,
  getGridNumericOperators,
  getGridBooleanOperators,
  getGridDateOperators,
  getGridSingleSelectOperators,
  gridFocusStateSelector,
} from '@mui/x-data-grid-pro'
import { Badge, Box, Button, IconButton, Tooltip, Typography, useTheme } from '@mui/material'
import { gridToolbarContainerStyles, StyledGridToolbarQuickFilter } from './style'
import CustomStatusFilter from '../DataGrid/createColumns/CustomStatusFilter'
import { useActiveUser } from '../../api/slack/useActiveUser'
import { statusFilters, contractStatusFilters, dealStageFilters } from '../DataGrid/filterArrays'
import CustomStageFilter from '../DataGrid/createColumns/CustomStageFilter'
import ViewKanbanIcon from '@mui/icons-material/ViewKanban'
import AppsIcon from '@mui/icons-material/Apps'
import { useNavigate } from 'react-router-dom'
import ResetFiltersButton from '../ui/reset-filters-button'
import { initialSortModel } from '../NCO/NCOPage'
import ResetSortingButton from '../ui/reset-sorting-button'
import ResetColumnsButton from '../ui/reset-columns-button'

const slotProps = theme => ({
  button: {
    sx: {
      borderRadius: 2,
      maxHeight: '100%',
      mt: 2,
      mb: 2,
      '&:hover': { backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary.main : 'rgba(0,0,0,0.1)' },
      color: theme.palette.text.primary,
      '& .MuiBadge-badge': {
        backgroundColor: 'rgb(251, 193, 30)', // Your custom background color
        color: theme.palette.mode === 'dark' ? 'rgba(0,0,0,1)' : 'rgba(0,0,0,1)', // Change the text color if needed
        fontSize: '14px', // Optional: adjust the font size
        width: '20px', // Adjust the width/height to control the size of the badge
        height: '20px',
      },
    },
  },
})
const initialVisibilityState = {
  implementationFee: false,
  total: false,
}
const isSortModelEqual = (sortModel1, sortModel2) => {
  if (sortModel1.length !== sortModel2.length) return false
  return sortModel1.every((sortItem, index) => {
    const initialSortItem = sortModel2[index]
    return sortItem.field === initialSortItem.field && sortItem.sort === initialSortItem.sort
  })
}
const CustomDealsGridToolbar = ({
  filterModel,
  visibilityModel,
  sortModel,
  setFilterModel,
  onClearSorting,
  onClearVisibility,
  context,
}) => {
  const theme = useTheme()
  const navigate = useNavigate()

  const textColor = theme.palette.mode === 'dark' ? 'rgba(255,255,255,1)' : 'rgba(0,0,0,1)'
  const gridFilterBgColor = theme.palette.mode === 'dark' ? theme.palette.primary.main : 'rgba(0,0,0,0.1)'
  const slotPropsColor = theme.palette.text.primary
  const quickFilterRef = useRef(null) // Create a ref for the quick filter
  const [filterStatus, setFilterStatus] = useState('')
  const [filterStage, setFilterStage] = useState('')
  const { activeUser, isActiveUserLoading, isActiveUserError } = useActiveUser()
  const activeUserName =
    activeUser && activeUser.user && activeUser.user.profile ? activeUser.user.profile.real_name : null
  /////////////////////// KanBan navigation ///////////////////////////////
  const handleNavigateToKanban = () => {
    navigate('/deals/kanban')
  }
  const isSortModelChanged = !isSortModelEqual(sortModel, initialSortModel)
  ///////////////////////////////////////////////////////////////////////////\
  // Utility function to check if two sort models are deeply equal

  // Handle keydown event to focus the quick filter on Ctrl+Shift+F or Cmd+Shift+F
  useEffect(() => {
    const handleKeyDown = event => {
      if ((event.ctrlKey || event.metaKey) && event.key === 'f') {
        event.preventDefault()
        if (quickFilterRef.current) {
          quickFilterRef.current.focus() // Focus the quick filter input
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [])
  const handleFilterMyData = () => {
    setFilterModel({
      ...filterModel,
      quickFilterValues: [activeUserName],
    })
  }

  const handleSetFilter = (fieldName, newValue) => {
    const updatedFilters = filterModel.items.filter(item => item.field !== fieldName)

    updatedFilters.push({
      field: fieldName,
      operator: 'contains',
      value: newValue,
    })

    setFilterModel({
      ...filterModel,
      items: updatedFilters,
    })
  }

  const handleClearFilters = e => {
    e.preventDefault()
    setFilterModel({ items: [], quickFilterValues: [] })
    setFilterStatus('')
    setFilterStage('')
  }

  return (
    <GridToolbarContainer sx={gridToolbarContainerStyles}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignContent: 'center',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, ml: 2 }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              alignItems: 'center',
            }}
          >
            {/* <Typography sx={{ color: textColor }}>{'Kanban View'}</Typography> */}
            <Tooltip title='View KanBan'>
              <IconButton onClick={handleNavigateToKanban}>
                <ViewKanbanIcon sx={{ height: 30, width: 30, color: 'rgb(251, 193, 30)' }} />
              </IconButton>
            </Tooltip>
          </Box>
          <GridToolbarDensitySelector slotProps={slotProps(theme)} />
          <GridToolbarColumnsButton slotProps={slotProps(theme)} />
          <GridToolbarFilterButton slotProps={slotProps(theme)} />
          <GridToolbarExport slotProps={slotProps(theme)} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <>
            <CustomStatusFilter
              context={'status'}
              options={contractStatusFilters}
              setterFn={setFilterStatus}
              filterModel={filterModel}
              value={filterStatus}
              onChange={handleSetFilter}
            />
            <CustomStageFilter
              context={'stage'}
              options={dealStageFilters}
              setterFn={setFilterStage}
              filterModel={filterModel}
              value={filterStage}
              onChange={handleSetFilter}
            />
            {filterModel &&
            filterModel.quickFilterValues &&
            Array.isArray(filterModel.quickFilterValues) &&
            filterModel.quickFilterValues.length > 0 ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Button
                  sx={{
                    height: 40,
                    color: theme.palette.mode === 'dark' ? 'lightblue' : 'lightblue',
                  }}
                  onClick={handleFilterMyData}
                >
                  My Deals
                </Button>
                {/* </Badge> */}
              </Box>
            ) : (
              <Button
                sx={{
                  height: 40,
                  color: theme.palette.mode === 'dark' ? 'lightblue' : 'lightblue',
                }}
                onClick={handleFilterMyData}
              >
                My Deals
              </Button>
            )}
            <ResetColumnsButton
              visibilityModel={visibilityModel}
              onClearVisibility={onClearVisibility}
              initialVisibilityState={initialVisibilityState}
            />
            <ResetSortingButton isSortModelChanged={isSortModelChanged} onClearSorting={onClearSorting} />
            <ResetFiltersButton filterModel={filterModel} handleClearFilters={handleClearFilters} />

            <Badge
              badgeContent={
                <>
                  {filterModel &&
                  filterModel.quickFilterValues &&
                  Array.isArray(filterModel.quickFilterValues) &&
                  filterModel.quickFilterValues.length > 0 ? (
                    <Typography
                      sx={{
                        fontSize: 12,
                        bgcolor: 'rgb(251, 193, 30)',
                        p: 0.5,
                        height: '20px',
                        width: '20px',
                        borderRadius: '50%',
                        display: 'flex', // Flexbox centering
                        alignItems: 'center', // Vertically center
                        justifyContent: 'center', // Horizontally center
                        color: theme.palette.mode === 'dark' ? 'rgba(0,0,0,1)' : 'rgba(0,0,0,1)',
                        fontWeight: 'bold',
                      }}
                    >
                      {filterModel && filterModel.quickFilterValues && Array.isArray(filterModel.quickFilterValues)
                        ? filterModel.quickFilterValues.length
                        : null}
                    </Typography>
                  ) : null}
                </>
              }
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <StyledGridToolbarQuickFilter placeholder={'ctrl+f or cmd+f to search'} inputRef={quickFilterRef} />
            </Badge>
          </>
        </Box>
      </Box>
    </GridToolbarContainer>
  )
}

export default CustomDealsGridToolbar
