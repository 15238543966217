import React from 'react'
import { Box, Chip, Typography, Tooltip, Button, useTheme, Grid } from '@mui/material'
import MassUpdateInvoiceSelect from './MassUpdateInvoiceSelect'
import RenderRowCount from '../DataGrid/RenderRowCount'
const RenderUpdateComponents = ({
  deals,
  rowCount,
  selectionModel,
  massUpdateData,
  handleMassUpdate,
  onMassUpdateChange,
}) => {
  const theme = useTheme()
  console.log('massUpdateData length', Object.entries(massUpdateData)?.length)
  return (
    <>
      {selectionModel && selectionModel?.length > 0 ? (
        <>
          <Grid item xs={0.5} sx={{ display: 'flex', alignItems: 'center', height: '100%', width: ' 100%' }}>
            <Chip
              size='lg'
              label={selectionModel.length}
              sx={{
                height: 50,
                width: 50,
                borderRadius: 25,
                fontSize: 20,
                bgcolor: theme.palette.background.sectionContent,
                transition: 'bgcolor 0.5s ease-in-out',
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Typography variant='h6'>{`Update ${selectionModel.length === 1 ? 'Deal' : 'Deals'}`}</Typography>
            </Box>
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={3}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'right', gap: 2 }}>
              <MassUpdateInvoiceSelect
                name='invoiceStatus'
                value={massUpdateData.invoiceStatus || ''}
                options={[
                  { value: 'Invoiced', label: 'Invoiced', color: theme.palette.text.success },
                  { value: 'Not Invoiced', label: 'Not Invoiced', color: theme.palette.text.warning },
                ]}
                displayLabel='Invoice Status'
                onChange={onMassUpdateChange}
              />
              <MassUpdateInvoiceSelect
                name='billingStatus'
                value={massUpdateData.billingStatus || ''}
                options={[
                  { value: 'Current (<60)', label: 'Current (<60)', color: theme.palette.text.success },
                  { value: 'Past Due (60+)', label: 'Past Due (60+)', color: theme.palette.text.warning },
                  { value: 'Past Due (90+)', label: 'Past Due (90+)', color: theme.palette.text.error },
                  { value: 'Potential Bad Debt', label: 'Potential Bad Debt', color: theme.palette.text.error },
                ]}
                displayLabel='Billing Status'
                onChange={onMassUpdateChange}
              />
              <Tooltip title={selectionModel.length === 1 ? 'Apply Update' : 'Apply Mass Update'}>
                <span>
                  {/* Wrapper to fix disabled button issue */}
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={handleMassUpdate}
                    disabled={Object.entries(massUpdateData)?.length < 1}
                    fullWidth
                  >
                    {selectionModel.length === 1 ? 'Update' : 'Mass Update'}
                  </Button>
                </span>
              </Tooltip>
            </Box>
          </Grid>
        </>
      ) : null}
    </>
  )
}

export default RenderUpdateComponents
