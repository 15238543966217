import React, { useState, useEffect } from 'react'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import { Box, TextField } from '@mui/material'

const EditableDatePicker = ({ value, onChange }) => {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(true) // Automatically open the calendar when the component renders
  }, [])

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%', // Match the grid cell height
          width: '100%', // Match the grid cell width
        }}
      >
        <DatePicker
          open={open}
          onOpen={() => setOpen(true)} // Keep the calendar open if triggered manually
          onClose={() => setOpen(false)} // Close the calendar when user clicks outside
          value={value ? dayjs(value) : null} // Convert value to dayjs if it exists
          onChange={newValue => {
            setOpen(false) // Close the calendar after a selection
            onChange(newValue)
          }}
          slotProps={{
            textField: {
              variant: 'outlined',
              sx: {
                '& .MuiInputAdornment-root': {
                  display: 'none', // Hide the calendar icon
                },
                width: '100%', // Ensure the TextField fills the parent container
                textAlign: 'center', // Align the text in the center
              },
            },
          }}
        />
      </Box>
    </LocalizationProvider>
  )
}

export default EditableDatePicker

// import React, { useRef, useEffect } from 'react'
// import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
// import dayjs from 'dayjs'
// import { Box } from '@mui/material'

// const EditableDatePicker = ({ value, onChange }) => {
//   const ncoDateInputRef = useRef(null)

//   useEffect(() => {
//     if (ncoDateInputRef.current) {
//       ncoDateInputRef.current.focus() // Focus the input field
//       ncoDateInputRef.current.click() // Trigger the calendar dropdown
//     }
//   }, [])

//   return (
//     <LocalizationProvider dateAdapter={AdapterDayjs}>
//       <DatePicker
//         ToolbarComponent={<Box>Test Toolbar Here</Box>}
//         value={value ? dayjs(value) : null} // Convert value to dayjs if it exists
//         onChange={onChange}
//         slotProps={{ textField: { variant: 'outlined', inputRef: ncoDateInputRef } }}
//       />
//     </LocalizationProvider>
//   )
// }

// export default EditableDatePicker
