import React, { useEffect } from 'react'
import { Box, useTheme, Button, Typography, Tooltip, Chip, IconButton, Grid } from '@mui/material'
import { GridFooterContainer } from '@mui/x-data-grid-pro'
const tooltipGridFooter = title => {
  return <Typography>{title}</Typography>
}
export const ApprovalsGridFooter = ({
  massUpdateData,
  selectionModel = [],
  rowCount,
  onChange,
  onUpdate,
  approvals = [],
  anchorEl,
  handleDeleteClick,
  handleConfirmDelete,
  handleClosePopover,
  handleEditClick,
  handleClone,
  selectedData,
  selectedDiscoveryForm,
}) => {
  const theme = useTheme()
  const open = Boolean(anchorEl)
  const id = open ? 'grid-footer-popover' : undefined

  return (
    <GridFooterContainer>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}></Box>
      <>
        <Grid
          container
          sx={{
            padding: 1,
            alignItems: 'center',
            minHeight: 50,
            bgcolor: theme.palette.mode === 'dark' ? 'black' : 'white',
            gap: 1,
          }}
          justifyContent='flex-start'
        >
          <Grid item>
            <Typography variant='body2' mr={2}>
              Total Rows:
            </Typography>
          </Grid>
          <Grid item>
            <Typography sx={{ color: theme.palette.mode === 'dark' ? 'rgba(89, 224, 158,0.8)' : 'rgba(0,0,0,0.6)' }}>
              {rowCount}
            </Typography>
          </Grid>
          <Grid item>
            <Typography sx={{ color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.6)' : 'rgba(0,0,0,0.6)' }}>
              /
            </Typography>
          </Grid>
          <Grid item>
            <Typography sx={{ color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.6)' : 'rgba(0,0,0,0.6)' }}>
              {approvals.length}
            </Typography>
          </Grid>
        </Grid>
      </>
    </GridFooterContainer>
  )
}
