import dayjs from 'dayjs'

export const validateDate = date => {
  if (!date || !dayjs(date).isValid()) {
    return null // Return null for invalid dates
  }
  return dayjs(date) // Return a valid Day.js object
}

export const normalizeDate = (date, timezone) => {
  const validDate = validateDate(date) // Validate before processing
  return validDate
    ? validDate
        .hour(12) // Set time to noon
        .minute(0)
        .second(0)
        .millisecond(0)
        .tz(timezone) // Convert to a specific timezone
        .toISOString() // Convert to ISO format for storage
    : null
}

export const validDateValue = (date, timezone) =>
  dayjs.isDayjs(date) ? date.tz(timezone) : dayjs(date).isValid() ? dayjs(date).tz(timezone) : null
