import React from 'react'
import { Box, Grid, Tooltip, Typography, useTheme } from '@mui/material'
import { renderStatusColor, renderTypeColor } from '../DataGrid/createColumns/renderDealsColumns'
import { calculateTotalMonthsForProducts } from '../../utility/calculateTotalMonths'
import { formatCurrency } from '../DealsKanban/aggregateStageTotals'

const AccountingDrawerHeader = ({ organization, billingContact, newDeal, totals }) => {
  const theme = useTheme()
  const { totalMonths } =
    newDeal && Array.isArray(newDeal.products) ? calculateTotalMonthsForProducts(newDeal.products) : { totalMonths: 1 }
  console.log('totalMonths', totalMonths)
  return (
    <Box
      sx={{
        p: 3,
        backgroundColor: theme.palette.background.paper,
        borderRadius: '8px',
        boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)',
      }}
    >
      <Grid
        container
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          overflowY: 'hidden',
        }}
      >
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <Tooltip title={<Typography>{'Organization Name'}</Typography>} placement='right'>
              <Typography
                variant='h5'
                sx={{ fontWeight: 'bold', textAlign: 'left', color: theme.palette.text.primary }}
              >
                {organization?.name || 'N/A'}
              </Typography>
            </Tooltip>
            <Box>
              <Tooltip title={<Typography>{'Legal Entity Name'}</Typography>} placement='left'>
                <Typography
                  sx={{ textAlign: 'left', fontWeight: 'bold', fontStyle: 'italic', color: theme.palette.text.primary }}
                >
                  {organization?.dba || 'N/A'}{' '}
                </Typography>
              </Tooltip>
            </Box>
            <Box>
              <Tooltip title={<Typography>{'Organization Status'}</Typography>} placement='left'>
                <Typography
                  sx={{
                    textAlign: 'left',
                    color: renderStatusColor(organization?.status),
                  }}
                >
                  {organization?.status || 'N/A'}
                </Typography>
              </Tooltip>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={4} xl={4} sx={{ p: 1, pl: 1.5, pr: 1.5 }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant='subtitle2' color='textSecondary' sx={{ textAlign: 'left' }}>
              Billing Contact
            </Typography>
            <Typography sx={{ textAlign: 'left', color: theme.palette.text.primary }}>
              {billingContact?.name || 'N/A'}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant='subtitle2' color='textSecondary' sx={{ textAlign: 'left' }}>
              Billing Email
            </Typography>
            <Typography
              noWrap
              sx={{
                textAlign: 'left',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                color: theme.palette.text.primary,
              }}
            >
              {billingContact?.email || 'N/A'}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant='subtitle2' color='textSecondary' sx={{ textAlign: 'left' }}>
              Billing Phone
            </Typography>
            <Typography sx={{ textAlign: 'left', color: theme.palette.text.primary }}>
              {billingContact?.phone || 'N/A'}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant='subtitle2' color='textSecondary' sx={{ textAlign: 'left' }}>
              Website
            </Typography>
            <Typography
              noWrap
              sx={{
                textAlign: 'left',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                color: theme.palette.text.primary,
              }}
            >
              {organization?.website || 'N/A'}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={4} xl={4} sx={{ p: 1, pl: 1.5, pr: 1.5 }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant='subtitle2' color='textSecondary' sx={{ textAlign: 'left' }}>
              Street
            </Typography>
            <Typography sx={{ textAlign: 'left', color: theme.palette.text.primary }}>
              {organization?.street || 'N/A'}
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant='subtitle2' color='textSecondary' sx={{ textAlign: 'left' }}>
              City
            </Typography>
            <Typography sx={{ textAlign: 'left', color: theme.palette.text.primary }}>
              {organization?.city || 'N/A'}
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant='subtitle2' color='textSecondary' sx={{ textAlign: 'left' }}>
              State
            </Typography>
            <Typography sx={{ textAlign: 'left', color: theme.palette.text.primary }}>
              {organization?.state || 'N/A'}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant='subtitle2' color='textSecondary' sx={{ textAlign: 'left' }}>
              Zip
            </Typography>
            <Typography sx={{ textAlign: 'left', color: theme.palette.text.primary }}>
              {organization?.Zip || 'N/A'}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={4} xl={4} sx={{ p: 1, pl: 1.5, pr: 1.5 }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant='subtitle2' color='textSecondary' sx={{ textAlign: 'left' }}>
              Total Gross Profit
            </Typography>
            <Typography sx={{ color: theme.palette.success.main, textAlign: 'left' }}>
              {totals?.grossProfit ? formatCurrency(totals?.grossProfit) : 'N/A'}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            {newDeal.type === 'IO' ? (
              <Typography variant='subtitle2' color='textSecondary' sx={{ textAlign: 'left' }}>
                Monthly Total
              </Typography>
            ) : (
              <Typography variant='subtitle2' color='textSecondary' sx={{ textAlign: 'left' }}>
                Monthly Total
              </Typography>
            )}

            <Typography sx={{ color: theme.palette.success.main, textAlign: 'left' }}>
              {totals?.monthlyTotal ? formatCurrency(totals?.monthlyTotal) : 'N/A'}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant='subtitle2' color='textSecondary' sx={{ textAlign: 'left' }}>
              Total Contract Amount
            </Typography>
            <Typography sx={{ color: theme.palette.success.main, textAlign: 'left' }}>
              {totals?.total ? formatCurrency(totals?.total) : 'N/A'}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant='subtitle2' color='textSecondary' sx={{ textAlign: 'left' }}>
              Implementation Fee
            </Typography>
            <Typography sx={{ color: theme.palette.success.main, textAlign: 'left' }}>
              {totals?.implementationFee ? formatCurrency(totals?.implementationFee) : 'N/A'}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default AccountingDrawerHeader

// import React from 'react'
// import { Box, Grid, Typography, useTheme } from '@mui/material'
// import { renderStatusColor } from '../DataGrid/createColumns/renderDealsColumns'
// import { calculateTotalMonthsForProducts } from '../../utility/calculateTotalMonths'

// const AccountingDrawerHeader = ({ organization, billingContact, newDeal, totals }) => {
//   const theme = useTheme()

//   return (
//     <Box
//       sx={{
//         padding: 3,
//         backgroundColor: theme.palette.background.paper,
//         borderRadius: '8px',
//         boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)',
//         marginBottom: 2,
//       }}
//     >
//       <Grid container spacing={2}>
//         {/* Top Row */}
//         <Grid item xs={12} sm={4}>
// <Typography variant='h6' sx={{ fontWeight: 'bold', textAlign: 'left' }}>
//   {organization?.name || 'N/A'}
// </Typography>
//         </Grid>
//         <Grid item xs={12} sm={4}>
// <Typography variant='subtitle2' color='textSecondary' sx={{ textAlign: 'left' }}>
//   Legal Entity Name
// </Typography>
// <Typography sx={{ textAlign: 'left' }}>{organization?.DBA || 'N/A'}</Typography>
//         </Grid>
//         <Grid item xs={12} sm={4}>
//           <Typography variant='subtitle2' color='textSecondary' sx={{ textAlign: 'left' }}>
//             Status
//           </Typography>
//           <Typography sx={{ textAlign: 'left', color: renderStatusColor(organization?.status) }}>
//             {organization?.status || 'N/A'}
//           </Typography>
//         </Grid>

//         {/* Billing Details */}
//         <Grid item xs={12} sm={4}>
//           <Typography variant='subtitle2' color='textSecondary' sx={{ textAlign: 'left' }}>
//             Billing Contact
//           </Typography>
//           <Typography sx={{ textAlign: 'left' }}>{billingContact?.name || 'N/A'}</Typography>
//         </Grid>
//         <Grid item xs={12} sm={4}>
//           <Typography variant='subtitle2' color='textSecondary' sx={{ textAlign: 'left' }}>
//             Billing Email
//           </Typography>
//           <Typography
//             noWrap
//             sx={{
//               textAlign: 'left',
//               textOverflow: 'ellipsis',
//               overflow: 'hidden',
//             }}
//           >
//             {billingContact?.email || 'N/A'}
//           </Typography>
//         </Grid>
//         <Grid item xs={12} sm={4}>
//           <Typography variant='subtitle2' color='textSecondary' sx={{ textAlign: 'left' }}>
//             Billing Phone
//           </Typography>
//           <Typography sx={{ textAlign: 'left' }}>{billingContact?.phone || 'N/A'}</Typography>
//         </Grid>

//         {/* Website and Address */}
// <Grid item xs={12} sm={6}>
//   <Typography variant='subtitle2' color='textSecondary' sx={{ textAlign: 'left' }}>
//     Website
//   </Typography>
//   <Typography
//     noWrap
//     sx={{
//       textAlign: 'left',
//       textOverflow: 'ellipsis',
//       overflow: 'hidden',
//     }}
//   >
//     {organization?.website || 'N/A'}
//   </Typography>
// </Grid>
// <Grid item xs={12} sm={6}>
//   <Typography variant='subtitle2' color='textSecondary' sx={{ textAlign: 'left' }}>
//     Street
//   </Typography>
//   <Typography sx={{ textAlign: 'left' }}>{organization?.street || 'N/A'}</Typography>
// </Grid>
// <Grid item xs={6} sm={4}>
//   <Typography variant='subtitle2' color='textSecondary' sx={{ textAlign: 'left' }}>
//     City
//   </Typography>
//   <Typography sx={{ textAlign: 'left' }}>{organization?.city || 'N/A'}</Typography>
// </Grid>
// <Grid item xs={6} sm={4}>
//   <Typography variant='subtitle2' color='textSecondary' sx={{ textAlign: 'left' }}>
//     State
//   </Typography>
//   <Typography sx={{ textAlign: 'left' }}>{organization?.state || 'N/A'}</Typography>
// </Grid>
// <Grid item xs={6} sm={4}>
//   <Typography variant='subtitle2' color='textSecondary' sx={{ textAlign: 'left' }}>
//     Zip
//   </Typography>
//   <Typography sx={{ textAlign: 'left' }}>{organization?.Zip || 'N/A'}</Typography>
// </Grid>

//         {/* Totals */}
// <Grid item xs={6} sm={4}>
//   <Typography variant='subtitle2' color='textSecondary' sx={{ textAlign: 'left' }}>
//     Total Gross Profit
//   </Typography>
//   <Typography sx={{ color: theme.palette.success.main, textAlign: 'left' }}>
//     {totals?.grossProfit ? `$${totals.grossProfit}` : 'N/A'}
//   </Typography>
// </Grid>
// <Grid item xs={6} sm={4}>
//   <Typography variant='subtitle2' color='textSecondary' sx={{ textAlign: 'left' }}>
//     Monthly Total
//   </Typography>
//   <Typography sx={{ color: theme.palette.success.main, textAlign: 'left' }}>
//     {totals?.monthlyTotal ? `$${totals.monthlyTotal}` : 'N/A'}
//   </Typography>
// </Grid>
// <Grid item xs={6} sm={4}>
//   <Typography variant='subtitle2' color='textSecondary' sx={{ textAlign: 'left' }}>
//     Total Contract Amount
//   </Typography>
//   <Typography sx={{ color: theme.palette.success.main, textAlign: 'left' }}>
//     {totals?.contractAmount ? `$${totals.contractAmount}` : 'N/A'}
//   </Typography>
// </Grid>
// <Grid item xs={6} sm={4}>
//   <Typography variant='subtitle2' color='textSecondary' sx={{ textAlign: 'left' }}>
//     Implementation Fee
//   </Typography>
//   <Typography sx={{ color: theme.palette.success.main, textAlign: 'left' }}>
//     {totals?.implementationFee ? `$${totals.implementationFee}` : 'N/A'}
//   </Typography>
// </Grid>

//         {/* Total Months */}
//         {newDeal && newDeal?.type === 'IO' && (
//           <Grid item xs={6} sm={4}>
//             <Typography variant='subtitle2' color='textSecondary' sx={{ textAlign: 'left' }}>
//               Total Months
//             </Typography>
//             <Typography sx={{ textAlign: 'left' }}>
//               {
//                 calculateTotalMonthsForProducts(newDeal && Array.isArray(newDeal.products) ? newDeal.products : [])
//                   .totalMonths
//               }
//             </Typography>
//           </Grid>
//         )}
//       </Grid>
//     </Box>
//   )
// }

// export default AccountingDrawerHeader
