import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Grid, useTheme, Box, Typography } from '@mui/material'
import {
  contractMap,
  generalInfoMap,
  totalsMap,
  ioTotalsMap,
  ioContractMap,
  csaTotalsMap,
  billingInfoMap,
} from './dataMaps'
import DetailsLoading from '../Loading/DetailsLoading'
import GenerateTable from './GenerateTable'
import { useOrganizationById } from '../../api/aws/useOrganization'
import { useDealDetailsContext } from '../../context/DealsDetailsProvider/DealsDetailsProvider'
import useTotals from '../../api/customHooks/useTotals'
import { useApproversById } from '../../api/customHooks/useApproversById'
import { useActiveUser } from '../../api/slack/useActiveUser'
import RenderApprover from '../Approvals/RenderApprover'
import { useApproveDeal } from '../../api/approvals/useApproveDeal'
import { determineApproverIds } from '../../context/DealsDetailsProvider/determineApprovers'
import { tableSectionStyles } from './style'
import GenerateInfoTables from './GenerateInfoTables'
import { useRejectDeal } from '../../api/approvals/useRejectDeal'
import { useResolveDeal } from '../../api/approvals/useResolveDeal'
import { useQueryClient } from '@tanstack/react-query'
import { useCreateTimeline } from '../../api/aws/useCreateTimeline'
import { updateDealTimelineData } from '../Timeline/createTimelineData'
import { EmptyPlaceholderRow } from '../ProductRows/style'
import { calculateTotalMonthsForProducts } from '../../utility/calculateTotalMonths'
import { useContact } from '../../api/aws/useContact'
import { useUser } from '../../api/aws/useUser'
const ReviewDeal = () => {
  const { dealId } = useParams()
  const { newDeal, setNewDeal, productRows, timelineData, handleCreateTimelineData } = useDealDetailsContext()
  const { mutate: approveDeal } = useApproveDeal()
  const { mutate: rejectDeal } = useRejectDeal()
  const { mutate: resolveDeal } = useResolveDeal()
  const approverIds = newDeal ? determineApproverIds(newDeal) : []
  const { approversById, isApproversByIdLoading } = useApproversById(approverIds || [])
  const {
    activeUser: { user: activeUser },
  } = useActiveUser()
  const { mutate: createTimelineEntry } = useCreateTimeline()
  const [isApproving, setIsApproving] = useState(false)
  const [rejectDialogs, setRejectDialogs] = useState({})
  const [resolveDialogs, setResolveDialogs] = useState({})
  const [approveDialogs, setApproveDialogs] = useState({})
  const [rejectText, setRejectText] = useState('')
  const [resolveText, setResolveText] = useState('')
  const [isRejecting, setIsRejecting] = useState(false)
  const [isResolving, setIsResolving] = useState(false)
  const [isRejectTextOpen, setIsRejectTextOpen] = useState(false)
  const [isResolveTextOpen, setIsResolveTextOpen] = useState(false)
  const totals = useTotals(productRows, newDeal?.implementationFee || 0, newDeal?.type)
  const orgId = newDeal && Array.isArray(newDeal.organizations) ? newDeal.organizations[0] : ''
  const { organization, isOrganizationLoading } = useOrganizationById(orgId)
  const { data: billingContact, isLoading: isBillingContactLoading, isError: isBillingContactError } = useContact(
    organization && Array.isArray(organization.billingContact) ? organization.billingContact[0] : null
  )
  const {
    organization: contractedBy,
    isOrganizationLoading: isContractedByLoading,
    isOrganizationError: isContractedByError,
  } = useOrganizationById(organization && Array.isArray(organization.contractedBy) ? organization.contractedBy[0] : [])
  const { user: createdBy, isLoading: isCreatedByLoading, isError: isCreatedByError } = useUser(
    newDeal && newDeal.createdBy ? newDeal.createdBy : null
  )
  const queryClient = useQueryClient()
  const theme = useTheme()
  const adminIds = ['U062JDP8R5X', 'UHKAA49CJ']
  const { allSame } =
    newDeal && Array.isArray(newDeal.products) ? calculateTotalMonthsForProducts(newDeal.products) : { totalMonths: 1 }
  useEffect(() => {
    if (timelineData) {
      console.log('timelineData', timelineData)
    }
  }, [timelineData])
  useEffect(() => {
    if (organization) {
      console.log('[Review Deal] organization', organization)
    }
  }, [organization])
  const handleApprove = approverId => {
    if (!dealId || !activeUser) {
      console.error('Missing dealId or activeUser for approval')
      return
    }
    // const approverId = activeUser?.id || activeUser?.user?.id
    console.log('APPROVING DEAL FOR ID: ', approverId)

    const timelineData = updateDealTimelineData(newDeal?.deal_uuid, activeUser, 'Approve', 'Approval', 'Deal approved')

    try {
      approveDeal(
        {
          dealId,
          requestor: { ...newDeal?.requestor },
          approverId,
        },
        {
          onSuccess: data => {
            setNewDeal(prevDeal => {
              const updatedApprovers = prevDeal.approvers.map(approver => {
                if (approver.id === approverId || adminIds.includes(activeUser.id)) {
                  return {
                    ...approver,
                    hasRejected: false, // Mark approver as rejected
                    hasApproved: true,
                    timeRejected: new Date().toISOString(),
                  }
                }
                return approver
              })
              return {
                ...prevDeal,
                status: 'Approved',
                approvers: updatedApprovers,
              }
            })
            createTimelineEntry(timelineData)
          },
          onError: e => {
            console.log(e)
          },
        }
      )
      handleRejectTextClose()
      // Optimistically update the approver status and the deal
    } catch (error) {
      console.error(error)
    }
  }

  const handleRejectTextOpen = approverId => {
    console.log('[Reject] Dialog Open for id: ', approverId)
    setRejectDialogs(prev => ({ ...prev, [approverId]: true }))
  }

  const handleRejectTextClose = approverId => {
    setRejectDialogs(prev => ({ ...prev, [approverId]: false }))
  }

  const handleResolveTextOpen = approverId => {
    console.log('[Resolve] Dialog Open for id: ', approverId)
    setResolveDialogs(prev => ({ ...prev, [approverId]: true }))
  }
  const handleResolveTextClose = approverId => {
    setResolveDialogs(prev => ({ ...prev, [approverId]: false }))
  }
  const handleReject = approverId => {
    if (!dealId || !activeUser) {
      console.error('Missing dealId or activeUser for approval')
      return
    }
    console.log('Rejecting Deal for id: ', approverId)

    const timelineData = updateDealTimelineData(
      newDeal?.deal_uuid,
      activeUser,
      'Reject',
      'Approval',
      'Deal rejected',
      rejectText
    )
    try {
      rejectDeal(
        {
          dealId,
          requestor: { ...newDeal?.requestor },
          approverId,
          reason: rejectText,
        },
        {
          onSuccess: data => {
            setNewDeal(prevDeal => {
              const updatedApprovers = prevDeal.approvers.map(approver => {
                if (approver.id === approverId || adminIds.includes(activeUser.id)) {
                  return {
                    ...approver,
                    hasRejected: true,
                    reason: rejectText,
                    timeRejected: new Date().toISOString(),
                  }
                }
                return approver
              })
              return {
                ...prevDeal,
                status: 'Rejected',
                approvers: updatedApprovers,
              }
            })
            createTimelineEntry(timelineData)
          },
          onError: e => {
            console.log(e)
          },
        }
      )
      handleRejectTextClose(approverId)
    } catch (error) {
      console.error(error)
    }
  }

  const handleResolve = (requestorId, approverId) => {
    if (!dealId || !activeUser) {
      console.error('Missing dealId or activeUser for approval')
      return
    }
    console.log('approverId', approverId)
    console.log('requestorId', requestorId)
    const timelineData = updateDealTimelineData(
      newDeal?.deal_uuid,
      activeUser,
      'Resolve',
      'Approval',
      'Deal resolved',
      resolveText
    )
    console.log('resolveText: ', resolveText, 'requestor: ', newDeal?.requestor)
    try {
      resolveDeal(
        {
          dealId,
          requestor: { ...newDeal?.requestor, resolution: resolveText },
          approverId,
          resolution: resolveText,
        },
        {
          onSuccess: data => {
            setNewDeal(prevDeal => {
              const updatedRequestor = {
                ...prevDeal?.requestor,
                hasResolved: true,
                resolution: resolveText,
              }
              const updatedApprovers = prevDeal.approvers.map(approver => {
                if (approver.id === approverId || adminIds.includes(approverId)) {
                  return {
                    ...approver,
                    hasResolved: true, // Mark approver as resolved
                    hasRejected: false,
                    resolution: resolveText,
                    timeResolved: new Date().toISOString(),
                  }
                }
                return approver
              })
              return {
                ...prevDeal,
                status: 'Resolved',
                approvers: updatedApprovers,
                requestor: updatedRequestor,
                isResolved: true,
              }
            })
            createTimelineEntry(timelineData)
          },
          onError: e => {
            console.log(e)
          },
        }
      )
      handleResolveTextClose()
      // Optimistically update the approver status and the deal
    } catch (error) {
      console.error(error)
    }
  }

  if (
    isOrganizationLoading ||
    isApproversByIdLoading ||
    isContractedByLoading ||
    isBillingContactLoading ||
    isCreatedByLoading
  ) {
    return <DetailsLoading />
  }

  return (
    <Grid
      container
      spacing={2}
      sx={{
        height: 'calc(100vh - 122px)', // Ensure full height layout
        overflowY: 'auto',
        pl: 2,
        pr: 2,
      }}
    >
      <Grid item xs={12} sm={6} lg={3}>
        <Box sx={{ bgcolor: theme.palette.background.default, p: 1 }}>
          <Typography variant='h6' sx={tableSectionStyles(theme)}>
            {'Approvers'}
          </Typography>
        </Box>
        {newDeal?.approvers && newDeal?.approvers?.length > 0 ? (
          <>
            {/* <Box sx={{ bgcolor: theme.palette.background.default, p: 1 }}>
              <Typography variant='h6' sx={tableSectionStyles(theme)}>
                {'Approvers'}
              </Typography>
            </Box> */}
            {newDeal.approvers.map(approver => {
              return (
                <RenderApprover
                  key={approver.id}
                  activeUser={activeUser}
                  approvers={newDeal?.approvers}
                  approver={approver}
                  requestor={newDeal?.requestor}
                  isApproving={isApproving}
                  onApprove={() => handleApprove(approver.id)}
                  rejectText={rejectText}
                  setRejectText={setRejectText}
                  isRejecting={isRejecting}
                  handleRejectTextOpen={() => handleRejectTextOpen(approver.id)}
                  handleRejectTextClose={() => handleRejectTextClose(approver.id)}
                  isRejectTextOpen={!!rejectDialogs[approver.id]}
                  handleReject={() => handleReject(approver.id)}
                  resolveText={resolveText}
                  setResolveText={setResolveText}
                  isResolving={isResolving}
                  isResolveTextOpen={!!resolveDialogs[approver.id]}
                  handleResolveTextOpen={() => handleResolveTextOpen(approver.id)}
                  handleResolveTextClose={() => handleResolveTextClose(approver.id)}
                  handleResolve={handleResolve}
                  requestedTime={newDeal.requestedTime || ''}
                  isResolved={newDeal && newDeal.isResolved ? newDeal.isResolved : false}
                  timeResolved={newDeal?.timeResolved}
                  resolution={newDeal && newDeal.resolution ? newDeal.resolution : null}
                />
              )
            })}
          </>
        ) : (
          <Box
            sx={{
              flexGrow: 1,
              border:
                theme.palette.mode === 'dark' ? '1px solid rgba(224, 224, 224, 0.4)' : '1px solid rgba(0,0,0,0.4)',
              borderRadius: '25px',
              backgroundImage: theme.palette.background.image,
              backgroundSize: '10px 10px', // Adjust size of the dots
              minHeight: '330px',
              width: '100%',
              height: '60%', // Full height
              display: 'flex', // Enable flexbox
              justifyContent: 'center', // Center horizontally
              alignItems: 'center', // Center vertically
            }}
            // bgcolor={'blue'}
          ></Box>
        )}
      </Grid>

      {/* General Info Section */}
      <Grid item xs={12} sm={6} lg={3}>
        <GenerateInfoTables options={generalInfoMap(organization)} section={'General Info'} />
      </Grid>
      {/* Totals Info Section */}
      <Grid item xs={12} sm={6} lg={3}>
        <GenerateInfoTables
          options={
            newDeal && newDeal.type === 'IO'
              ? ioTotalsMap(newDeal, totals, allSame)
              : ['CSA', 'RCSA'].includes(newDeal.type)
              ? csaTotalsMap(newDeal, totals)
              : totalsMap(newDeal, totals, allSame)
          }
          section={'Totals Info'}
        />
      </Grid>
      {/* Billing Info Section */}
      <Grid item xs={12} sm={6} lg={3}>
        <GenerateInfoTables
          options={billingInfoMap(newDeal, contractedBy, createdBy, billingContact)}
          section={'Billing Info'}
        />
      </Grid>

      {/* Contracts or Products */}
      <Grid item xs={12} sm={12} lg={12}>
        <GenerateTable
          options={
            newDeal && newDeal.type === 'IO' ? ioContractMap(productRows, theme) : contractMap(productRows, theme)
          }
        />
      </Grid>
    </Grid>
    // </Grid>
  )
}

export default ReviewDeal
