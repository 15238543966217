import React, { useState } from 'react'
import { strategyFieldConfig } from './strategyFieldConfig'
import { Typography, TextField, Grid, Box, useTheme, Button, CircularProgress, IconButton } from '@mui/material'
import TaskAltIcon from '@mui/icons-material/TaskAlt'
import CancelIcon from '@mui/icons-material/Cancel'
import EditStrategyButton from '../ui/edit-strategy-button'
import { useDealDetailsContext } from '../../context/DealsDetailsProvider/DealsDetailsProvider'
import RefreshButton from '../ui/refresh-button'

const RenderHeader = ({
  isEditMode,
  setIsEditMode,
  generalInfo,
  setGeneralInfo,
  onSave,
  onChange,
  onRefresh,
  activeUser,
  errors,
}) => {
  const { handleStartEdit, handleStopEdit } = useDealDetailsContext()
  const [isSaving, setIsSaving] = useState(false)
  const theme = useTheme()
  // const pageNames = ['General Information', 'Integration']
  const pageNames = ['General Information']
  const handleSave = async () => {
    setIsSaving(true)
    try {
      await onSave()
      handleStopEdit()
      setIsSaving(false)
    } catch (error) {
      console.log('Error saving strategy: ', error)
      setIsSaving(false)
    }
  }
  return (
    <Box>
      <Grid container>
        <Grid item xs={10}>
          <Typography
            sx={{ color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,1)' : 'rgba(0,0,0,1)', ml: 1 }}
            variant='h6'
            gutterBottom
          >
            General Information
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'right' }}>
            {isSaving && <CircularProgress />}
            {isEditMode ? (
              <>
                <IconButton
                  onClick={() => handleStopEdit()}
                  sx={{
                    gap: 2,
                    borderRadius: 0,
                    color: theme.palette.text.secondary,
                    '&:hover': {
                      color: theme.palette.text.primary,
                    },
                    '&:hover .MuiSvgIcon-root': {
                      color: 'rgb(220, 20, 60)',
                    },
                  }}
                >
                  <CancelIcon
                    sx={{
                      height: 30,
                      width: 30,
                      color: theme.palette.text.secondary,
                      '&:hover': {
                        color: 'rgb(220, 20, 60)',
                      },
                    }}
                  />
                  <Typography>Cancel</Typography>
                </IconButton>
                <IconButton
                  onClick={handleSave}
                  sx={{
                    gap: 2,
                    borderRadius: 0,
                    color: theme.palette.text.secondary,
                    '&:hover': {
                      color: theme.palette.text.primary,
                      fontWeight: 'bold',
                    },
                    '&:hover .MuiSvgIcon-root': {
                      color: 'rgb(50, 187, 130)',
                    },
                  }}
                >
                  <TaskAltIcon
                    sx={{
                      height: 30,
                      width: 30,
                      color: theme.palette.text.secondary,
                    }}
                  />
                  <Typography>Save</Typography>
                </IconButton>
              </>
            ) : (
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                {activeUser?.user?.id === 'U062JDP8R5X' ? (
                  <>
                    <RefreshButton onClick={onRefresh} tooltipTitle={'Recreate Strategy'} />
                    <EditStrategyButton color={'primary'} variant='contained' onEdit={() => handleStartEdit()} />
                  </>
                ) : (
                  <EditStrategyButton color={'primary'} variant='contained' onEdit={() => handleStartEdit()} />
                )}
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>

      <Grid
        container
        p={0.5}
        sx={{
          backgroundColor: theme.palette.mode === 'dark' ? 'rgb(30, 39, 53)' : 'rgb(255, 255, 255)',
          borderRadius: 1,
          alignItems: 'center',
        }}
      >
        {Object.keys(strategyFieldConfig).map((pageName, pageIndex) => (
          <React.Fragment key={pageIndex}>
            {pageNames.includes(pageName) ? (
              <>
                {strategyFieldConfig[pageName].map((field, index) => {
                  // console.log('errors[field.name]', errors[field.name]?.value)
                  return (
                    <Grid item xs={12} sm={6} key={index}>
                      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2, mb: 0.5 }}>
                        <Typography
                          sx={{
                            width: '40%',
                            fontWeight: 'bold',
                            ml: 2,
                          }}
                        >
                          {field.label}
                        </Typography>

                        {!isEditMode ? (
                          <Typography
                            sx={{
                              flexGrow: 1,
                              padding: '8px 16px',
                              backgroundColor: theme.palette.background.input,
                              borderRadius: '4px',
                              fontSize: '0.875rem',
                              color: theme.palette.mode === 'dark' ? 'rgb(181, 191, 205)' : 'rgb(97, 97, 97)',
                            }}
                          >
                            {typeof generalInfo[field.name] === 'object'
                              ? JSON.stringify(generalInfo[field.name])
                              : generalInfo[field.name] || '—'}
                          </Typography>
                        ) : (
                          <TextField
                            name={field.name}
                            type={field.type || 'text'}
                            value={generalInfo[field.name] || ''}
                            onChange={onChange}
                            variant='outlined'
                            fullWidth
                            size='small'
                            error={errors?.[field.name]?.value ? errors?.[field.name]?.value : null}
                            InputProps={{
                              readOnly: field.readOnly,
                            }}
                            sx={{
                              flexGrow: 1,
                              backgroundColor: theme.palette.background.input,
                              borderRadius: '4px',
                            }}
                          />
                        )}
                      </Box>
                    </Grid>
                  )
                })}
              </>
            ) : null}
          </React.Fragment>
        ))}
      </Grid>
    </Box>
  )
}

export default RenderHeader
