import { styled } from '@mui/material/styles'
import { Button } from 'antd'
import {
  Accordion,
  Avatar,
  TableCell,
  TableRow,
  Table,
  Tooltip,
  Typography,
  Tab,
  Tabs,
  Card,
  Box,
  TextField,
  List,
  ListItem,
  ListItemButton,
  Grid,
  lighten,
  darken,
  Chip,
} from '@mui/material'
import Select from '@mui/material/Select'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { getTabColor } from './getTabColor'
import { getTeamTasksTabColor } from './getTaskColor'
import { getCreativeTasksTabColor } from './getCreativeTasksTabColor'
import Board from 'react-trello'
import { renderDealStatusColor, renderTypeColor } from '../components/DataGrid/createColumns/renderDealsColumns'
import { setStageStyle } from '../components/DealsKanban/DealsKanban'
import { DataGridPro } from '@mui/x-data-grid-pro'

export const StyledExpandMoreIcon = styled(ExpandMoreIcon)(({ color }) => ({
  color: color,
}))

export const MyStyledComponent = styled('div')(({ theme }) => ({
  overflowY: 'scroll',
  '&::-webkit-scrollbar': {
    width: '6px',
  },
  '&::-webkit-scrollbar-track': {
    boxShadow:
      theme.palette.mode === 'dark' ? 'inset 0 0 6px rgba(0,0,0,0.3)' : 'inset 0 0 6px rgba(255,255,255,255.3)',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.5)' : 'rgba(0,0,0,0.5)',
    borderRadius: '6px',
  },
  backgroundColor: theme.palette.mode === 'dark' ? '#17191E' : '#e8f6fc',
  color: theme.palette.primary.main,
  alignContent: 'center',
  textAlign: 'center',
  // overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  // width: '100vw',
}))
export const StyledCreateButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  border: 'solid 1px',
  '&:hover': {
    backgroundColor: theme.palette.tertiary.light,
  },
  margin: '10px',
}))

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  position: 'relative',
  backgroundColor: 'rgb(26 22 22)',
  color: theme.palette.secondary.contrastText, // If you have contrastText defined
  fontSize: '16px',
}))
export const StyledNcoTableCell = styled(TableCell)(({ theme }) => ({
  borderTop: 'none',
  borderBottom: 'none',
  borderWidth: '0', // Remove border
  textAlign: 'left' /* Aligns text to the left */,
  verticalAlign: 'middle' /* Aligns content vertically in the middle */,
  whiteSpace: 'nowrap' /* Prevents text wrapping */,
  width: '20%' /* Sets a fixed width, change as per your layout */,
  // boxSizing:
  //   'border-box' /* Includes padding and border in the element's total width and height */,
  padding: '8px' /* Consistent padding */,
  '&:hover': {
    transform: 'none !important',
    transition: 'none !important', // You may also need to reset transitions
  },
}))

export const EditableDisplayCell = styled(TableCell)(({ theme }) => ({
  borderTop: 'none',
  borderBottom: 'none',
  borderWidth: '0', // Remove border
  textAlign: 'left' /* Aligns text to the left */,
  verticalAlign: 'middle' /* Aligns content vertically in the middle */,
  whiteSpace: 'nowrap' /* Prevents text wrapping */,
  width: '20%' /* Sets a fixed width, change as per your layout */,
  boxSizing: 'border-box' /* Includes padding and border in the element's total width and height */,
  padding: '8px' /* Consistent padding */,
  flexGrow: 1,
  // backgroundColor: theme.palette.mode === 'dark' ? 'black' : '#af6f7fb',
}))

export const StyledDrawerTableCell = styled(TableCell)(({ theme }) => ({
  borderTop: 'none',
  borderBottom: 'none',
  borderWidth: '0', // Remove border
  textAlign: 'left', // Aligns text to the left
  verticalAlign: 'middle', // Aligns content vertically in the middle
  whiteSpace: 'nowrap', // Prevents text wrapping
  width: '10%', // Sets a fixed width. Adjust according to the number of columns
  boxSizing: 'border-box', // Includes padding and border in the element's total width and height
  // padding: '8px', // Consistent padding
}))
export const StyledCellText = styled(TableCell)(({ theme }) => ({
  color: theme.palette.primary.darker,
  margin: '20px',
}))
export const StyledAccordionComponent = styled(Accordion)(({ theme }) => ({
  // backgroundColor: '#3d3739',
  backgroundColor: 'black',
  color: theme.palette.primary.darker,
  fontSize: '14px',
  margin: '2px',
}))
export const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  color: theme.palette.mode === 'dark' ? 'white' : 'black',
}))
export const StyledGridTypography = styled(Typography)(({ theme }) => ({
  fontFamily: '"Muli", sans-serif',
  fontSize: '12px',
  color: theme.palette.mode === 'dark' ? 'white' : '#405466',
}))
export const StyledProspectTypography = styled(Typography)(({ theme }) => ({
  fontFamily: '"Muli", sans-serif',
  fontSize: '18px',
  color: theme.palette.mode === 'dark' ? 'white' : '#405466',
}))
export const StyledGridDefaultTypography = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.6)' : 'rgba(0,0,0,0.)',
}))
export const StyledServiceSelect = styled(Select)(({ theme }) => ({
  color: 'white',
  textAlign: 'center',
  fontSize: '14px',
  width: '180px',
  minwidth: '180px',
  maxWidth: '200px',
  '& .MuiSelect-select': {
    spacing: 1,
    textAlign: 'center',
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    '&:focus': {
      backgroundColor: 'transparent',
    },
    '& .MuiSvgIcon-root': {
      display: 'flex',
    },
  },
}))
export const StyledNcoTab = styled(props => <Tab {...props} />)(({ label, theme }) => {
  // Define the background color based on the theme mode
  // console.log('Theme in StyledTab: ', theme)

  const tabColor = getTabColor(label)

  return {
    backgroundColor: 'transparent',
    // theme.palette.mode === 'dark' ? 'transparent' : 'rgb(255, 255, 240)',
    // border: `1px solid ${theme.palette.primary.main}`,
    fontSize: '16px',
    maxHeight: 20,
    borderRadius: '10px',
    margin: '0 5px',
    color: tabColor,
    // color: 'white',
    '&:hover': {
      border: 'solid 2px',
      borderColor: theme.palette.mode === 'dark' ? 'white' : 'black',
      backgroundColor: theme.hover,
      // margin: '0 5px',
      color: tabColor,
    },
    '&.Mui-selected': {
      border: 'solid 2px',
      borderColor: theme.palette.mode === 'dark' ? 'white' : 'black',
      backgroundColor:
        theme.palette.mode === 'dark'
          ? '#424242' //theme.palette.tertiary.darker
          : theme.palette.primary.lighter,
      color: tabColor,
    },
  }
})

export const StyledNcoTabs = styled(Tabs)(({ theme }) => ({
  '& .MuiTabs-indicator': {
    display: 'none', // This hides the underline indicator
  },
  backgroundColor: theme.palette.mode === 'dark' ? 'rgb(26, 22, 22)' : 'rgb(255, 255, 255)',
}))
export const StyledTeamTasksTab = styled(props => <Tab {...props} />)(({ label, theme }) => {
  // Define the background color based on the theme mode
  // console.log('Theme in StyledTab: ', theme)

  const tabColor = getTeamTasksTabColor(label)

  return {
    backgroundColor: 'transparent',
    // theme.palette.mode === 'dark' ? 'transparent' : 'rgb(255, 255, 240)',
    // border: `1px solid ${theme.palette.primary.main}`,
    height: 30,
    // minHeight: 15,
    borderRadius: '15px',
    margin: '0 5px',
    // color: tabColor,
    color: 'white',
    backgroundColor: tabColor,
    '&:hover': {
      backgroundColor: theme.hover,
      color: tabColor,
    },
    '&.Mui-selected': {
      border: 'solid 2px',
      borderColor: theme.palette.mode === 'dark' ? 'white' : 'black',
      backgroundColor:
        theme.palette.mode === 'dark'
          ? '#424242' //theme.palette.tertiary.darker
          : theme.palette.primary.lighter,
      color: tabColor,
    },
  }
})
export const StyledTeamTasksTabs = styled(Tabs)(({ theme }) => ({
  '& .MuiTabs-indicator': {
    display: 'none', // This hides the underline indicator
  },
}))

export const CustomTableContainer = styled(Table)(({ theme }) => ({
  boxShadow: 'none', // Or any other styles you want
  width: '100%',
}))

export const CustomTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'rgb(30 43 59)' : theme.palette.background.paper,
  width: '100%',
}))

export const CustomTypography = styled(Typography)({
  height: '63px',
})
export const CustomCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',

  padding: theme.spacing(2),
  margin: theme.spacing(1),
  marginBottom: theme.spacing(2),
  backgroundColor: theme.palette.card.default,
  borderRadius: '18px',
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
  width: '100%',
}))

// export const CustomCard = styled(Card)(({ theme }) => ({
//   // padding: theme.spacing(1),
//   // height: '300px',
//   // height: '100%',
//   // minHeight: '300px',
//   display: 'flex',
//   flexDirection: 'row',
//   paddingTop: '20px',
//   // boxSizing: 'border-box', // Include padding and border in the width and height
//   overflow: 'hidden', // Hide overflowed content
//   marginTop: '0px',
//   marginLeft: '10px',
//   marginRight: '10px',
//   marginBottom: '5px',

//   // border: '1px solid',
//   // borderColor:
//   //   theme.palette.mode === 'dark'
//   //     ? 'rgb(192, 192, 192, 0.4)'
//   //     : 'rgb(0,0,0,0.3)',
//   backgroundColor: theme.palette.card.default,
//   borderRadius: '18px',
// }))
export const CustomDependenciesCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(3),
  paddingTop: '0px',
  boxSizing: 'border-box', // Include padding and border in the width and height
  overflow: 'hidden', // Hide overflowed content
  marginTop: '10px',
  marginLeft: '10px',
  marginRight: '10px',
  marginBottom: '5px',

  // border: '1px solid',
  // borderColor:
  //   theme.palette.mode === 'dark'
  //     ? 'rgb(192, 192, 192, 0.4)'
  //     : 'rgb(0,0,0,0.3)',
  backgroundColor: theme.palette.card.default,
  borderRadius: '18px',
}))
export const CustomNcoHeader = styled(Typography)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? 'white' : 'black',
  textAlign: 'left',
  fontWeight: 'bold',
  fontSize: '18px',
  marginLeft: '10px',
  // marginBottom: '10px',
  // border: '1px solid',
  // borderColor:
  //   theme.palette.mode === 'dark'
  //     ? 'rgb(192, 192, 192, 0.4)'
  //     : 'rgb(0,0,0,0.3)',
}))

export const CustomDependencyTypography = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  // marginRight: '25px',
  marginBottom: '10px',
  marginTop: '10px',
  color: theme.palette.mode === 'dark' ? 'white' : 'black',
}))

export const CustomBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1E2735' : 'white',
  position: 'sticky',
  top: 0,
  zIndex: 185,
  marginTop: 70,
  // boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.2)',
  paddingLeft: '50px',
}))

export const CreateProjectTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'rgb(30 43 59)' : '#ffffff',
  textAlign: 'left',
  borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0,0,0,0.12)', // Dim white borde
  borderWidth: '1px',
  borderStyle: 'solid',
  borderRadius: '4px',

  '&:hover': {
    borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.32)' : 'rgba(0,0,0,0.22)',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.12)', // Dim white border
    },
    '&:hover fieldset': {
      borderWidth: '0px !important',
    },
    '&.Mui-focused fieldset': {
      borderWidth: '1px !important',
      borderColor: theme.palette.mode === 'dark' ? 'white' : 'black',
      // backgroundColor: theme.palette.mode === 'dark' ? 'red' : '#f0f0f0', // Change background color when focused
      // borderColor: 'white', // Change border color when focused
    },
  },
  '& .MuiOutlinedInput-root.Mui-focused': {
    // backgroundColor: theme.palette.mode === 'dark' ? 'red' : '#f0f0f0', // Change background color when focused
  },
  '& .MuiOutlinedInput-input': {
    '&:focus': {
      backgroundColor: theme.palette.mode === 'dark' ? 'rgb(40 53 69)' : '#f0f0f0', // Ensure the input itself also changes background color
    },
  },
}))
export const StyledSelect = styled(Select)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'rgb(30 43 59)' : '#ffffff',
  borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0,0,0,0.12)', // Dim white borde
  borderWidth: '1px',
  borderStyle: 'solid',
  borderRadius: '4px',
  height: '60px',
  '&:hover': {
    borderColor: theme.palette.mode === 'dark' ? 'white' : 'rgba(0,0,0,0.22)',
  },
  '& .MuiSelect-root': {
    '&:hover': {
      borderColor: theme.palette.mode === 'dark' ? 'green' : 'blue', // Change border color on hover based on theme
    },
    '&.Mui-focused': {
      borderColor: 'white', // Change border color when focused
    },
    // '&.services-select-cprojects .MuiSvgIcon-root': {
    //   display: 'block' /* Override the display property here */,
    // },
  },
}))
export const CustomListItemButton = styled(ListItemButton)(({ theme }) => ({
  '&:hover': {
    // borderColor: theme.palette.mode === 'dark' ? 'white' : 'rgba(0,0,0,0.22)',
    // backgroundColor:
    //   theme.palette.mode === 'dark' ? 'rgb(121, 126, 147)' : 'rgba(0,0,0,0.12)',
  },
}))
export const CustomThreadBox = styled(Box)(({ theme }) => ({
  borderColor: theme.palette.mode === 'dark' ? 'rgb(255,255,255,0.5)' : 'black',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderRadius: '12px',
}))
export const CustomCommentBox = styled(Box)(({ theme }) => ({
  borderColor: theme.palette.mode === 'dark' ? 'rgb(121, 126, 147)' : 'black',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderRadius: '8px',
}))
export const CustomCircularProgressBox = styled(Box)(({ theme, isGlobalUpdating }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'fixed', // Or 'absolute' depending on the use-case
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent overlay
  zIndex: 1000, // Ensure it's above everything else
  visibility: isGlobalUpdating ? 'visible' : 'hidden', // Only show when updating
  pointerEvents: 'none', // Allows interaction with elements below
}))
export const CustomFlexBox = styled(Box)(({ theme, isGlobalUpdating }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 15,
}))
export const StyledLoadingComponent = styled('div')(({ theme }) => ({
  alignItems: 'center',
  position: 'fixed', // Changed from 'absolute' to 'fixed'
  top: 0,
  left: 0,
  height: '100vh',
  width: '100vw',
  display: 'flex',
  // marginTop: '100px',
  justifyContent: 'center',
  zIndex: 1000, // Ensure it's above other content
  backgroundColor: '#1f2228',
}))
export const CustomPlaceholderAvatar = styled(Avatar)(({ theme }) => ({
  width: '35px',
  height: '35px',
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)',
  color: theme.palette.mode === 'dark' ? 'white' : 'black',
  fontSize: '1rem',
  display: 'flex', // Ensures content is flexibly positioned
  justifyContent: 'center', // Centers content horizontally
  alignItems: 'center', // Centers content vertically
  borderRadius: 25,
}))
export const CustomZohoAccountAvatar = styled(Avatar)({
  width: '35px',
  height: '35px',
  // backgroundColor: '#1976d2', // Example background color
  // color: '#ffffff', // Text color
  fontSize: '1rem', // Example font size
  display: 'flex', // Ensures content is flexibly positioned
  justifyContent: 'center', // Centers content horizontally
  alignItems: 'center', // Centers content vertically
})
export const CustomProjectDetailsAvatar = styled('img')(({ theme }) => ({
  width: '175px', // Fixed width for all images
  height: '175px', // Fixed height for all images
  objectFit: 'contain', // Contain the entire image within the box
  objectPosition: 'center center', // Center the image within the box
  backgroundColor: theme.palette.mode === 'dark' ? 'rgb(31, 34, 40)' : 'white', // Optional: a background color for when
  borderRadius: 25,
}))
export const HighlightedListItemButton = styled(ListItem)(({ theme, selected }) => ({
  width: '100%', // This ensures the item takes full width
  backgroundColor: selected
    ? theme.palette.mode === 'dark'
      ? 'blue' // Selected color in dark type
      : 'red' // Selected color in light type
    : 'transparent', // Normal background color
  '&:hover': {
    backgroundColor: theme.palette.mode === 'dark' ? 'red' : 'rgba(0,0,0,0.2)', // Hover background color
  },
  // Add other styling as needed
}))
export const CustomTaskGrid = styled(Grid)(({ user, selecteduserids }) => ({
  cursor: 'pointer',
  padding: '8px',
  boxShadow: selecteduserids.includes(user.gusers_uuid)
    ? '0px 4px 6px -1px rgba(0, 0, 0, 0.3), 0px 2px 4px -2px rgba(0, 0, 0, 0.8)'
    : 'none',
  backgroundColor: selecteduserids.includes(user.gusers_uuid)
    ? 'rgba(0,0,0,0.1)' // Slight darkening on selection
    : 'transparent',
  backgroundImage: selecteduserids.includes(user.gusers_uuid)
    ? 'linear-gradient(to bottom, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 70%)' // Glossy effect
    : 'none',
  transform: selecteduserids.includes(user.gusers_uuid) ? 'scale(1.02)' : 'none',
  '&:hover': {
    transition: 'transform 0.2s ease',
    transform: 'scale(1.02)',
    boxShadow: '0px 4px 6px -1px rgba(0, 0, 0, 0.3), 0px 2px 4px -2px rgba(0, 0, 0, 0.8)',
  },
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  transition: 'box-shadow 0.2s ease, background-color 0.2s ease', // Smooth transitions for shadow and background
}))
function customCheckbox(theme) {
  return {
    '& .MuiCheckbox-root svg': {
      width: 16,
      height: 16,
      backgroundColor: 'transparent',
      border: `1px solid ${theme.palette.mode === 'light' ? '#d9d9d9' : 'rgb(67, 67, 67)'}`,
      borderRadius: 2,
    },
    '& .MuiCheckbox-root svg path': {
      display: 'none',
    },
    '& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg': {
      backgroundColor: '#1890ff',
      borderColor: '#1890ff',
    },
    '& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after': {
      position: 'absolute',
      display: 'table',
      border: '2px solid #fff',
      borderTop: 0,
      borderLeft: 0,
      transform: 'rotate(45deg) translate(-50%,-50%)',
      opacity: 1,
      transition: 'all .2s cubic-bezier(.12,.4,.29,1.46) .1s',
      content: '""',
      top: '50%',
      left: '39%',
      width: 5.71428571,
      height: 9.14285714,
    },
    '& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after': {
      width: 8,
      height: 8,
      backgroundColor: '#1890ff',
      transform: 'none',
      top: '39%',
      border: 0,
    },
  }
}

export const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  border: 0,
  maxHeight: '100vh',
  maxWidth: '95vw',
  // boxShadow: '0px 4px 6px -1px rgba(0, 0, 0, 0.3), 0px 2px 4px -2px rgba(0, 0, 0, 0.8)',
  boxShadow: theme.shadows,
  color: theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.85)',
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  WebkitFontSmoothing: 'auto',
  letterSpacing: 'normal',
  '& .MuiDataGrid-columnsContainer': {
    backgroundColor: theme.palette.mode === 'light' ? '#fafafa' : '#1d1d1d',
  },
  // '& .MuiDataGrid-iconSeparator': {
  //   display: 'none',
  // },
  '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
    borderRight: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'}`,
  },
  '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
    borderBottom: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'}`,
  },
  '& .MuiDataGrid-cell': {
    // backgroundColor: theme.palette.mode === 'light' ? '#f9f8ff' : '#4a576d',
    backgroundColor: theme.palette.mode === 'light' ? '#f9f8ff' : 'rgba(30, 39, 53, 0.65)',
    color: theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.65)',
  },
  '& .MuiPaginationItem-root': {
    borderRadius: 0,
  },
  // Existing styles...
  // height: '100%', // Ensure the DataGrid fills its parent's height
  // width: '100%', // Ensure the DataGrid fills its parent's width
  // '& .MuiDataGrid-root': {
  //   height: '100%',
  //   width: '100%',
  // },
  ...customCheckbox(theme),
}))
export const StyledTaskCell = styled(TableCell)(({ theme }) => ({
  // width: '35px',
  // height: '35px',
  // backgroundColor:
  //   theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)',
  color: theme.palette.mode === 'dark' ? 'white' : 'black',
}))
export const StyledTaskHeader = styled(TableCell)(({ theme }) => ({
  // width: '35px',
  // height: '35px',
  // backgroundColor:
  //   theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)',
  textAlign: 'left',
  color: theme.palette.mode === 'dark' ? 'white' : 'black',
}))
export const StyledTab = styled(Tab)(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: '16px',
  color: theme.palette.mode === 'dark' ? 'rgba(255,255,255, 1.5)' : '#405466',
  '&.Mui-selected': {
    color: 'rgb(121, 212, 255) !important', // Add !important here
  },
}))
export const LightTooltip = styled(Tooltip)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  color: 'rgba(0, 0, 0, 0.87)',
  boxShadow: theme.shadows[1],
  fontSize: 11,
}))
export const StyledCreativeTasksTab = styled(props => <Tab {...props} />)(({ label, theme }) => {
  // Define the background color based on the theme mode
  // console.log('Theme in StyledTab: ', theme)

  const tabColor = getCreativeTasksTabColor(label)

  return {
    backgroundColor: 'transparent',
    // theme.palette.mode === 'dark' ? 'transparent' : 'rgb(255, 255, 240)',
    // border: `1px solid ${theme.palette.primary.main}`,
    height: 30,
    // minHeight: 15,
    borderRadius: '15px',
    margin: '0 5px',
    // color: tabColor,
    color: 'white',
    backgroundColor: tabColor,
    '&:hover': {
      backgroundColor: theme.hover,
      color: tabColor,
    },
    '&.Mui-selected': {
      border: 'solid 2px',
      borderColor: theme.palette.mode === 'dark' ? 'white' : 'black',
      backgroundColor:
        theme.palette.mode === 'dark'
          ? '#424242' //theme.palette.tertiary.darker
          : theme.palette.primary.lighter,
      color: tabColor,
    },
  }
})
export const StyledCreativeTasksTabs = styled(Tabs)(({ theme }) => ({
  '& .MuiTabs-indicator': {
    display: 'none', // This hides the underline indicator
  },
}))
export const GroupHeader = styled(Box)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  padding: '4px 10px',
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === 'light'
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
  zIndex: 2,
}))

export const GroupItems = styled(List)({
  padding: 0,
  listStyle: 'none',
})

export const RowGridContainer = styled(Grid)(({ theme }) => ({
  margin: '0 auto',
  marginTop: '5px',
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(30, 39, 53, 0.2)' : 'rgb(0, 0, 0, 0.1)', // Default row color, // Apply background color based on selection
  borderRadius: '15px',
  padding: '10px',
  '& > .MuiGrid-item': {
    padding: '10px',
  },
}))
export const RowBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
}))
export const CustomKanBan = styled(Board)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'red' : '#a8a8a8', // Default row color, // Apply background color based on selection
}))
export const BoxScroller = styled(Box)(({ theme }) => ({
  overflowY: 'scroll',
  '&::-webkit-scrollbar': {
    width: '6px',
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: theme.palette.mode === 'dark' ? 'inset 0 0 6px rgba(0,0,0,0.3)' : 'inset 0 0 6px rgba(255,255,255,0.3)',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(167,51,51,1)' : 'rgba(0,0,0,0.5)',
    borderRadius: '6px',
  },
}))
export const AutocompleteButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.5)' : 'rgba(0,0,0,0.1)',
  color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.5)' : 'rgba(0,0,0)',
  width: '100%',
  '&:hover': {
    backgroundColor: theme.palette.mode === 'dark' ? 'rgb(30, 39, 53)' : 'red',
  },
}))
export const CreateDealText = styled(TextField)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'rgb(30 43 59)' : '#ffffff',
  textAlign: 'left',
  borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0,0,0,0.12)', // Dim white borde
  borderWidth: '1px',
  borderStyle: 'solid',
  borderRadius: '4px',
  '&:hover': {
    borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.32)' : 'rgba(0,0,0,0.22)',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.12)', // Dim white border
    },
    '&:hover fieldset': {
      borderWidth: '0px !important',
    },
    '&.Mui-focused fieldset': {
      borderWidth: '0px !important',
      // borderColor: 'white', // Change border color when focused
    },
  },
  color: 'purple',
}))
export const GridStatusChip = styled(Chip)(({ theme, type }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? 'rgb(30, 39, 53)' : '#ffffff',
  backgroundColor: 'transparent',
  textAlign: 'left',
  // borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0,0,0,0.12)', // Dim white borde
  // borderWidth: '1px',
  // borderStyle: 'solid',
  color: renderTypeColor(type, theme),
  fontWeight: 'bold',
  width: '100%',
  height: '100%',
  borderRadius: '4px',
  // '&:hover': {
  //   borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.32)' : 'rgba(0,0,0,0.22)',
  // },
  // color: theme.palette.mode === 'dark' ? 'black' : 'black',
}))

export const NcoStatusContainer = styled(Box)(({ type }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? 'rgb(30, 39, 53)' : '#ffffff',
  bgcolor: renderTypeColor(type),
  textAlign: 'left',
  // borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0,0,0,0.12)', // Dim white borde
  // borderWidth: '1px',
  // borderStyle: 'solid',
  fontWeight: 'bold',
  width: '100%',
  height: '100%',
  // borderRadius: '4px',
  // '&:hover': {
  //   borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.32)' : 'rgba(0,0,0,0.22)',
  // },
  // color: theme.palette.mode === 'dark' ? 'black' : 'black',
}))
export const DealDetailsStatusChip = styled(Chip)(({ theme, status }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? 'rgb(30, 39, 53)' : '#ffffff',
  backgroundColor: 'transparent',
  textAlign: 'left',
  // borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0,0,0,0.12)', // Dim white borde
  // borderWidth: '1px',
  // borderStyle: 'solid',
  color: theme.palette.text.chip,
  backgroundColor: renderDealStatusColor(status),
  fontWeight: 'bold',
  minHeight: 25,
  minWidth: 120, // Set a fixed width for all chips
  width: '100%',
  height: '100%',
  borderRadius: '4px',
  // '&:hover': {
  //   borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.32)' : 'rgba(0,0,0,0.22)',
  // },
  // color: theme.palette.mode === 'dark' ? 'black' : 'black',
}))
export const GridStageChip = styled(Chip)(({ theme, stage }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? 'rgb(30 43 59)' : '#ffffff',
  backgroundColor: theme.palette.mode === 'dark' ? 'transparent' : 'transparent',
  textAlign: 'left',
  borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0,0,0,0.12)', // Dim white borde
  // borderWidth: '1px',
  // borderStyle: 'solid',

  color: setStageStyle(stage),
  fontWeight: 'bold',
  height: '100%',
  width: '100%',
  borderRadius: '4px',
  '&:hover': {
    borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.32)' : 'rgba(0,0,0,0.22)',
  },
  // color: theme.palette.mode === 'dark' ? 'black' : 'black',
}))
