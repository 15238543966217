import React, { useState, useEffect, useRef } from 'react'
import { Box, Button, Grid } from '@mui/material'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { useTheme } from '@mui/material/styles'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import useTotals from '../../api/customHooks/useTotals'
import ContractTotals from '../ContractDialog/ContractTotals'
import { useCreateDocument } from '../../api/PandaDoc/useCreateDocument'
import { useUpdateDeal } from '../../api/aws/useUpdateDeal'
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'
import EditDealInfo from './EditDealInfo'
import { useOrganizationById } from '../../api/aws/useOrganization'
import { useActiveUser } from '../../api/slack/useActiveUser'
import DetailsLoading from '../Loading/DetailsLoading'
import { useDealDetailsContext } from '../../context/DealsDetailsProvider/DealsDetailsProvider'
import { useParams } from 'react-router-dom'
import ContractInfoHeader from '../ContractDialog/ContractInfoHeader'
import ProductRowsTable from '../ProductRows/ProductRowsTable'
import { calculateDayjsTotalMonths } from '../../utility/calculateTotalMonths'
import { updateContractTimelineData } from '../Timeline/createTimelineData'
import { useCreateTimeline } from '../../api/aws/useCreateTimeline'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advancedFormat)
dayjs.extend(isSameOrBefore)

const RenderContract = ({
  onSaveDeal,
  onClose,
  open,
  onUpdate,
  onRequestApproval,
  scrollRef,
  onSaveModal,
  setShowModal,
  setTempDealStatus,
}) => {
  const theme = useTheme()
  const { dealId } = useParams()
  const {
    newDeal,
    setNewDeal,
    validateFields,
    validationErrors,
    productRows,
    setProductRows,
    computeTotals,
    isValid,
    setIsValid,
    addRow,
    removeRow,
    handleProductChange,
    handleChange,
    originalApprovalState,
    approvalState,
    setApprovalState,
    onDragEnd,
    strategyInfo,
    timelineData,
  } = useDealDetailsContext()
  const { organization, isOrganizationLoading, isOrganizationError } = useOrganizationById(
    newDeal && Array.isArray(newDeal.organizations) ? newDeal.organizations[0] : null
  )
  // const [timelineData, setTimelineData] = useState({})
  const { activeUser } = useActiveUser()
  const { mutate: createTimelineEntry } = useCreateTimeline()
  const [isCreating, setIsCreating] = useState(false)
  const [isError, setIsError] = useState(false)
  const totals = useTotals(productRows, newDeal?.implementationFee || 0, newDeal.type)
  const { totalMonths, allSame } = newDeal ? calculateDayjsTotalMonths(newDeal) : { totalsMonths: 1 }
  const { mutate: createDocument } = useCreateDocument()
  const { mutate: updateDeal } = useUpdateDeal()
  const { showSnackbar } = useSnackbarContext()
  const {
    organization: contractedBy,
    isOrganizationLoading: isContractedByLoading,
    isOrganizationError: isContractedByError,
  } = useOrganizationById(organization && Array.isArray(organization.contractedBy) ? organization.contractedBy[0] : [])

  useEffect(() => {
    if (open) {
      const isValid = validateFields(newDeal)
      setIsValid(isValid)
    }
  }, [open, newDeal])
  const handleSave = async e => {
    e.preventDefault()
    setIsCreating(true)
    const hasProducts = newDeal && Array.isArray(newDeal.products) && newDeal.products.length > 0
    const updatedDeal = {
      ...newDeal,
      ...totals,
      totals: { ...totals },
      hasProducts: hasProducts,
      products: productRows,
      updatedProducts: productRows,
    }
    try {
      await onSaveDeal(updatedDeal, organization, hasProducts)
      setIsCreating(false)
    } catch (error) {
      setIsCreating(false)
      console.error(error)
    }
  }
  useEffect(() => {
    if (newDeal && newDeal?.products && newDeal?.products?.length > 0) {
      console.log('newDeal?.products', newDeal?.products)
    }
  }, [newDeal])

  const submitContract = (cleanedDeal, organization, activeUser) => {
    try {
      const documentData = {
        deal: cleanedDeal,
        organization,
        activeUser,
      }
      const timelineData = updateContractTimelineData(
        cleanedDeal?.deal_uuid,
        activeUser,
        'Create',
        'deals',
        'Contract created'
      )

      createDocument(documentData, {
        onSuccess: data => {
          const hasProducts = data && Array.isArray(data.products) && data.products.length > 0
          const contractId = data.id
          const dealWithContractId = {
            ...cleanedDeal,
            contractId: contractId,
            status: 'Created',
            stage: 'Contract Created',
            lastModified: new Date(),
            ...totals,
            hasProducts: hasProducts,
            products: productRows,
            totals: { ...totals },
          }
          updateDeal(
            {
              dealId: dealWithContractId.deal_uuid,
              dealData: dealWithContractId,
            },
            {
              onSuccess: () => {
                setIsCreating(false)
                createTimelineEntry(timelineData)
                onClose()
              },
              onError: error => {
                console.error('Error in updateDeal:', error)
                setIsCreating(false)
              },
            }
          )
          showSnackbar('Document successfully created', 'success')
        },
        onError: error => {
          console.error('Error in createDocument:', error)
          setIsCreating(false)
          setIsError(true)
        },
      })
    } catch (error) {
      console.error('Error in submitContract try-catch:', error)
      setIsCreating(false)
      setIsError(true)
    }
  }

  const handleSubmit = event => {
    event.preventDefault()
    setIsCreating(true)
    try {
      submitContract({ ...newDeal, creation_timestamp: new Date(), isCreated: true }, organization, activeUser)
    } catch (error) {
      console.error('Error in handleSubmit:', error)
      setIsCreating(false)
      setIsError(true)
    }
    setIsCreating(false)
  }

  const onChange = (fieldName, value) => {
    handleChange(fieldName, value, organization)
  }
  const isLoading = isOrganizationLoading || isContractedByLoading
  if (isLoading) {
    return <DetailsLoading />
  }
  return (
    <Grid container>
      <Box
        sx={{
          position: 'sticky',
          top: 0,
          zIndex: 10,
          bgcolor: theme.palette.background.main,
          p: 2,
        }}
      >
        <Grid item xs={12} sm={7} md={12}>
          <EditDealInfo
            key={dealId}
            organization={organization}
            approvalState={approvalState}
            contractedBy={contractedBy}
            onUpdate={onUpdate}
            isCreating={isCreating}
            onClose={onClose}
            onSave={handleSave}
            isValid={isValid}
            onCreate={handleSubmit}
            validationErrors={validationErrors}
            onRequestApproval={onRequestApproval}
            onChange={handleChange}
            onSaveModal={onSaveModal}
            setShowModal={setShowModal}
            setTempDealStatus={setTempDealStatus}
          />
        </Grid>
        <Grid item xs={12} sm={7} md={12}>
          <ContractInfoHeader handleChange={onChange} validationErrors={validationErrors} organization={organization} />
        </Grid>
      </Box>

      <Grid item xs={12} p={2}>
        <ProductRowsTable
          newDeal={newDeal}
          productRows={productRows}
          onProductChange={handleProductChange}
          validationErrors={validationErrors}
          removeRow={removeRow}
          type={newDeal ? newDeal.type : ''}
          onDragEnd={onDragEnd}
        />
      </Grid>
      <Box
        sx={{
          position: 'sticky',
          bottom: 0,
          zIndex: 12,
          bgcolor: theme.palette.background.main,
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignContent: 'center',
        }}
      >
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            sx={{
              marginBottom: 4,
              '&:hover': { opacity: '80%' },
            }}
            startIcon={<AddCircleOutlineIcon />}
            onClick={() => addRow(scrollRef)}
          >
            Add Service
          </Button>
        </Grid>
        <Grid item xs={12} pb={2}>
          <ContractTotals newDeal={newDeal} setNewDeal={setNewDeal} totals={totals} onChange={handleChange} />
        </Grid>
      </Box>
    </Grid>
  )
}

export default RenderContract
