import React, { createContext, useState, useContext, useCallback, useEffect } from 'react'
import { useFetchNotifications } from '../../api/aws/useFetchNotifications'
import { useCreateNotification } from '../../api/aws/useCreateNotification'
import { useActiveUser } from '../../api/slack/useActiveUser'

const NotificationContext = createContext()

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([])
  const { activeUser } = useActiveUser()
  const { user } = activeUser
  const [unreadCount, setUnreadCount] = useState(0)
  const { notifications: fetchedNotifications, isNotificationsLoading, isNotificationsError } = useFetchNotifications()
  const { mutate: createNotification } = useCreateNotification()
  // Load notifications initially
  useEffect(() => {
    if (fetchedNotifications && !isNotificationsLoading) {
      // setNotifications(fetchedNotifications)
      setNotifications(fetchedNotifications.filter(notify => notify.userId === user?.id))
      // updateUnreadCount(fetchedNotifications)
    }
  }, [fetchedNotifications])
  useEffect(() => {
    if (notifications) {
      // console.log('notifications', notifications)
      updateUnreadCount(notifications)
    }
  }, [notifications])

  // Update unread count
  const updateUnreadCount = useCallback(
    (data = notifications) => {
      const unread = data.filter(n => !n.isRead).length
      setUnreadCount(unread)
    },
    [notifications]
  )

  // Mark specific notifications as read
  const markAsRead = useCallback(
    notify_uuid => {
      setNotifications(prev =>
        prev.map(notification =>
          notify_uuid.includes(notification.notify_uuid) ? { ...notification, isRead: true } : notification
        )
      )
      updateUnreadCount()
    },
    [updateUnreadCount]
  )

  // Add a new notification
  const addNotification = useCallback(
    notification => {
      createNotification(notification, {
        onSuccess: data => {
          setNotifications(prev => [...prev, data])
          updateUnreadCount()
        },
        onError: error => {
          console.error('Error creating notification:', error)
        },
      })
    },
    [createNotification, updateUnreadCount]
  )
  /**
   *
   * @param {Array} userIds
   * @param {string} type
   * @param {string} severity
   * @param {string} title
   * @param {string} message
   * @returns
   */
  const createMessagePayload = (
    userIds,
    type,
    severity,
    title,
    message,
    board,
    boardItemName,
    boardId,
    imageUrl,
    url
  ) => {
    console.log('userIds', userIds, 'type', type, 'title', title, 'message', message)
    if (userIds?.length < 1) {
      console.log('userIds less than 1')
      return
    }
    return userIds.map(id => {
      return {
        userId: id,
        type: type,
        severity: severity,
        title: title,
        message: message,
        board: board || '',
        boardItemName: boardItemName,
        boardId: boardId,
        imageUrl: imageUrl,
        url: url || '',
      }
    })
  }

  const value = {
    notifications,
    unreadCount,
    markAsRead,
    addNotification,
    isNotificationsLoading,
    isNotificationsError,
    createMessagePayload,
  }

  return <NotificationContext.Provider value={value}>{children}</NotificationContext.Provider>
}

export const useNotify = () => {
  const context = useContext(NotificationContext)
  if (!context) {
    throw new Error('useNotify must be used within a NotificationProvider')
  }
  return context
}
