import React from 'react'
import { useSlackUserProfile } from '../../api/slack/useSlackUserProfile'
import { Box, Typography, Stack, useTheme, Grid } from '@mui/material'
import { StyledGridTypography } from '../../style/styleElements'
import SlackLoading from '../Loading/SlackLoading'
import Loading from '../Loading/Loading'
import { useUsers } from '../../api/aws/useUsers'

const SlackUserList = () => {
  const {
    data: slackUserProfile,
    isLoading: slackUserProfileLoading,
    isError: slackUserProfileError,
  } = useSlackUserProfile()
  const { users, isUsersLoading, isUsersError } = useUsers()
  console.log('users: ', users)
  const theme = useTheme()

  if (slackUserProfileLoading || isUsersLoading) return <SlackLoading />
  if (slackUserProfileError || isUsersError) return <div>Error loading Slack user data</div>

  console.log('[SlackUserList]', 'Slack Users: ', users, 'SlackUserProfile: ', slackUserProfile)
  const renderUser = (user, label, size) => (
    <Grid
      item
      xs={size ?? 1.5}
      key={user.id}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 1,
        margin: 1,
        border: '1px solid #ccc',
        borderRadius: '8px',
        // width: 'fit-content',
        width: '100%',
      }}
    >
      <img
        src={user?.image_48 || user?.profile?.image_48 || ''}
        alt='avatar'
        style={{ width: 48, height: 48, borderRadius: '50%' }}
      />
      <StyledGridTypography>
        {label}: {user?.first_name || user?.profile?.first_name || ''}
      </StyledGridTypography>
      <StyledGridTypography>Display Name: {user.name || user?.profile?.name || ''}</StyledGridTypography>
      <StyledGridTypography>Admin: {user.is_admin ? 'Yes' : 'No'}</StyledGridTypography>
      <StyledGridTypography>Bot: {user.is_bot ? 'Yes' : 'No'}</StyledGridTypography>
    </Grid>
  )

  return (
    <Grid
      container
      sx={{
        display: 'flex',
        // flexDirection: 'column',
        width: '95vw',
        height: 'calc(100vh - 10px)',
        overflowY: 'auto',
        mr: 3,
        ml: 2,
        border: '1px solid',
        borderColor: theme.palette.text.secondary,
      }}
    >
      <Grid item xs={12}>
        <Typography sx={{ textAlign: 'center' }}>{`${users?.length} Total Users`}</Typography>
      </Grid>
      <Grid item xs={12} sx={{ width: '100%' }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
          {slackUserProfile && renderUser(slackUserProfile, 'Current Logged In User', 5)}
        </Box>
      </Grid>
      <Grid item xs={12} sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', p: 2 }}>
        {users && users.map(user => renderUser(user, 'User'))}
      </Grid>
    </Grid>
  )
}

export default SlackUserList
