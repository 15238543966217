// src/AppLayout.js
import React, { useState, useContext, useEffect } from 'react'
import { Box, AppBar, Toolbar, useTheme, useMediaQuery, SpeedDial, SpeedDialAction } from '@mui/material'
import { styled } from '@mui/material/styles'
import { ContainerProvider } from './context/ContainerProvider/ContainerProvider'
import CreateOrganization from './components/CreateOrganization/CreateOrganization'
import CreateDeal from './components/CreateDeal/CreateDeal'
import CreateContact from './components/CreateContact/CreateContact'
import MiniDrawer from './components/AppSidebar/MiniDrawer'
import { useLocation } from 'react-router-dom'
import headerMap from './components/Headers/headerMap'
import { HeaderContext } from './context/HeaderProvider/HeaderProvider'
import { useActiveUser } from './api/slack/useActiveUser'
import { DealDetailsProvider } from './context/DealsDetailsProvider/DealsDetailsProvider'
import { initialDealState } from './context/DealsDetailsProvider/initialDealState'
import { useUsers } from './api/aws/useUsers'
import SpeedDialComponent from './components/SpeedDial/SpeedDial'
import { NotificationProvider } from './context/NotificationProvider/NotificationProvider'

const MainContent = styled('main', {
  shouldForwardProp: prop => !['open'].includes(prop),
})(({ theme, open }) => ({
  flexGrow: 1,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  // overflowY: 'hidden',
  marginLeft: 0,
  ...(open && {
    marginLeft: 20,
    marginRight: 20,
  }),
  // marginTop: theme.spacing(9),
}))

const AppLayout = ({ children }) => {
  const { activeUser, isActiveUserLoading } = useActiveUser()
  const { user } = activeUser
  const { users, isUsersLoading, isUsersError } = useUsers()
  const [currentUser, setCurrentUser] = useState(null)
  const [isCurrentUserLoading, setIsCurrentUserLoading] = useState(isActiveUserLoading)
  const [isCreateOrganizationOpen, setIsCreateOrganizationOpen] = useState(false)
  const [isCreateDealOpen, setIsCreateDealOpen] = useState(false)
  const [isCreateContactOpen, setIsCreateContactOpen] = useState(false)
  const [isNotifyDrawerOpen, setIsNotifyDrawerOpen] = useState(false)
  const [isConnectedUsersOpen, setIsConnectedUsersOpen] = React.useState(false)

  const handleOpenCreateOrganization = () => setIsCreateOrganizationOpen(true)
  const handleCloseCreateOrganization = () => setIsCreateOrganizationOpen(false)

  const handleOpenCreateDeal = () => setIsCreateDealOpen(true)
  const handleCloseCreateDeal = () => setIsCreateDealOpen(false)

  const handleOpenCreateContact = () => setIsCreateContactOpen(true)
  const handleCloseCreateContact = () => setIsCreateContactOpen(false)

  const handleOpenNotifyDrawer = () => setIsNotifyDrawerOpen(true)
  const handleCloseNotifyDrawer = () => setIsNotifyDrawerOpen(false)

  const handleOpenConnectedUsers = () => setIsConnectedUsersOpen(true)
  const handleCloseConnectedUsers = () => setIsConnectedUsersOpen(false)

  // const theme = useTheme()
  const theme = useTheme()

  useEffect(() => {
    if (users && !isUsersLoading) {
      setCurrentUser(users.filter(currUser => currUser.user_uuid === user?.id))
    }
  }, [users])
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100vw',
        overflow: 'hidden', // Prevent scrolling the entire layout
        backgroundColor: theme.palette.mode === 'dark' ? 'rgb(26, 22, 22)' : 'rgb(232, 246, 252)',
      }}
    >
      <NotificationProvider>
        <DealDetailsProvider dealData={initialDealState}>
          <MiniDrawer
            handleOpenCreateOrganization={handleOpenCreateOrganization}
            handleOpenCreateDeal={handleOpenCreateDeal}
            handleOpenCreateContact={handleOpenCreateContact}
            isNotifyDrawerOpen={isNotifyDrawerOpen}
            onOpenNotify={handleOpenNotifyDrawer}
            onCloseNotify={handleCloseNotifyDrawer}
            isConnectedUsersOpen={isConnectedUsersOpen}
            handleOpenConnectedUsers={handleOpenConnectedUsers}
            handleCloseConnectedUsers={handleCloseConnectedUsers}
          />
          <MainContent>
            {children}
            <CreateOrganization open={isCreateOrganizationOpen} onClose={handleCloseCreateOrganization} />
            <CreateDeal open={isCreateDealOpen} onClose={handleCloseCreateDeal} />
            <CreateContact open={isCreateContactOpen} onClose={handleCloseCreateContact} />
            <SpeedDialComponent currentUser={currentUser} isCurrentUserLoading={isCurrentUserLoading} />
          </MainContent>
        </DealDetailsProvider>
      </NotificationProvider>
    </Box>
  )
}

export default AppLayout
