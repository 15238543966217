import React, { useState } from 'react'
import { Box, Select, MenuItem, Chip, Typography, useTheme } from '@mui/material'
import { renderTypeColor } from '../DataGrid/createColumns/renderDealsColumns'

const EditSelectCell = ({ params, options }) => {
  const [open, setOpen] = useState(true) // Keep dropdown open initially
  const theme = useTheme()
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <Select
      autoFocus
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      value={params.value || ''}
      onChange={e => {
        params.api.setEditCellValue({
          id: params.id,
          field: params.field,
          value: e.target.value,
        })
        handleClose() // Close dropdown after selection
      }}
      fullWidth
    >
      {options.map(option => (
        <MenuItem key={option.value} value={option.value} sx={{ bgcolor: renderTypeColor(option.value, theme) }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}
          >
            {/* <Chip size='small' sx={{ height: 15, width: 15, bgcolor: renderTypeColor(option.value, theme) }} /> */}
            <Typography>{option.label}</Typography>
          </Box>
        </MenuItem>
      ))}
    </Select>
  )
}
export default EditSelectCell
