import React, { useState, useEffect } from 'react'
import { Typography, Chip, FormControl, Select, MenuItem, useTheme } from '@mui/material'
export const dealStatusOptions = [
  {
    label: 'Month To Month',
    color: 'rgb(22, 138, 239)',
  },
  {
    label: 'Active',
    color: 'rgb(80, 200, 120)',
  },
  {
    label: '30 Day Notice',
    color: 'rgb(255, 123, 208)',
  },
  { label: 'Cancelled', color: 'rgb(0,0,0)' },
  {
    label: 'Expired',
    color: 'rgba(220, 20, 60, 0.8)',
  },
]

const DealStatusSelect = ({
  newDeal,
  setNewDeal,
  setIsDealStatusChange,
  onSaveModal,
  setShowModal,
  setTempDealStatus,
}) => {
  const theme = useTheme()
  const [selectedValue, setSelectedValue] = useState('')
  const [lastDateOfService, setLastDateOfService] = useState('')
  const [reason, setReason] = useState('')
  const [error, setError] = useState(false)

  useEffect(() => {
    if (newDeal.dealStatus) {
      console.log('[DealStatusSelect] newDeal.dealStatus: ', newDeal.dealStatus)
      setSelectedValue(newDeal.dealStatus || 'Select Status')
    }
  }, [newDeal.dealStatus])
  // const handleChange = event => {
  //   const newValue = event.target.value
  //   setSelectedValue(newValue)
  //   // onChange(newValue)
  //   setIsDealStatusChange(true)
  //   setNewDeal(prev => ({
  //     ...prev,
  //     dealStatus: newValue,
  //   }))
  // }
  const handleChange = event => {
    const newValue = event.target.value

    const requiresModal = ['30 Day Notice', 'Cancelled', 'Expired'].includes(newValue)

    if (requiresModal) {
      setShowModal(true) // Open the modal
      setTempDealStatus(newValue) // Temporarily hold the status
      setIsDealStatusChange(true)
    } else {
      setSelectedValue(newValue)
      setNewDeal(prev => ({
        ...prev,
        dealStatus: newValue,
      }))
    }
  }

  const handleSave = () => {
    if (!lastDateOfService || !reason) {
      setError(true) // Show an error if fields are empty
      return
    }

    setError(false)
    onSaveModal(lastDateOfService, reason) // Pass data to parent
  }

  return (
    <Select
      id='status-select'
      value={selectedValue || ''}
      onChange={handleChange}
      name='status'
      required
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
      }}
    >
      {dealStatusOptions.map(option => (
        <MenuItem key={option.label} value={option.label}>
          <Chip
            size='small'
            style={{
              backgroundColor: option.color,
              height: 14,
              width: 14,
              marginRight: 8,
              borderRadius: '50%',
            }}
          />
          <Typography variant='subtitle1'>{option.label}</Typography>
        </MenuItem>
      ))}
    </Select>
  )
}

export default DealStatusSelect
