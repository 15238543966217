import React, { useState, useEffect } from 'react'
import { Box, Chip, Typography, useTheme } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

/**
 *
 * @param {array} options array of options to be displayed in list
 * @param {function} setterFn set function for value
 * @param {string} label initial value for select label
 * @param {function} onChange custom handleChange to be passed to select component
 * @param {string} value value passed for currently selected stage
 * @param {string} context pass a context value to tell the function what type of filter it is applying
 * @returns
 */

const renderContextLabel = context => {
  switch (context) {
    case 'status':
      return 'Status'
    case 'ncoStatus':
      return 'NCO Status'
    case 'stage':
    case 'dealStage':
      return 'Stage'
    case 'type':
      return 'Type'
    case 'billingStatus':
      return 'Billing Status'
    default:
      return context
  }
}
const CustomStatusFilter = ({ options, setterFn, filterModel, value, onChange, context }) => {
  const theme = useTheme()
  const [status, setStatus] = useState('')

  useEffect(() => {
    if (filterModel) {
      const currentFilter = filterModel.items.find(item => item.field === 'status')

      if (currentFilter && currentFilter.value) {
        setterFn(currentFilter.value) // Correctly set the status value from filterModel
      }
    }
  }, [filterModel])

  const handleStatusChange = event => {
    const newStatus = event.target.value
    const selectedStatus = options.find(status => status.label === newStatus)
    setStatus(selectedStatus ? selectedStatus.label : '')
    setterFn(selectedStatus ? selectedStatus.label : '')
    if (onChange) {
      onChange(context, selectedStatus ? selectedStatus.label : '')
    }
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minWidth: 200 }}>
      <FormControl sx={{ display: 'flex', height: 70 }} fullWidth>
        <Select
          sx={{ height: 30, pt: 2.5, pb: 2.5, borderRadius: '4px' }}
          name={context ? context : ''}
          id='status-select'
          value={value || ''} // Use status state for selected value
          onChange={handleStatusChange}
          displayEmpty
          renderValue={selectedStatus => {
            const statusDetail = options.find(status => status.label === selectedStatus)
            return (
              <Box display='flex' alignItems='center' gap={1}>
                {statusDetail ? (
                  <Chip
                    size='small'
                    style={{
                      backgroundColor: statusDetail.color,
                      width: 14,
                      height: 14,
                      borderRadius: '50%',
                    }}
                  />
                ) : (
                  <Typography
                    sx={{ color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.4)' : 'rgba(0,0,0,0.4)' }}
                    variant='subtitle1'
                  >
                    {renderContextLabel(context) || 'Filter Status'}
                  </Typography>
                )}
                <Typography variant='subtitle1'>{statusDetail ? statusDetail.label : ''}</Typography>
              </Box>
            )
          }}
        >
          {options.map(status => (
            <MenuItem key={status.label} value={status.label}>
              <Chip
                size='small'
                style={{
                  backgroundColor: status.color,
                  width: 14,
                  height: 14,
                  borderRadius: '50%',
                  margin: '5px',
                }}
              />
              <Typography variant='subtitle1' ml={1}>
                {status.label}
              </Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}

export default CustomStatusFilter
