import React, { useState, useEffect } from 'react'
import { AvatarGroup, Avatar, Box, useTheme } from '@mui/material'
import { useUsers } from '../../api/aws/useUsers'

const RenderGridTeamMembers = ({ organization, densityStyles }) => {
  const theme = useTheme()
  const additionalAvatarBgColor = theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.2)' : 'rgba(0,0,0,0.2)'
  const { users, isUsersLoading, isUsersError } = useUsers()
  const [teamMembers, setTeamMembers] = useState([])
  const userImageStyles = {
    width: '50px', // Set a fixed width
    height: '50px', // Set a fixed height
    borderRadius: '50%', // Make the image circular
    objectFit: 'cover', // Ensure the image covers the box while maintaining aspect ratio
  }
  useEffect(() => {
    if (organization && users.length > 0) {
      // Extract all team member IDs from the organization
      const roles = ['AM', 'PM', 'DMS', 'SEO', 'BD']
      const teamMemberIds = roles.flatMap(role => organization[role] || [])
      // Remove duplicate IDs
      const uniqueTeamMemberIds = [...new Set(teamMemberIds)]

      // Find corresponding user information for each team member ID
      const teamMembersData = uniqueTeamMemberIds.map(userId => users.find(user => user.id === userId)).filter(Boolean) // Remove undefined values if any ID doesn't match a user

      setTeamMembers(teamMembersData)
    }
  }, [organization, users])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        alignItems: 'center',
        height: '100%',
        padding: densityStyles.padding,
        gap: densityStyles.gap,
      }}
    >
      <AvatarGroup
        max={4}
        spacing={'medium'}
        slotProps={{
          additionalAvatar: {
            sx: {
              height: densityStyles.avatarSize,
              width: densityStyles.avatarSize,
              fontSize: densityStyles.fontSize,
              backgroundColor: additionalAvatarBgColor,
            },
          },
        }}
      >
        {teamMembers.map(member => (
          <Avatar
            key={member.id}
            alt={member.real_name}
            src={member.image_512}
            sx={{ height: densityStyles.avatarSize, width: densityStyles.avatarSize }}
          />
        ))}
      </AvatarGroup>
    </Box>
  )
}

export default RenderGridTeamMembers
