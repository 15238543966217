import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'
import { baseUrl } from '../queryMutations/queryMutations'

const createNotification = async notificationData => {
  const url = `${baseUrl}/aws/notifications`
  console.log('New Notification Data: ', notificationData)
  const response = await axios.post(url, notificationData)
  return response.data // Assumes the response data includes the new lead with `id`
}

export const useCreateNotification = () => {
  const { showSnackbar } = useSnackbarContext()
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: createNotification,
    onMutate: async newNotification => {
      await queryClient.cancelQueries(['notifications'])

      // Snapshot the previous value
      const previousNotifications = queryClient.getQueryData(['notifications'])

      // Do not optimistically update here because we don't have the new `id` yet
      return { previousNotifications }
    },
    onError: (err, newNotification, context) => {
      queryClient.setQueryData(['notifications'], context.previousNotifications)
      console.error('Mutation Error:', err)
      showSnackbar(err.response?.data?.message || err.message, 'error')
    },
    onSuccess: data => {
      queryClient.setQueryData(['notifications'], old => {
        if (!old) {
          return { data: [{ ...data, id: data.notify_uuid }], total: 1 }
        }
        if (Array.isArray(old.data)) {
          return { ...old, data: [...old.data, { ...data, id: data.notify_uuid }], total: old.total + 1 }
        }
        console.error('Unexpected query data format:', old)
        return { data: [{ ...data, id: data.notify_uuid }], total: 1 }
      })
      showSnackbar('Notification created', 'info')
    },
    onSettled: () => {
      queryClient.invalidateQueries(['notifications'])
    },
  })

  return mutation
}
