import React, { useContext, useEffect, useState } from 'react'
import { Box, Grid, Typography, useTheme } from '@mui/material'
import Loading from '../Loading/Loading'
import { useParams } from 'react-router-dom'
import ContactInfoCard from './ContactInfoCard'
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'
import { useUpdateItem } from '../../api/aws/useUpdateItem'
import ContactDetailsHeader from './ContactDetailsHeader'
import EditContactsInfo from './EditContactInfo'
import { gridStyles } from './style'
import { useOrganizations } from '../../api/aws/useOrganizations'
import { useContactById } from '../../api/customHooks/useContactById'
import { useGetQuery } from '../../api/aws/useGetQuery'
import DetailsLoading from '../Loading/DetailsLoading'
import { useUsers } from '../../api/aws/useUsers'
import { useQueryClient } from '@tanstack/react-query'

const ContactDetails = () => {
  const source = 'Contact Details'
  const { contactId } = useParams()
  const { users, isUsersLoading, isUsersError } = useUsers(source)
  const { organizations, isOrganizationsLoading, isOrganizationsError } = useOrganizations(source)
  const [isEditMode, setIsEditMode] = useState(false)
  const queryClient = useQueryClient()
  const { showSnackbar } = useSnackbarContext()
  const { data: contact, isLoading: contactLoading, isError: contactError } = useContactById(contactId)
  const [initialDetails, setInitialDetails] = useState(contact)
  const [details, setNewDetails] = useState(contact)
  const { mutate: updateItem } = useUpdateItem()
  const theme = useTheme()
  const gridBgColor = theme.palette.mode === 'dark' ? '#1e2735' : 'rgba(255,255,255)'
  useEffect(() => {
    if (contact) {
      console.log('[Contact Details] contact:', contact)
    }
  }, [contact])
  const handleUpdate = (fieldName, newValue) => {
    console.log('newValue: ', newValue)
    if (contact[fieldName] !== newValue) {
      updateItem(
        { primaryKey: 'contacts_uuid', tableName: 'contacts', itemId: contactId, fieldName, newValue },
        {
          onSuccess: () => {
            showSnackbar(`Contact ${fieldName} updated successfully`, 'success')
            console.log('Update successful')
            setNewDetails(prev => ({ ...prev, [fieldName]: newValue }))
          },
          onError: err => {
            console.error('Update failed', err)
          },
        }
      )
    }
  }

  const fetchOrganizationObjects = ids => {
    return ids.map(id => organizations.find(org => org.org_uuid === id)).filter(Boolean)
  }
  /////////////////////
  const handleSave = e => {
    setIsEditMode(false)
    handleUpdate(details)
  }

  const handleEdit = e => {
    setIsEditMode(true)
  }

  const handleChange = e => {
    const { name, value } = e.target
    setNewDetails({ ...details, [name]: value })
  }

  // const handleBlur = e => {
  //   const { name, value } = e.target
  //   if (initialDetails[name] !== value) {
  //     handleUpdate(name, value)
  //     setInitialDetails({ ...initialDetails, [name]: value })
  //   }
  // }
  const handleBlur = e => {
    const { name, value } = e.target

    // Check if the field is one of the name fields
    const isNameField = ['firstName', 'middleName', 'lastName'].includes(name)

    // Update the specific field
    if (initialDetails[name] !== value) {
      handleUpdate(name, value)
      setInitialDetails({ ...initialDetails, [name]: value })
      setNewDetails({ ...details, [name]: value })
    }

    // If it's a name field, update the full name
    if (isNameField) {
      const firstName = name === 'firstName' ? value : details.firstName || details.first_name || ''
      const middleName = name === 'middleName' ? value : details.middleName || ''
      const lastName = name === 'lastName' ? value : details.lastName || details.last_name || ''

      // Construct the full name and trim extra spaces
      const fullName = `${firstName} ${middleName} ${lastName}`.trim()

      // Update the contact name field if it has changed
      if (contact?.name !== fullName) {
        handleUpdate('name', fullName)
        setInitialDetails(prev => ({ ...prev, name: fullName }))
        setNewDetails(prev => ({ ...prev, [name]: value }))
      }
    }
  }

  const handleCompanyChange = (context, value) => {
    setNewDetails(prev => ({
      ...prev,
      [context]: value,
    }))
  }

  const handleObjectBlur = (context, newValue) => {
    if (JSON.stringify(initialDetails[context]) !== JSON.stringify(newValue)) {
      handleUpdate(context, newValue)
      setInitialDetails(prev => ({
        ...prev,
        [context]: newValue,
      }))
    }
  }

  const isLoading = isOrganizationsLoading || isUsersLoading || contactLoading

  return (
    <>
      {isLoading && <DetailsLoading />}
      <Grid container spacing={2} justifyContent='center' sx={gridStyles(theme)}>
        {!isEditMode ? (
          <>
            <Grid item xs={12} sm={12} md={9} lg={9}>
              <ContactInfoCard contact={details} onSave={handleUpdate} onEdit={handleEdit} />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      padding: 2,
                      backgroundColor: gridBgColor,
                      height: 200,
                      borderRadius: '25px',
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      padding: 2,
                      backgroundColor: gridBgColor,
                      height: 130,
                      borderRadius: '25px',
                      width: '100%',
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      padding: 2,
                      backgroundColor: gridBgColor,
                      height: 130,
                      borderRadius: '25px',
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={9} lg={9}>
              <Box sx={{ width: '100%', backgroundColor: gridBgColor, padding: 4, borderRadius: '25px', mt: 2 }}>
                <Typography
                  sx={{ color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,1)' : 'rgba(0,0,0,1)' }}
                  variant='h6'
                  gutterBottom
                >
                  Summary
                </Typography>
                <Typography sx={{ color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,1)' : 'rgba(0,0,0,1)' }}>
                  {contact && contact.summary ? contact.summary : 'No summary included'}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Box sx={{ padding: 2, backgroundColor: 'none', height: 190, borderRadius: '25px' }} />
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12} sm={12} md={9} lg={9}>
              <EditContactsInfo
                users={users}
                contact={contact}
                details={details}
                handleChange={handleChange}
                handleBlur={handleBlur}
                fetchOrganizationObjects={fetchOrganizationObjects}
                handleCompanyChange={handleCompanyChange}
                handleObjectBlur={handleObjectBlur}
                handleSave={handleSave}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Grid container spacing={2}>
                <Grid item xs={12}></Grid>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      padding: 2,
                      backgroundColor: gridBgColor,
                      height: 200,
                      borderRadius: '25px',
                      width: '100%',
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      padding: 2,
                      backgroundColor: gridBgColor,
                      height: 130,
                      borderRadius: '25px',
                      width: '100%',
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      padding: 2,
                      backgroundColor: gridBgColor,
                      height: 130,
                      borderRadius: '25px',
                      width: '100%',
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </>
    // <>
    //   <ContactDetailsHeader contact={contact} />
    //   <Grid container spacing={2} justifyContent='center' sx={{ padding: 5 }}>
    //     <ContactInfoCard contact={contact} onSave={handleUpdate} />
    //     <Grid item xs={12} sm={12} md={3} lg={3}>
    //       <Grid container spacing={2}>
    //         <Grid item xs={12}>
    //           <Box sx={{ padding: 2, backgroundColor: gridBgColor, height: 200, borderRadius: '25px' }} />
    //         </Grid>
    //         <Grid item xs={12}>
    //           <Box sx={{ padding: 2, backgroundColor: gridBgColor, height: 125, borderRadius: '25px' }} />
    //         </Grid>
    //         <Grid item xs={12}>
    //           <Box sx={{ padding: 2, backgroundColor: gridBgColor, height: 150, borderRadius: '25px' }} />
    //         </Grid>
    //       </Grid>
    //     </Grid>
    //     <Grid item xs={12} sm={12} md={9} lg={9}>
    //       <Box sx={{ width: '100%', backgroundColor: gridBgColor, padding: 4, borderRadius: '25px', mt: 2 }}>
    //         <Typography variant='h6' gutterBottom>
    //           Summary
    //         </Typography>
    //         {contact && contact.summary ? (
    //           <Typography>{contact.summary || ''}</Typography>
    //         ) : (
    //           <Typography sx={{ color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.3)' : 'rgba(0,0,0,0.3)' }}>
    //             Contact Summary Here
    //           </Typography>
    //         )}
    //       </Box>
    //     </Grid>
    //     <Grid item xs={12} sm={12} md={3} lg={3}>
    //       <Box sx={{ padding: 2, backgroundColor: 'none', height: 190, borderRadius: '25px' }} />
    //     </Grid>
    //   </Grid>
    // </>
  )
}

export default ContactDetails
