import React, { useState, useEffect, useRef } from 'react'
import { Box, Typography, Table, TableRow, TableCell, TableBody, TableHead, useTheme, Grid } from '@mui/material'
import { formatCurrency } from '../DealsKanban/aggregateStageTotals'
import RenderServiceIcon from '../RenderServiceIcons/RenderServiceIcon'
import { tableCellStyles, tableHeaderStyles } from '../DealApproval/style'
import { calculateRangeForProducts, calculateTotalMonthsForProducts } from '../../utility/calculateTotalMonths'
import { useDealDetailsContext } from '../../context/DealsDetailsProvider/DealsDetailsProvider'
import AccountingRichTextTooltip from './AccountingRichTextTooltip'

const IOFinancialTotals = ({ totals, containerHeight, hideViewIcon }) => {
  const { newDeal: deal } = useDealDetailsContext()
  const [openTooltip, setOpenTooltip] = useState(null) // Track which tooltip is open
  const tooltipRef = useRef(null) // Ref for the currently open tooltip
  const [isCopied, setIsCopied] = useState(false)
  const theme = useTheme()
  const {
    dateRange: { start, end },
  } = deal && Array.isArray(deal.products) ? calculateTotalMonthsForProducts(deal.products) : { dateRange: {} }
  const { totalMonths, allSame } =
    deal && Array.isArray(deal.products) ? calculateTotalMonthsForProducts(deal.products) : { totalMonths: 1 }
  // const { allSame } =
  //   deal && Array.isArray(deal.products) ? calculateTotalMonthsForProducts(deal.products) : { totalMonths: 1 }
  // Ensure the deal type is IO

  const handleTooltipToggle = index => {
    setOpenTooltip(prev => (prev === index ? null : index))
  }

  const handleClickOutside = event => {
    if (
      tooltipRef.current &&
      !tooltipRef.current.contains(event.target) &&
      !event.target.closest('.MuiTooltip-tooltip') // Ignore clicks inside the tooltip
    ) {
      setOpenTooltip(null)
      setIsCopied(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])
  if (deal.type !== 'IO') {
    return <Typography>This component is only for IO deals</Typography>
  }
  // Calculate total IO cost by summing all product costs and multiplying by totalMonths
  // const ioTotal = deal.products.reduce((total, product) => total + parseFloat(product.price || 0), 0)
  const ioTotal = deal && deal.totals ? totals.total : deal.totals.total
  const varyingDateTotal = deal.products.reduce((total, product) => {
    const productQty = product?.qty || 1
    const price = product.payer === 'Client' ? parseFloat(product.billedPrice || 0) : parseFloat(product.price || 0)
    const rowTotal = price * productQty
    return total + rowTotal // Ensure price is a valid number
  }, 0)

  // Calculate the complete total by multiplying the total cost of products by the total months
  const completeTotal = totalMonths > 0 ? ioTotal * totalMonths : ioTotal
  return (
    <Grid container spacing={1} sx={{ maxWidth: '80vw' }}>
      <Grid item xs={12}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={deal && deal.status === 'Completed' ? 1.5 : 2} sx={tableHeaderStyles}>
                {start && end ? `${start} - ${end} | ${totalMonths} mo(s).` : `No Start and End Date`}
              </TableCell>
              <TableCell sx={tableHeaderStyles}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {deal.products.map((product, idx) => {
              const productRowPrice = product && product.payer === 'Client' ? product.billedPrice : product.price
              return (
                <TableRow key={`${idx}-${product.productName}`}>
                  <TableCell sx={tableCellStyles(theme)}>
                    <Box
                      ref={tooltipRef} // Attach ref to tooltip container
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        height: '100%',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      {<RenderServiceIcon service={product.productName} />}
                      <AccountingRichTextTooltip
                        open={openTooltip === `${'description'}-${idx}`}
                        onToggle={() => handleTooltipToggle(`${'description'}-${idx}`)}
                        content={product?.description}
                        isCopied={isCopied}
                        setIsCopied={setIsCopied}
                      />
                    </Box>
                  </TableCell>
                  <TableCell sx={tableCellStyles(theme)}>
                    {' '}
                    <Typography
                      sx={{
                        color: product?.payer === 'Client' ? theme.palette.text.warning : theme.palette.text.primary,
                      }}
                    >
                      {formatCurrency(productRowPrice)}
                    </Typography>
                  </TableCell>
                </TableRow>
              )
            })}
            <TableRow>
              <TableCell
                sx={{
                  ...tableCellStyles(theme),
                  textAlign: 'right',
                  borderRight: 'none',
                }}
                colSpan={1}
              >
                Total IO Cost:
              </TableCell>
              <TableCell
                sx={{
                  ...tableCellStyles(theme),
                  color: 'rgb(50, 187, 130)',
                  borderLeft: 'none',
                }}
                colSpan={1}
              >
                {formatCurrency(allSame ? completeTotal : varyingDateTotal)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  )
}

export default IOFinancialTotals
