import React from 'react'
import { Button, Tooltip, Typography, useTheme } from '@mui/material'
import WarningIcon from '@mui/icons-material/Warning'
import SendIcon from '@mui/icons-material/Send'

const ApprovalRequestButton = ({ isRequested, isRequesting, onRequest, startIcon, errorIcon, isValid }) => {
  const theme = useTheme()
  return (
    <Button
      sx={{
        width: '100%',
        height: '100%',
        backgroundColor: 'rgb(5, 190, 113)',
      }}
      // disabled={isRequesting || isRequested || !isValid}
      disabled={isRequesting || isRequested}
      onClick={onRequest}
      name='isApproved'
      variant='contained'
      startIcon={
        errorIcon ? (
          <WarningIcon sx={{ color: theme.palette.text.error }} />
        ) : startIcon ? (
          <SendIcon sx={{ color: theme.palette.text.primary }} />
        ) : null
      }
    >
      <Typography>{!isRequested ? 'Request Approval' : 'Pending Approval'}</Typography>
    </Button>
  )
}

export default ApprovalRequestButton
