import React, { useContext, useEffect, useState } from 'react'
import { Tabs, Tab, Box, Typography, useTheme, Tooltip, CircularProgress, Stack } from '@mui/material'
import { useParams, Link, Outlet, useLocation } from 'react-router-dom'
import Loading from '../Loading/Loading'
import { useDeal } from '../../api/aws/useDeal'
import DealsDetailsHeader from './DealsDetailsHeader'
import GridLoading from '../Loading/GridLoading'
import { DealDetailsProvider, useDealDetailsContext } from '../../context/DealsDetailsProvider/DealsDetailsProvider'
import DetailsLoading from '../Loading/DetailsLoading'
import { useQueryClient } from '@tanstack/react-query'
import { useTimeline } from '../../api/aws/useTimeline'
import { useStrategy } from '../../api/aws/useStrategy'
import { useValidationContext } from '../../context/ValidationProvider/ValidationProvider'
import WarningIcon from '@mui/icons-material/Warning'
import RenderErrorTooltip from '../Strategy/RenderErrorTooltip'

const DealPage = () => {
  const { dealId } = useParams()
  const { deal, isLoading, isError } = useDeal(dealId)
  const { strategy, isLoading: isStrategyLoading, isError: isStrategyError } = useStrategy(dealId)
  const { strategyErrors, errorKeys, handleValidateStrategy, isStrategyValid } = useValidationContext()
  const { timeline, isLoading: timelineLoading, isError: timelineError } = useTimeline(dealId)
  const theme = useTheme()
  const location = useLocation()
  const [tabValue, setTabValue] = React.useState(0)

  const queryClient = useQueryClient()
  useEffect(() => {
    const handleDealUpdate = event => {
      const data = event.detail
      if (data.tableName === 'deals' && data.itemId === dealId) {
        console.log('DealPage received update event for deal:', dealId)
        queryClient.invalidateQueries(['/aws/deals', dealId])
      }
    }

    // Listen for the custom event 'dealUpdate'
    window.addEventListener('dealUpdate', handleDealUpdate)

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener('dealUpdate', handleDealUpdate)
    }
  }, [dealId, queryClient])

  useEffect(() => {
    if (deal && strategy && !isStrategyLoading) {
      handleValidateStrategy(strategy, deal)
    }
  }, [deal, strategy])

  useEffect(() => {
    // Determine the tab value based on the current route
    if (location.pathname.includes('/tasks')) {
      setTabValue(1)
    } else if (location.pathname.includes('/services')) {
      setTabValue(2)
    } else if (location.pathname.includes('/strategy')) {
      setTabValue(3)
    } else if (location.pathname.includes('/paymentschedule')) {
      setTabValue(4)
    } else if (location.pathname.includes('/reviewdeal')) {
      setTabValue(5)
    } else if (location.pathname.includes('/invoicing')) {
      setTabValue(6)
    } else if (location.pathname.includes('/timeline')) {
      setTabValue(7)
    } else {
      setTabValue(0)
    }
  }, [location])

  if (isLoading || isStrategyLoading) {
    return <DetailsLoading />
  }

  if (isError) {
    return <Typography>Error loading deal details</Typography>
  }

  return (
    <>
      <Box sx={{ position: 'sticky', top: 0, zIndex: 1100 }}>
        <Box
          sx={{
            width: '100%',
          }}
        />
        <DealDetailsProvider dealData={deal}>
          <Box sx={{ display: 'flex', maxWidth: '100vw' }}>
            <DealsDetailsHeader deal={deal} />
          </Box>
          <Box
            sx={{
              width: '100%',
            }}
          >
            <Tabs
              value={tabValue}
              aria-label='deal tabs'
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
                backgroundColor: theme.palette.mode === 'dark' ? 'rgb(30, 39, 53)' : 'rgba(255,255,255,1)',
                mb: 1.5,
              }}
            >
              <Tab label={<Typography>Details</Typography>} component={Link} to={`/deals/${dealId}`} />
              <Tab label={<Typography>Tasks</Typography>} component={Link} to={`/deals/${dealId}/tasks`} disabled />
              <Tab
                label={<Typography>Services</Typography>}
                component={Link}
                to={`/deals/${dealId}/services`}
                disabled
              />
              <Tab
                label={
                  errorKeys &&
                  errorKeys?.length > 0 &&
                  !deal?.isCompleted &&
                  deal?.status !== 'Completed Internally' ? (
                    <RenderErrorTooltip strategy={strategy} isStrategyValid={isStrategyValid} errorKeys={errorKeys} />
                  ) : (
                    <Typography>Strategy</Typography>
                  )
                }
                disabled={deal?.status === 'Completed Internally'}
                component={Link}
                to={`/deals/${dealId}/strategy`}
              />
              <Tab
                disabled={deal && deal.products && Array.isArray(deal.products) && deal.products.length === 0}
                label={<Typography>Payment Schedule</Typography>}
                component={Link}
                to={`/deals/${dealId}/paymentschedule`}
              />
              <Tab
                disabled={deal && deal.products && Array.isArray(deal.products) && deal.products.length === 0}
                label={<Typography>Review Deal</Typography>}
                component={Link}
                to={`/deals/${dealId}/reviewdeal`}
              />
              <Tab
                disabled={deal && deal.products && Array.isArray(deal.products) && deal.products.length === 0}
                label={<Typography>Invoicing</Typography>}
                component={Link}
                to={`/deals/${dealId}/invoicing`}
              />
              <Tab
                label={<Typography>Timeline</Typography>}
                component={Link}
                to={`/deals/${dealId}/timeline`}
                disabled={!timeline || timeline?.length === 0}
              />
            </Tabs>
            <Box
              sx={{
                height: 'calc(100vh - 185px)', // Adjust this value based on your header height
                p: `0px !important`,
              }}
            >
              <Outlet />
            </Box>
          </Box>
        </DealDetailsProvider>
      </Box>
    </>
  )
}

export default DealPage
