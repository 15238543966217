import React, { useState, useEffect } from 'react'
import { Grid, Typography, Card, Checkbox, FormControlLabel, useTheme } from '@mui/material'

const Channels = ({ deal, dependencyList, handleChange, dependenciesNameMapping }) => {
  const theme = useTheme()
  // Initialize statuses based on the dependency list and deal data
  const initializeSelectedStatuses = () => {
    const initialStatuses = {}
    dependencyList[0].Dependency.forEach(dep => {
      const key = Object.keys(dep)[0]
      initialStatuses[key] = deal[key] || 'N/A' // Default status
    })
    return initialStatuses
  }

  const [selectedStatuses, setSelectedStatuses] = useState(initializeSelectedStatuses())

  useEffect(() => {
    setSelectedStatuses(initializeSelectedStatuses())
  }, [deal, dependencyList])

  const handleCheckboxChange = (e, dependency, status) => {
    console.log(e)
    const updatedStatus = selectedStatuses[dependency] === status ? 'N/A' : status // Toggle status
    setSelectedStatuses(prev => ({
      ...prev,
      [dependency]: updatedStatus,
    }))
    handleChange(dependency, updatedStatus)
  }

  return (
    <Grid container spacing={2}>
      {dependencyList[0].Dependency.map((dep, index) => {
        const dependencyKey = Object.keys(dep)[0]
        const dependencyName = dependenciesNameMapping[dependencyKey] || dependencyKey // Get user-friendly name
        const statusOptions = dep[dependencyKey]

        return (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card sx={{ padding: 2 }}>
              <Typography sx={{ color: theme.palette.text.primary }} align='center' variant='h6'>
                {dependencyName} {/* Display dependency name */}
              </Typography>
              <Grid container spacing={1} justifyContent='center'>
                {statusOptions.map((option, idx) => (
                  <Grid item key={idx}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedStatuses[dependencyKey] === option.name}
                          onChange={e => handleCheckboxChange(e, dependencyKey, option.name)}
                          sx={{
                            color: option.color,
                            '&.Mui-checked': {
                              color: option.color,
                            },
                            '& .MuiSvgIcon-root': {
                              color: option.color,
                            },
                          }}
                        />
                      }
                      label={option.name}
                      sx={{ textAlign: 'center', color: theme.palette.text.primary }}
                    />
                  </Grid>
                ))}
              </Grid>
            </Card>
          </Grid>
        )
      })}
    </Grid>
  )
}

export default Channels
