import React from 'react'
import { Grid, Box, Typography } from '@mui/material'
import RoleCard from './RoleCard'
import RoleTaskComponent from './RoleTaskComponent'
import { CustomCard } from '../../style/styleElements'

const RenderRoleTasks = ({ role, deal, users, tasks, onChange, onRoleTaskChange }) => {
  return (
    <CustomCard>
      {/* Role Card */}
      <Box
        sx={{
          flex: '1 0 25%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <RoleCard initialRoleUsers={deal[role]} users={users} handleChange={onChange} tasks={tasks} roleName={role} />
      </Box>

      {/* Tasks */}
      <Grid
        container
        spacing={2}
        sx={{
          flex: '3 0 75%',
          justifyContent: 'flex-start',
          alignItems: 'center',
          paddingLeft: 2,
        }}
      >
        {tasks.map((task, index) => {
          const taskGroupName = Object.keys(task)[0]
          const taskOptions = task[taskGroupName]
          return (
            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
              <RoleTaskComponent
                key={taskGroupName}
                taskGroupName={taskGroupName}
                taskOptions={taskOptions}
                role={role}
                initialValue={deal[taskGroupName]}
                onChange={(field, newValue) => onRoleTaskChange(taskGroupName, newValue, role)}
              />
            </Grid>
          )
        })}
      </Grid>
    </CustomCard>
  )
}

export default RenderRoleTasks
