import { useGetQueryByIds } from './useGetQueryByIds'

// export const useDealsByIds = dealIds => {
//   const validIds = Array.isArray(dealIds) ? dealIds.filter(id => !!id) : []
//   const deals = useGetQueryByIds('/aws/deals', 'deals', validIds || [])
//     .filter(result => result.isSuccess && result.data) // Only include successful results with data
//     .map(result => result.data) // Extract the `data` property
//   console.log('Deals Found: ', deals)
//   return deals
// }
export const useDealsByIds = dealIds => {
  const validIds = Array.isArray(dealIds) ? dealIds.filter(id => !!id) : []

  // Use the custom useGetQueryByIds hook to fetch user data by IDs
  const queryResults = useGetQueryByIds('/aws/deals', 'deals', validIds)
  // Extract user data from successful queries
  const deals = queryResults
    .filter(result => result.isSuccess && result.data) // Filter out unsuccessful queries
    .map(result => result.data) // Extract the user object from the data
  // Aggregate loading and error states
  const isDealsLoading = queryResults.some(result => result.isLoading)
  const isDealsError = queryResults.some(result => result.isError)
  const dealsByIdsError = queryResults.find(result => result.isError)?.error

  return { deals, isDealsError, isDealsLoading, dealsByIdsError }
}
