import React, { useState, useMemo, useCallback, useEffect } from 'react'
import {
  Autocomplete,
  AvatarGroup,
  Avatar,
  TextField,
  Checkbox,
  Box,
  Grid,
  FormControl,
  Typography,
  List,
  ListItem,
  useTheme,
} from '@mui/material'
import UserImageComponent from '../Users/UserImageComponent'
import RoleTaskComponent from './RoleTaskComponent'

export const sortUsersBySelectedRoles = (users, selectedRoleMembers) => {
  if (!Array.isArray(users) || users.length === 0) return []

  return [...users].sort((a, b) => {
    const aSelected = selectedRoleMembers.some(member => member && member.user_uuid === a.user_uuid)
    const bSelected = selectedRoleMembers.some(member => member && member.user_uuid === b.user_uuid)
    return bSelected - aSelected
  })
}

const RoleCard = ({ initialRoleUsers, users, handleChange, roleName }) => {
  const theme = useTheme()
  const [selectedUsers, setSelectedUsers] = useState(initialRoleUsers || [])

  useEffect(() => {
    setSelectedUsers(initialRoleUsers || [])
  }, [initialRoleUsers])

  const handleRoleChange = useCallback(
    (event, newValue) => {
      setSelectedUsers(newValue)
      handleChange(roleName, newValue)
    },
    [handleChange, roleName]
  )

  // Assuming sortUsersBySelectedRoles function can be reused or adapted
  const sortedUsers = useMemo(() => sortUsersBySelectedRoles(users, selectedUsers), [users, selectedUsers])

  const renderOption = (props, option, { selected }) => {
    const { key, ...restProps } = props // Extract the key to prevent spreading it
    return (
      <List key={key} {...restProps} sx={{ width: '100%', p: 0 }}>
        <ListItem
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: '8px', // Optional padding for spacing
          }}
        >
          {option.image_512 ? (
            <Box
              component='img'
              src={option.image_512}
              alt='User'
              sx={{
                width: 40,
                height: 40,
                borderRadius: '50%',
                mr: 1,
              }}
            />
          ) : (
            <Avatar
              sx={{
                width: 40,
                height: 40,
                marginRight: 12, // Add spacing for consistent alignment
              }}
            />
          )}
          <Typography
            sx={{
              flex: 1, // Take up remaining horizontal space
              whiteSpace: 'nowrap', // Prevent wrapping to new lines
              overflow: 'hidden', // Clip overflowing text
              textOverflow: 'ellipsis', // Add ellipsis for truncated text
              fontSize: '14px', // Adjust font size if needed
            }}
          >
            {option.name}
          </Typography>
        </ListItem>
      </List>
    )
  }

  const renderTags = value => (
    <AvatarGroup
      max={4}
      spacing='medium'
      slotProps={{
        additionalAvatar: {
          sx: {
            bgcolor: theme.palette.secondary.main, // Change this to your desired background color
            color: theme.palette.getContrastText(theme.palette.secondary.main), // Adjust text color for contrast
          },
        },
      }}
    >
      {value.map(
        option =>
          option &&
          (option.image_512 ? (
            <UserImageComponent imageUrl={option.image_512} key={option.user_uuid} />
          ) : (
            <Avatar key={option.user_uuid} sx={{ bgcolor: theme.palette.background.paper }} />
          ))
      )}
    </AvatarGroup>
  )

  return (
    <FormControl fullWidth>
      <Autocomplete
        multiple
        value={selectedUsers}
        onChange={handleRoleChange}
        options={sortedUsers}
        getOptionLabel={option => (option ? option.name : '')}
        renderTags={renderTags}
        renderOption={renderOption}
        isOptionEqualToValue={(option, value) => option && value && option.user_uuid === value.user_uuid}
        renderInput={params => (
          <TextField
            {...params}
            label={roleName}
            variant='outlined'
            // placeholder={roleName}
            InputProps={{
              ...params.InputProps,
              sx: {
                '.MuiInputBase-input::placeholder': {
                  fontSize: '16px', // Change this value as needed
                },
              },
            }}
          />
        )}
      />
    </FormControl>
  )
}

export default React.memo(RoleCard)
